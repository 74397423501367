import React, { Component } from 'react'
import { connect } from "react-redux"
import { Redirect } from 'react-router'
import Rodal from 'rodal'
import HayerImageCrop from 'hayer-react-image-crop'
import 'hayer-react-image-crop/style.css'
import Select from 'react-select'
import csc from 'country-state-city'

import { ImageUploadPreview } from '../../reusables/ImageUploadPreview/ImageUploadPreview'

import { checkLoginStatus } from '../../actions/sessionActions'
import {     
    loadSupplierCategories, 
    supplierProfileBusinessFormUpdate, 
    retrieveAllSupplierBusinessInformation,     
    postCollectedSupplierBusinessInformation 
} from '../../actions/profileActions'
import { showNotification, hideNotification } from "../../actions/notificationActions"

import profile_sample from '../../../src/assets/images/profile_image1.png'
import { isValidURL } from '../../utils/misc'

class BusinessInformation extends Component {

    state = {         
        src: null,
        preview: null,
        crop: {
            aspect: 1,
            width: 50,
            x: 0,
            y: 0
        },
        town: "",        
        street: "",
        country: "",
        website: "", 
        postcode: "", 
        jobCost: null, 
        companyBio: "",         
        costDetails: "",       
        companyName: "",  
        coverImage: null,
        selectedCity: "",
        oldProfilePic: "",         
        oldCoverImage: "",        
        selectedState: "",        
        businessAwards: "",                
        croppedImageUrl: "",                
        selectedCountry: "",     
        jobCostToObject: null,
        modalBoxVisbile: false,
        profilePicResource: "",
        jobCostFromObject: null,                
        jobCastTypes: [
            {
                "key": "0-20",
                "text": "under $20,000"
            },
            {
                "key": "20-30",
                "text": "$20,000-$30,000"
            },
            {
                "key": "30-50",
                "text": "$20,000-$50,000"
            },
            {
                "key": "50-100",
                "text": "over $50,000"
            }
        ],
        errorFields: {
            website: false,            
            country: false,            
            coverImage: false,
            companyBio: false,
            companyName: false,
            croppedImageUrl: false
        },
        touched: {
            country: false,
            website: false,
            coverImage: false,
            companyBio: false,
            companyName: false,
            croppedImageUrl: false
        },
        allCountries: [],
        errorsInTheForm: false,
        successInTheForm: false,
        selectedStateObject: {},
        loggedInRealStatus: true,        
        selectedCountryObject: {}
    }

    componentDidMount () {
        this.props.checkLoginStatus().then(data => {
            if (!data) {
                this.setState({ loggedInRealStatus: false })
            } else {
                this._loadBusinessProfileInformation()   
            }            
        })        
    }

    onClose = () => this.setState({ preview: null })
    
    onCrop = preview => this.setState({ preview: preview, touched: { ...this.state.touched, errorsInTheForm: true, croppedImageUrl: true }, errorFields: { croppedImageUrl: true }})            
    
    showModalBox = () => this.setState({ modalBoxVisbile: true })

    hideModalBox = () => this.setState({ modalBoxVisbile: false })

    _loadBusinessProfileInformation = () => {
        this.props.retrieveAllSupplierBusinessInformation().then(data => {  
            if (data !== null) {
                const allCountries = csc.getAllCountries()
                this.setState({   
                    country: data && data.country,
                    town: data && data.town,
                    street: data && data.address,
                    website: data && data.website,
                    postcode: data && data.post_code,                    
                    coverImage: data && data.cover_image,                    
                    companyBio: data && data.company_bio,
                    companyName: data && data.company_name,                            
                    costDetails: data && data.cost_details,
                    oldProfilePic: data && data.profile_photo,   
                    oldCoverImage: data && data.cover_image,                         
                    jobCost: data && data.typical_job_cast_type,
                    businessAwards: data && data.business_awards,
                    croppedImageUrl: this.props.values.selectedImage,                    
                    errorsInTheForm: (!data.company_name || !data.profile_photo || !data.cover_image || !data.company_bio || !data.country || !data.website) ? true : false
                })     
                allCountries.map(eachCountry => {
                    let contryObject = {
                        value: eachCountry.id,
                        label: eachCountry.name
                    }
                    this.setState({ allCountries: this.state.allCountries.concat(contryObject) })
                })  
            }          
        }) 
    }

    _handleProfileImageCrop = img => this.setState({ croppedImageUrl: true, croppedImageUrl: img })

    _handleProfileImageCropError = err => {
        this.setState({ croppedImageUrl: false })
        this.props.showNotification('Something went wrong! Please contact support...', 'error')
        setTimeout(() => this.props.hideNotification(), 1500)                
    }

    _completeTheImageCrop = e => {
        this.hideModalBox()
        this.props.selectedImage(this.state.croppedImageUrl)
        this.props.supplierProfileBusinessFormUpdate('profilePhoto', this.state.croppedImageUrl)     
    }

    _handleImageChangeForCoverImagae = e => {
        e.preventDefault()
        const reader = new FileReader()
        const file = e.target.files[0] 
        reader.readAsDataURL(file)
        reader.onload = () => this.setState({ coverImagePreview: reader.result, selectedCoverImage: reader.result, errorsInTheForm: false })  
    }

    updateFormHandler = field => event => { 
        if (field === "country") {
            if ((event) && (event)) {
                this.setState({ 
                    [field]: event.label, 
                    errorFields: {
                        [field]: (!event.label) ? true : false
                    },
                    errorsInTheForm: (!event.label) ? true : false
                })               
                if (field === "country" && event.value) {  
                    this.setState({ selectedCountryObject: event })   
                }            
                this.props.supplierProfileBusinessFormUpdate([field], event.value)      
            }
        } else {
            if ((field !== "country") && (field !== "website")) {
                this.setState({ 
                    [field]: event.target.value, 
                    errorFields: {
                        [field]: (!event.target.value) ? true : false
                    },
                    errorsInTheForm: (!event.target.value) ? true : false
                })                                        
                this.props.supplierProfileBusinessFormUpdate([field], event.target.value)     
            }
            if (field === "website") {
                if (isValidURL(event.target.value)) {
                    this.setState({ 
                        [field]: event.target.value, 
                        errorFields: {
                            [field]: (!event.target.value) ? true : false
                        },
                        errorsInTheForm: (!event.target.value) ? true : false
                    })                                        
                    this.props.supplierProfileBusinessFormUpdate([field], event.target.value)         
                } else {
                    this.setState({ errorsInTheForm: true })
                }
            }            
        }
    }

    handleBlur = field => event => this.setState({ touched: { ...this.state.touched, [field]: true }, errorFields: { [field]: (!event.target.value) ? true : false }})

    saveAndContinue = e => {
        e.preventDefault()
        const {
            town,
            street,
            jobCost,
            country,
            website,
            postcode,
            companyBio,
            companyName,                            
            costDetails,            
            oldCoverImage,           
            oldProfilePic,                                                 
            businessAwards,
            croppedImageUrl,
            selectedCoverImage
        } = this.state        

        if (!this.state.errorsInTheForm) {  
            const postData = {
                supplierCompanyName: companyName,
                supplierProfilePhoto: croppedImageUrl || oldProfilePic,
                supplierProfileCoverImage: selectedCoverImage || oldCoverImage,
                supplierCompanyBio: companyBio,
                supplierBusinessAwards: businessAwards,
                supplierJobCost: jobCost,
                supplierCostDetails: costDetails,
                supplierStreet: street !== null ? street : '',
                supplierTown: town !== null ? town : '',
                supplierCity: "",
                supplierState: "",
                supplierCountry: country,
                supplierPostcode: postcode !== null ? postcode : '',
                supplierWebsite: website
            }  

            if (croppedImageUrl || selectedCoverImage) {
                    this.props.postCollectedSupplierBusinessInformation(postData).then(data => {
                        if (data.status === "supplier_business_information_updated") {
                            this.props.showNotification('Your business information have been updated!.', 'success')
                            setTimeout(() => {
                                this.props.hideNotification()
                                this.props.nextStep()                                
                            }, 1500)      
                        } else {
                            this.props.showNotification('Something went wrong! Please contact support...', 'error')
                            setTimeout(() => this.props.hideNotification(), 1500)                
                        }
                    })                
            } else {
                if (this.props.formUpdated) {
                    this.props.postCollectedSupplierBusinessInformation(postData).then(data => {
                        if (data.status === "supplier_business_information_updated") {
                            this.props.showNotification('Your business information have been updated!.', 'success')
                            setTimeout(() => {
                                this.props.hideNotification()
                                this.props.nextStep()                                
                            }, 5000)      
                        } else {
                            this.props.showNotification('Something went wrong! Please contact support...', 'error')
                            setTimeout(() => this.props.hideNotification(), 5000)                
                        }
                        this._loadBusinessProfileInformation()   
                    })                         
                } else {
                    this.props.nextStep()
                }
            }                      
        }
    } 

    render() {

        const { 
            values,
            loading
        } = this.props 

        const { 
            src,
            town, 
            street,
            country,
            touched,
            jobCost,
            preview,
            website,
            postcode,
            companyBio,
            companyName,
            costDetails,
            errorFields,
            jobCastTypes,
            allCountries,
            oldCoverImage,
            oldProfilePic,            
            businessAwards,
            croppedImageUrl,             
            modalBoxVisbile,
            errorsInTheForm,
            coverImagePreview,
            loggedInRealStatus,
            selectedCountryObject,
        } = this.state         

        let countryObject = {}

        if (country && allCountries && !selectedCountryObject.value) {
            countryObject = allCountries.filter(eachCountry => eachCountry.label === country)            
        }
        
        if (!loggedInRealStatus) return <Redirect to={'/'} />
        
        return (
            <>
                <div className={loading ? "overlay" : "overlay hide"}>
                    <div className="profile__loader-container">
                        <div className="loader"><span>Loading...</span></div>
                    </div>
                </div>  
                <div className="profile__business-info">
                    <div className="profile__section">
                        <Rodal className="rodal--profile-pic" visible={modalBoxVisbile} onClose={this.hideModalBox.bind(this)} animation={'slideDown'}>
                            <header className="rodal__header">
                                <h4 className="rodal__heading">Profile Image</h4>
                            </header>
                            <p className="profile__rodal-text">Drag the corner of the box to change the position and size, or upload a new image</p>
                            <div className="rodal__main-container">
                                <div className="image-preview-container">
                                {
                                    croppedImageUrl ?
                                        <img alt="Croped profile pic" className="image-preview--cropped-img" src={croppedImageUrl || oldProfilePic} /> : 
                                        <img alt="profile pic" className="image-preview" src={preview || oldProfilePic || profile_sample} />
                                }
                                </div>  
                                <div className="image-file-select-container">
                                    {
                                        src ?
                                        <div className="image-cropped">
                                            <input type="button" className="btn btn-white image-crop-remove" value="Remove Image" onClick={this.removeImage} />
                                        </div> : 
                                        <div className="image-upload-wrap">
                                            <HayerImageCrop
                                                unit="px"                                                
                                                width={256}
                                                aspect={4/4}
                                                // src={`${window.location.origin}/avatar.png`}
                                                error={err => this._handleProfileImageCropError(err)}                                                
                                                onImageCrop={img => this._handleProfileImageCrop(img)}                                                
                                            />                                
                                        </div>
                                    } 
                                </div>    
                            </div>
                            <div className="rodal__btn-container">
                                <input type="button" className="btn btn-gold image-crop-complete" disabled={!croppedImageUrl && true} value="Done" onClick={this._completeTheImageCrop} />
                            </div>
                        </Rodal>
                        <h5 className="profile__sub-title">Create An EventBuzz360 Event Manager Profile</h5>                        
                        <div className="profile__section-content">
                            <div className="profile__form-container">
                                <form className="eb-forms eb-forms--profile">                                    
                                    <div className="eb-forms__form-group cover-pro-container">
                                        <div className="cover-pic-container">
                                            <ImageUploadPreview 
                                                coverImagePreview={coverImagePreview || oldCoverImage}
                                                coverImageHandler={this._handleImageChangeForCoverImagae}
                                            />
                                        </div>
                                        <div className="pro-pic-container">
                                            <div onClick={this.showModalBox}
                                                className={`${((touched.croppedImageUrl && !croppedImageUrl) || (errorFields.croppedImageUrl)) ? 'error__fields-indicator profile__profile-photo' : 'profile__profile-photo profile__profile-photo--cropped'}`} >
                                                {
                                                    croppedImageUrl || oldProfilePic ?
                                                        <img alt="Croped profile pic" className="image-preview--main-cropped-img" src={croppedImageUrl || oldProfilePic} /> :
                                                        <p>Profile photo</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <span className="profile__section-content-tips">* Indicates required fields!</span>
                                    <h6 className="profile__sub-title sub--heading_presentations sub--heading_presentations">Tell us a little about your business</h6>
                                    <div className="eb-forms__form-group">
                                        <label htmlFor="company-name">Company Name <span className="required__indicator">*</span></label>
                                        <div className="profile__input-container">
                                            <input 
                                                type="text" 
                                                id="company-name" 
                                                name="company-name"
                                                placeholder="Company Name"                                             
                                                onBlur={this.handleBlur('companyName')}
                                                onChange={this.updateFormHandler('companyName')}
                                                defaultValue={(values.companyName && values.companyName) || (companyName !== 'null' && companyName)} 
                                                className={`${((touched.companyName && !companyName) || (errorFields.companyName)) && 'error__fields-indicator'}`}
                                            /> 
                                        </div>
                                    </div>
                                    <div className="eb-forms__form-group">
                                        <label htmlFor="company-bio">Company Bio <span className="required__indicator">*</span></label>
                                        <textarea 
                                            id="company-bio" 
                                            name="company-bio"
                                            placeholder="Company Bio"                                               
                                            onBlur={this.handleBlur('companyBio')}
                                            onChange={this.updateFormHandler('companyBio')}                                            
                                            value={(values.companyBio ? values.companyBio : '') || (companyBio !== 'null' ? companyBio : '')}                                                          
                                            className={`${((touched.companyBio && !companyBio) || (errorFields.companyBio)) && 'error__fields-indicator'}`}
                                        ></textarea>
                                    </div>
                                    <div className="eb-forms__form-group">
                                        <label htmlFor="business-awards">Business awards</label>
                                        <textarea 
                                            id="business-awards" 
                                            name="business-awards"
                                            placeholder="Business Awards"             
                                            onChange={this.updateFormHandler('businessAwards')}       
                                            value={(values.businessAwards ? values.businessAwards : '') || (businessAwards !== 'null' ? businessAwards : '')}                                                                                  
                                        ></textarea>
                                    </div>
                                    <h6 className="profile__sub-title sub--heading_presentations">Head Office Address Details</h6>
                                    <div className="eb-forms__form-group">
                                        <label htmlFor="job-cost">Country <span className="required__indicator">*</span></label>
                                        <div className="eb-forms--not-fullwidth">
                                            <div className="container">
                                                {(allCountries && allCountries.length > 0) &&
                                                    <Select 
                                                        clearable
                                                        required={true}                                                        
                                                        theme={theme => ({
                                                            ...theme,
                                                            borderRadius: 0,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: '#ffffff',
                                                                primary: 'neutral90',
                                                            },
                                                        })}
                                                        options={allCountries}                                                         
                                                        placeholder="Select the Country"
                                                        onBlur={this.handleBlur('country')}                                                        
                                                        value={countryObject && countryObject[0]}                                                        
                                                        style={{
                                                            minWidth: 250,
                                                            borderRadius: 5
                                                        }}
                                                        onChange={this.updateFormHandler('country')} 
                                                        className={`${((touched.country && !country) || (errorFields.country)) && 'error__fields-indicator'}`}
                                                    />}                                                
                                            </div>                                            
                                        </div>
                                    </div>                                    
                                    <div className="eb-forms__form-group">
                                        <label htmlFor="street">Address </label>
                                        <div className="eb-forms--not-fullwidth">                                           
                                            <input type="text" 
                                                id="street"                                                 
                                                name="street"
                                                placeholder="Number/Street"                
                                                defaultValue={(street && street)}                                                                                              
                                                onChange={this.updateFormHandler('street')}                                                
                                            />
                                            <input type="text" 
                                                id="town-suburb"                                                 
                                                name="town-suburb"
                                                placeholder="Town/Suburb" 
                                                onChange={this.updateFormHandler('town')}            
                                                defaultValue={(values.town ? values.town : '') || (town !== 'null' ? town : '')}                                                                                     
                                            /> 
                                            <input 
                                                type="text" 
                                                id="postcode"                                                 
                                                name="postcode"
                                                placeholder="Postcode"                                                 
                                                onChange={this.updateFormHandler('postcode')}
                                                defaultValue={(values.postcode ? values.postcode : '') || (postcode !== 'null' ? postcode : '')}      
                                            />
                                        </div>
                                    </div>
                                    <div className="eb-forms__form-group">
                                        <label htmlFor="website">Website <span className="required__indicator">*</span></label>
                                        <div className="profile__input-container">
                                            <input 
                                                type="text" 
                                                id="website"                                             
                                                name="website"
                                                placeholder="URL"                                             
                                                onBlur={this.handleBlur('website')}                                                                                                                                                                                                                                                            
                                                onChange={this.updateFormHandler('website')}                                                                                                                                                                                                                                                                                             
                                                defaultValue={(values.website ? values.website : '') || (website !== 'null' ? website : '')}      
                                                className={`${((touched.website && !website) || (errorFields.website)) && 'error__fields-indicator'}`}                                            
                                            />
                                        </div>
                                    </div>
                                    <h6 className="profile__sub-title sub--heading_presentations">Help prospective clients understand the typical budget you work with</h6>
                                    <div className="eb-forms__form-group">
                                        <label htmlFor="job-cost">Typical Job Cost</label>
                                        <div className="eb-forms--not-fullwidth">
                                            <select 
                                                id="job-cost" 
                                                value={(values.jobCost && values.jobCost) || (jobCost !== 'null' && jobCost)}      
                                                onChange={this.updateFormHandler('jobCost')}
                                            >
                                                <option value="ALL">-- SELECT --</option>
                                                {jobCastTypes.map(eachType => <option key={eachType.key} value={eachType.key}>{eachType.text}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="eb-forms__form-group">
                                        <label htmlFor="cost-details">Cost Details</label>
                                        <textarea 
                                            id="cost-details" 
                                            name="cost-details"
                                            placeholder="Budget Range, Your Standard Fees and Charges. e.g. M.C. charge is $5,000 per day + GST"                                                         
                                            onChange={this.updateFormHandler('costDetails')}
                                            value={(values.costDetails ? values.costDetails : '') || (costDetails !== 'null' ? costDetails : '')}      
                                        ></textarea>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="profile__button-container profile__button-container--single">                           
                            <button                           
                                disabled={errorsInTheForm}      
                                onClick={this.saveAndContinue}
                                className="btn btn-gold profile__button profile__button-next"                                                                 
                            >Save and Next</button>                            
                        </div>
                    </div>
                </div>
            </>    
        )
    }
}

const mapStateToProps = ({ profile, session }) => ({
    loading: profile.loading,        
    isLoggedIn: session.isLoggedIn,
    formUpdated: profile.supplierBusinessInfomration.formUpdated
})
  
const mapDispatchToProps = dispatch => ({
    checkLoginStatus: () => dispatch(checkLoginStatus()),    
    hideNotification: () => dispatch(hideNotification()),
    loadSupplierTypes: () => dispatch(loadSupplierCategories()),    
    retrieveAllSupplierBusinessInformation: () => dispatch(retrieveAllSupplierBusinessInformation()),
    showNotification: (message, notificationType) => dispatch(showNotification(message, notificationType)),            
    supplierProfileBusinessFormUpdate: (field, value) => dispatch(supplierProfileBusinessFormUpdate(field, value)),              
    postCollectedSupplierBusinessInformation: postData => dispatch(postCollectedSupplierBusinessInformation(postData))
})
  
export default connect(mapStateToProps, mapDispatchToProps)(BusinessInformation)