import React, { Component } from 'react'
import Rodal from 'rodal'
import { connect } from "react-redux"

import { supplierContactFormUpdate } from '../../../actions/generalActions'

import '../../../screens/ProfileScreen/EditProfileScreen.css'
import './styles/find-pro-modal.css'

class FindProContactModal extends Component {

    render () {

        const {
            touched,
            handleBlur,
            errorFields,
            contactEmail,
            onCloseHandler,
            errorsInTheForm,
            showContactModal,
            updateFormHandler,
            contactTheSupplier,
            supplierEmailMessage
        } = this.props 

        return (
            <Rodal className="rodal--profile-pic" visible={showContactModal} onClose={onCloseHandler} animation={'slideDown'}>
                <header className="rodal__header">
                    <h4 className="rodal__heading">Contact Supplier</h4>
                </header>                
                <p className="profile__rodal-text">Please fill the message you want to communicate with this supplier!</p>
                <div className="rodal__main-container">
                    <div className="profile__section-content">
                        <form className="eb-forms eb-forms--profile">
                            <div className="eb-forms__form-group">
                                <label htmlFor="message">Message *</label>
                                <textarea 
                                    id="message" 
                                    name="message" 
                                    placeholder="Message"
                                    onBlur={handleBlur('supplierEmailMessage')}                                    
                                    onChange={updateFormHandler('supplierEmailMessage')}                                                                                
                                    value={(supplierEmailMessage ? supplierEmailMessage : '')}                                                          
                                    className={`${((touched && touched.supplierEmailMessage && !supplierEmailMessage) || (errorFields.supplierEmailMessage)) && 'error__fields-indicator'}`}
                                ></textarea>
                            </div>
                        </form>                            
                    </div>
                </div>
                <div className="rodal__btn-container">
                    <input type="button" className="btn btn-gold image-crop-complete" value="Contact" disabled={!supplierEmailMessage || errorsInTheForm} onClick={() => contactTheSupplier(contactEmail, supplierEmailMessage)} />
                </div>
            </Rodal>
        )
    }
}

const mapStateToProps = ({ general }) => ({
    supplierEmailMessage: general.supplierEmailMessage
})
  
const mapDispatchToProps = dispatch => ({
    supplierContactFormUpdate: (field, value) => dispatch(supplierContactFormUpdate(field, value))
})
  
export default connect(mapStateToProps, mapDispatchToProps)(FindProContactModal)