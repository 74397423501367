import React, { Component } from 'react'
import { connect } from "react-redux"
import InfiniteScroll from "react-infinite-scroll-component"

import {
    openGalleryModal,
    retrievePaginatedImagesOnScorll,
    loadAllEventInformationFromEventPhotoId,
} from '../../../actions/galleryActions'

import GalleryModal from './GalleryModal'
import { BackToTop } from '../../../reusables/BackToTop/BackToTop'
import { LazyLoad } from '../../../reusables/LazyLoad/LazyLoad'
import multiple from '../../../assets/images/icons/multiple.png'

import './styles/gallery.css'

class GalleryImages extends Component {    

    openGalleryModal = image_id => {  
        const { 
            openGalleryModal, 
            loadAllEventInformationFromEventPhotoId 
        } = this.props
        openGalleryModal()
        loadAllEventInformationFromEventPhotoId(image_id)
        document.querySelector('body').classList.add('modal-open')
    } 

    fetchMoreData = () => this.props.retrievePaginatedImagesOnScorll(this.props.currentEventTypeId)

    render() {

        const {
            allImages,
            pagesCount,
            currentPage,
        } = this.props

        return (
            <div className="gallery">                
                <InfiniteScroll
                    hasMore={true}
                    dataLength={allImages.length}
                    className="gallery__inifinite-scroll"
                    next={currentPage <= pagesCount ? this.fetchMoreData : null}
                >
                    {(allImages && allImages.length > 0) &&
                    allImages.map((eachImage, key) =>
                        <div className="gallery__image" 
                            // key={eachImage.imageId} 
                            key={key}
                            onClick={() => this.openGalleryModal(eachImage.imageId)}>
                            <LazyLoad src={eachImage.imageLink} style="gallery__image-item" alter="gallery" />
                            {eachImage.imageCount.imageCount > 1 && (
                                <div className="gallery__image--multiple">
                                    <img className="" src={multiple} alt="folder" />
                                </div>
                            )}
                            <div className="gallery__image-hover-overlay">
                                <span className="gallery__overlay-icons">
                                    <i className="fa fa-heart" aria-hidden="true"></i>{eachImage.likesResult.liked_count}
                                </span>
                                <span className="gallery__overlay-icons">
                                    <i className="fa fa-comment" aria-hidden="true"></i>{eachImage.event_comments_count.comments_count}
                                </span>
                            </div>
                        </div>
                        )
                    }
                </InfiniteScroll>
                {
                    currentPage < pagesCount && (
                        <div className="load-more-images">
                            <img className="load-more-icon" src="https://www.eliananunes.com/images/lazy_loader.gif" alt="loader" />
                        </div>
                    )
                }
                <GalleryModal />
                <BackToTop />
            </div>
        )
    }
}

const mapStateToProps = ({ gallery }) => ({  
    allImages: gallery.allImages,
    currentPage: gallery.currentPage,
    pagesCount: gallery.pagesCount
})
  
const mapDispatchToProps = dispatch => ({        
    openGalleryModal: () => dispatch(openGalleryModal()),
    retrievePaginatedImagesOnScorll: eventTypeId => dispatch(retrievePaginatedImagesOnScorll(eventTypeId)),
    loadAllEventInformationFromEventPhotoId: imageId => dispatch(loadAllEventInformationFromEventPhotoId(imageId)),        
})
  
export default connect(mapStateToProps, mapDispatchToProps)(GalleryImages)