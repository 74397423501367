import React, { Component } from 'react'
import PasswordMask from 'react-password-mask'
import { connect } from "react-redux"

import { authLogin, loginFormUpdate } from '../../../actions/sessionActions'
import { showNotification, hideNotification } from '../../../actions/notificationActions'
import { retrieveAllSupplierBusinessInformation } from '../../../actions/profileActions'

class SignIn extends Component {

    state = {
        user: {
            username: '',
            password: ''
        },
        touched: {
            username: false,
            password: false
        },
        errorFields: {
            username: false,
            password: false
        }
    }

    handleBlur = field => this.setState({ ...this.state, touched: { ...this.state.touched, [field]: true } })

    updateFormHandler = (field, value) => {
        this.setState({ ...this.state, user: {...this.state.user, [field]: value } }) 
        this.props.updateForm(field, value)
    }

    formSubmitHandler = e => {
        e.preventDefault()

        const { user } = this.state
        
        if ((user.username) && (user.password)) {                        
            this.props.authLogin().then(data => {
                if (data.status === "user_credentials_ok") {                    
                    this.props.retrieveAllSupplierBusinessInformation().then(profileInfo => {  
                        if (profileInfo.company_name && profileInfo.profile_photo && profileInfo.cover_image && profileInfo.company_bio && profileInfo.country && profileInfo.website) {
                            // this.props.history.push('add-event')
                            window.location.href = '/add-event'
                        } else {
                            // this.props.history.push('profile')
                            window.location.href = '/profile'
                        }
                    })
                } else if (data.status === "user_credentials_wrong") {
                    this.props.showNotification('Username or password incorrect!', 'error')
                    setTimeout(_ => this.props.hideNotification(), 6000)  
                } else if (data.status === "user_not_activated") {
                    this.props.showNotification('Please activate your account!', 'info')
                    setTimeout(_ => this.props.hideNotification(), 6000)  
                }
            })
        }
    }

    render () {
        const { user, touched, errorFields } = this.state

        return (
            <>
                <h5 className="login__title">Sign in</h5>
                <div className="login__form-container">
                    <form className="eb-forms" onSubmit={this.formSubmitHandler}>
                        <div className="eb-forms__form-group eb-forms__form-group--login">
                            <label htmlFor="emailAddress">Email</label>
                        <input 
                                type="text" 
                                id="emailAddress" 
                                placeholder="Enter email"
                                onBlur={() => this.handleBlur('username')}
                                onChange={e => this.updateFormHandler('username', e.target.value)} 
                                value={user.username}
                            />                                    
                            {((touched.username && !user.username) || (errorFields.username)) && <span className="error">Enter a valid email address</span>}                        
                        </div>
                        <div className="eb-forms__form-group eb-forms__form-group--login">
                            <label htmlFor="passwordLogin">Password</label>
                            <PasswordMask
                                name="password"
                                value={user.password}                                        
                                id="passwordLogin"
                                className="passwordMask"
                                placeholder="Enter password"    
                                onBlur={() => this.handleBlur('password')}                                    
                                onChange={e => this.updateFormHandler('password', e.target.value)} 
                            />
                            <span className="signup__password-hint">Use 8 or more characters with a mix of letters, numbers and symbols</span>
                            {((touched.password && !user.password) || (errorFields.password)) && <span className="error">Enter a valid password</span>}                        
                        </div>
                        <button type="submit" className="btn btn-gold signup__button" disabled={(user.email === "" || user.password === "")}>Sign In</button>
                    </form>     
                </div>
                <footer className="login-form__footer">
                    <p className="login-form__footer-copy">Forget your password? <a onClick={() => this.props.resetPassword()}>Reset password</a></p>
                    <span className="signup__password-hint">By signing up, signing in or continuing, I agree to EventBuzz360's <a target="_blank" href="/terms-of-use">Terms of Use</a> and <a target="_blank" href="/privacy-policy">Privacy Policy</a></span>
                </footer> 
            </>
        )
    }  
}

const mapStateToProps = ({ session }) => ({    
    username: session.username,
    password: session.password    
})
  
const mapDispatchToProps = dispatch => ({
    authLogin: _ => dispatch(authLogin()),
    hideNotification: _ => dispatch(hideNotification()),    
    updateForm: (field, value) => dispatch(loginFormUpdate({ field, value })),            
    showNotification: (message, notificationType) => dispatch(showNotification(message, notificationType)),
    retrieveAllSupplierBusinessInformation: userId => dispatch(retrieveAllSupplierBusinessInformation(userId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)