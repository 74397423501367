import { ActionTypes as types } from '../constants/newLogin';

export const openModal = () => ({
    type: types.OPEN_MODAL
})

export const closeModal = () => ({
    type: types.CLOSE_MODAL
})

export const setUserType = userType => ({
    type: types.SET_USER_TYPE,
    data: userType
})

export const changeStepOnUserType = () => ({
    type: types.CHANGE_STEP_USER
})

export const resetPassword = () => ({
    type: types.RESET_PASSWORD
})

export const setNewUserType = (userType, section) => ({
    type: types.SET_NEW_USER_TYPE,
    data: { userType: userType, section: section }
})

export const nextStep = () => ({
    type: types.GOTO_NEXT_STEP
})