import React, { Component } from 'react'
import { connect } from "react-redux"
import PasswordMask from 'react-password-mask'

import { checkLoginStatus } from '../../actions/sessionActions'
import { supplierProfileFormGenericUpdate, processSupplierProfileChangePassword } from '../../actions/profileActions'
import { showNotification, hideNotification } from "../../actions/notificationActions"

class Password extends Component {

    state = { 
        oldPassword: "",
        newPassword: "",
        errorFields: {
            oldPassword: false,
            newPassword: false
        },
        touched: {
            oldPassword: false,
            newPassword: false
        },
        userId: null,
        errorsInTheForm: true
    }

    componentDidMount () {
        this.props.checkLoginStatus().then(data => data)
        setTimeout(() => { this.setState({ overlay: false }) }, 100)   
    }

    updateFormHandler = field => event => {
        this.props.supplierProfileFormGenericUpdate(field, event.target.value)    
        this.setState({ [field]: event.target.value })            
    }

    handleBlur = field => event => this.setState({ touched: { ...this.state.touched, [field]: true }, errorFields: { [field]: (!event.target.value) ? true : false }, errorsInTheForm: (!event.target.value) ? true : false })

    formSubmitHandler = e => {
        e.preventDefault()
        if (this.state.oldPassword !== '' && this.state.newPassword !== '') {
            const postData = {
                oldPassword: this.state.oldPassword,
                newPassword: this.state.newPassword
            }        
            this.props.processSupplierProfileChangePassword(postData).then(data => {
                if (data.status === 'supplier_password_updated') {
                    this.props.showNotification('Your password have been updated!.', 'success')
                    this.setState({ oldPassword: '', newPassword: '', touched: {
                        oldPassword: false,
                        newPassword: false
                    }, errorFields: {
                        oldPassword: false,
                        newPassword: false
                    } })
                    setTimeout(_ => this.props.hideNotification(), 5000)                                    
                } else if (data.status === 'supplier_old_new_equal') {
                    this.props.showNotification('Your new password and old passwords are same!.', 'error')
                    setTimeout(_ => this.props.hideNotification(), 5000)                
                } else if (data.status === 'supplier_oldpassword_wrong') {
                    this.props.showNotification('Your old password is wrong!.', 'error')
                    setTimeout(_ => this.props.hideNotification(), 5000)                
                } 
            })
        }
    } 

    render() {

        const {
            touched,
            oldPassword,
            newPassword,
            errorFields
        } = this.state

        if (!this.props.isLoggedIn) {
            // window.location.href = "/"
        }

        return (
            <div className="profile__change-password">
                <div className="profile__section">
                    <div className="profile__section-content width__Half">
                        <div className="profile__form-container">
                            <form className="eb-forms eb-forms--profile eb-forms--profile-password">
                                <div className="eb-forms__form-group">
                                    <label htmlFor="oldPassword">Old Password <span className="required__indicator">*</span></label>
                                    <div className="eb-forms--not-fullwidth">
                                        <PasswordMask
                                            id="oldPassword"
                                            name="oldPassword"
                                            value={oldPassword}
                                            useVendorStyles={true}
                                            className="passwordMask"
                                            placeholder="Enter old password"    
                                            onBlur={this.handleBlur('oldPassword')}
                                            onChange={this.updateFormHandler('oldPassword')} 
                                            className={`passwordMask password__mask-wrapper ${((touched.oldPassword && !oldPassword) || (errorFields.oldPassword)) && 'error__fields-indicator-profile'}`}
                                        /> 
                                    </div>                                    
                                </div>
                                <div className="eb-forms__form-group">
                                    <label htmlFor="newPassword">New Password <span className="required__indicator">*</span></label>
                                    <div className="eb-forms--not-fullwidth">
                                        <PasswordMask
                                            id="newPassword"
                                            name="newPassword"
                                            value={newPassword}   
                                            useVendorStyles={true}                                                                            
                                            className="passwordMask"
                                            placeholder="Enter new password"  
                                            onBlur={this.handleBlur('newPassword')}  
                                            onChange={this.updateFormHandler('newPassword')} 
                                            className={`passwordMask password__mask-wrapper ${((touched.newPassword && !newPassword) || (errorFields.newPassword)) && 'error__fields-indicator-profile'}`}
                                        />  
                                    </div>                                                                                                                     
                                </div>
                            </form>
                        </div>
                        <div className="profile__tips-section"></div>
                    </div>
                    <div className="profile__button-container width__Half">
                        <button className="btn btn-gold profile__button profile__button-next" disabled={!oldPassword || !newPassword} onClick={this.formSubmitHandler}>Change Password</button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ profile, session }) => ({
    loading: profile.loading,        
    isLoggedIn: session.isLoggedIn,
    oldPassword: profile.oldPassword,
    newPassword: profile.newPassword
})
  
const mapDispatchToProps = dispatch => ({
    checkLoginStatus: _ => dispatch(checkLoginStatus()),    
    hideNotification: _ => dispatch(hideNotification()),    
    showNotification: (message, notificationType) => dispatch(showNotification(message, notificationType)),    
    processSupplierProfileChangePassword: postData => dispatch(processSupplierProfileChangePassword(postData)),
    supplierProfileFormGenericUpdate: (field, value) => dispatch(supplierProfileFormGenericUpdate(field, value))    
})
  
export default connect(mapStateToProps, mapDispatchToProps)(Password)  