import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

import arrowIcon from '../../../assets/images/icons/arrow.svg'

export class FindProSidebar extends Component {

    state = {
        sidebar: true
    }

    toggleSidebar = () => this.setState({ sidebar: !this.state.sidebar })

    render() { 

        const { sidebar } = this.state
        const { 
            main,
            mainCat,
            relatedSubServices,
        } = this.props

        const sidebarLinks = (relatedSubServices && relatedSubServices.map) && relatedSubServices.map((eachSubService, i) => {
            return (
                <li className="find-pro__sidebar-list-item">
                    <NavLink key={eachSubService.id} to={`/find-pro-details/${mainCat}/${main}/${eachSubService.category_name}/${eachSubService.id}`} activeClassName="active" className="find-pro__sidebar-list-link">{eachSubService.category_name}</NavLink>
                </li>
            )
        })

        return (
            <div className={sidebar ? "find-pro__sidebar find-pro__sidebar--open" : "find-pro__sidebar"}>
                <button className="profile__sidebar-trigger find-pro__sidebar-trigger" onClick={this.toggleSidebar}>
                    {sidebar ? (
                        <img src={arrowIcon} alt="icon" className="profile__sidebar-trigger-icon" />
                    ) : ( <img src={arrowIcon} alt="icon" className="profile__sidebar-trigger-icon" /> )
                    }            
                </button>
                <ul className="find-pro__sidebar-list">{sidebarLinks}</ul>
            </div>
        )
    }
}