import React from 'react'

import facebookIcon from '../../../assets/images/icons/icon_facebook1.png'
import instagramIcon from '../../../assets/images/icons/icon_instagram1.png'
import pinterestIcon from '../../../assets/images/icons/icon_pinterest1.png'
import linkedinIcon from '../../../assets/images/icons/icon_linkedin1.png'
import youtubeIcon from '../../../assets/images/icons/icon_youtube.jpg'
import blocgIcon from '../../../assets/images/icons/icon_rss.jpg'
import vimeoIcon from '../../../assets/images/icons/vimeo.jpg'

import Accordion from '../../../reusables/Accordian/Accordian'
import {
    formatUrl,
    cleanTheWebSiteUrlAndReturnPlainURL
} from "../../../utils/misc"

export const Overview = ({      
    areas, 
    supplier,
    services,
    activeTab,  
    otherAreas, 
    activateTab, 
    mainServices, 
    mainServiceId,     
    subServicesInfo, 
    otherSubServicesInfo 
}) => (
    <div className="supplier-profile__overview">
        <div className="supplier-profile__description rich-text">
            {supplier && supplier.company_bio !== '' && supplier.company_bio !== null && supplier.company_bio !== 'null' && (
                <div className="supplier-profile__company-data supplier-profile__company-data--company-bio">
                    <span className="profile__basic-infomation-labeles">Company Bio:</span>
                    <div dangerouslySetInnerHTML={{__html: supplier && supplier.company_bio}}></div>
                </div>
            )}
            {supplier && supplier.business_awards !== '' && supplier.business_awards !== null && supplier.business_awards !== 'null' && (
                <div className="supplier-profile__company-data supplier-profile__company-data--business-awards">
                    <span className="profile__basic-infomation-labeles">Company Awards:</span>
                    <div>{supplier.business_awards}</div>
                </div>
            )}
            {(services && services.length > 0) &&
                <div className="supplier-profile__company-data supplier-profile__company-data--services">
                    <span className="profile__basic-infomation-labeles">Services Provided:</span>
                    {(mainServices && mainServices.length > 0) &&
                    <>
                        <div className="profile__section-content">                                                        
                            <Accordion                                                                     
                                panels={mainServices} 
                                activeTab={activeTab} 
                                serviceId={mainServiceId}
                                activateTab={activateTab} 
                                subServicesInfo={subServicesInfo} 
                                otherSubServicesInfo={otherSubServicesInfo}                                                                    
                            />                        
                        </div>
                    </>}
                </div>
            }
            {(areas && areas.length > 0) &&
                <div className="supplier-profile__company-data supplier-profile__company-data--areas">
                    <span className="profile__basic-infomation-labeles">Areas Served:</span>
                    <ul className="supplier-profile__areas-list">
                        {areas && areas.map(eachLocationInfor => 
                            <li>{eachLocationInfor.City} ({eachLocationInfor.Region} - {eachLocationInfor.CountryName})</li>
                        )}  
                        {otherAreas && otherAreas.map(eachLocationInfor => 
                            <li>{eachLocationInfor.City} ({eachLocationInfor.CountryName})</li>
                        )}  
                    </ul>  
                </div>
            }                  
        </div>
        <div className="supplier-profile__sidebar">
            {/* <button className="btn supplier-profile__save">+ Save</button> */}
            <p className="supplier-profile__contact-name"><span className="profile__basic-infomation-labeles">Contact:</span> {supplier && supplier.first_name && supplier.first_name} {supplier && supplier.surname && supplier.surname}</p>
            <address className="supplier-profile__address">
                <span className="profile__basic-infomation-labeles">Address: </span> 
                { supplier && supplier.address && (supplier.address + ", ") }
                { supplier && supplier.town && (supplier.town + ", ") }
                { supplier.city && supplier.city && (supplier.city + ", ") }
                { supplier && supplier.post_code && (supplier.post_code + ", ") }
                { supplier && supplier.country && (supplier.country) }
            </address>  
            <p className="supplier-profile__contact-name">            
            {supplier && supplier.typical_job_cast_type !== '' && supplier.typical_job_cast_type !== 'null' && supplier.typical_job_cast_type !== null && (
                <>
                    <span className="profile__basic-infomation-labeles">Typical Job:</span>
                    <div>
                        <label>Cost: </label>
                        {supplier && supplier.typical_job_cast_type}
                    </div>
                </>
            )}                
            {supplier && supplier.cost_details !== '' && supplier.cost_details !== 'null' && supplier.cost_details !== null && (
                <div>
                    <lable>Cost details: </lable>
                    {supplier && supplier.cost_details}
                </div>
            )} 
            </p>       
            <div className="supplier-profile__social-media">
                <ul>
                    {supplier.blog !== null && supplier.blog !== 'null' && supplier.blog !== "" &&
                        <li className="supplier-profile__social-media-list">
                            <a className="supplier-profile__social-media-link" target="_blank" rel="noopener noreferrer" href={`${supplier && supplier.blog ? formatUrl(cleanTheWebSiteUrlAndReturnPlainURL(supplier.blog)) : ''}`}>
                                <img src={blocgIcon} alt="facebook" />
                            </a>
                        </li>
                    }
                    {supplier.video !== null && supplier.video !== 'null' && supplier.video !== "" &&
                        <li className="supplier-profile__social-media-list">
                            <a className="supplier-profile__social-media-link" target="_blank" rel="noopener noreferrer" href={`${supplier && supplier.video ? formatUrl(cleanTheWebSiteUrlAndReturnPlainURL(supplier.video)) : ''}`}>
                                <img src={youtubeIcon} alt="facebook" />
                            </a>
                        </li>
                    }
                    {supplier.facebook !== null && supplier.facebook !== 'null' && supplier.facebook !== "" &&
                        <li className="supplier-profile__social-media-list">
                            <a className="supplier-profile__social-media-link" target="_blank" rel="noopener noreferrer" href={`${supplier && supplier.facebook ? formatUrl(cleanTheWebSiteUrlAndReturnPlainURL(supplier.facebook)) : ''}`}>
                                <img src={facebookIcon} alt="facebook" />
                            </a>
                        </li>
                    }
                    {supplier.instagram !== null && supplier.instagram !== 'null' && supplier.instagram !== "" &&
                        <li className="supplier-profile__social-media-list">
                            <a className="supplier-profile__social-media-link" target="_blank" rel="noopener noreferrer" href={`${supplier && supplier.instagram ? formatUrl(cleanTheWebSiteUrlAndReturnPlainURL(supplier.instagram)) : ''}`}>
                                <img src={instagramIcon} alt="instagram" />
                            </a>
                        </li>
                    }
                    {supplier.linkedIn !== null && supplier.linkedIn !== 'null' && supplier.linkedIn !== "" &&
                        <li className="supplier-profile__social-media-list">
                            <a className="supplier-profile__social-media-link" target="_blank" rel="noopener noreferrer" href={`${supplier && supplier.linkedIn ? formatUrl(cleanTheWebSiteUrlAndReturnPlainURL(supplier.linkedIn)) : ''}`}>
                                <img src={linkedinIcon} alt="instagram" />
                            </a>
                        </li>
                    }
                    {supplier.pinterest !== null && supplier.pinterest !== 'null' && supplier.pinterest !== "" &&
                        <li className="supplier-profile__social-media-list">
                            <a className="supplier-profile__social-media-link" target="_blank" rel="noopener noreferrer" href={`${supplier && supplier.pinterest ? formatUrl(cleanTheWebSiteUrlAndReturnPlainURL(supplier.pinterest)) : ''}`}>
                                <img src={pinterestIcon} alt="pinterest" />
                            </a>
                        </li>
                    }
                    {supplier.vimeo !== null && supplier.vimeo !== 'null' && supplier.vimeo !== "" &&
                        <li className="supplier-profile__social-media-list">
                            <a className="supplier-profile__social-media-link" target="_blank" rel="noopener noreferrer" href={`${supplier && supplier.vimeo ? formatUrl(cleanTheWebSiteUrlAndReturnPlainURL(supplier.vimeo)) : ''}`}>
                                <img src={vimeoIcon} alt="facebook" />
                            </a>
                        </li>
                    }
                </ul>
            </div>
        </div>
    </div>
)