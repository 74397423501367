import React, { Component } from 'react'
import { connect } from "react-redux"

import { checkLoginStatus } from '../../../actions/sessionActions'
import { 
    loadSubCategoriesForParentCategory,    
    loadSupplierOtherSubServicesForMainService,
    loadSupplierSubServicesForMainServicePublicProfile,
    loadMainServicesInformationForPublicProfileAction,
    loadSupplierOtherSubServicesForMainServicePublicProfile
} from "../../../actions/profileActions"
import {
    checkTheGivenCompanyNameIsValidInAction,
    fetchSupplierEventPhotosForPublicInAction,
    fetchSupplierPublicProfileServiceInformationInAction,
    fetchSupplierProfileBasicInformationForPublicInAction,
    fetchAllReviewsForSelectedSupplierPublicProfileInAction,
    fetchSupplierPublicProfileOtherServiceInformationInAction,
    fetchSupplierPublicProfileLocationMainInformationInAction,
    fetchSupplierPublicProfileOtherLocationInformationInAction
} from '../../../actions/galleryActions'

import { SupplierHeader } from './SupplierHeader'
import SupplierFilter from './SupplierFilter'
import { Overview } from './Overview'
import { Photos } from './Photos'
import Reviews from './Reviews'

import '../supplier/styles/supplier-profile.css'

class PublicProfile extends Component {

    state = {
        areas: [],        
        basic: {}, 
        reviews: [],
        services: [],  
        supplier: {
            basic: {},
            photos: [],
            reviews: []
        },   
        currentTab: 0,        
        otherAreas: [],   
        otherServices: [], 
        writeReviewVisbile: false,        
        tabs: [ 'overview', 'photos', 'reviews' ]
    }

    componentDidMount() {
        const currentUrl = window.location.href
        const splittedUrl = currentUrl.split('/')
        this.props.checkLoginStatus().then(data => data)        
        this.props.checkTheGivenCompanyNameIsValidInAction(decodeURI(splittedUrl[4])).then(data => {
            if (data.email) {
                this.setState({ supplierUserEmail: data.email })
                this.props.fetchSupplierProfileBasicInformationForPublicInAction(data.email).then(basic => this.setState({ basic }))
                this.props.fetchAllReviewsForSelectedSupplierPublicProfile(data.email).then(reviews => this.setState({ reviews }))
                this.props.fetchSupplierEventPhotosForPublicInAction(data.email).then(photos => this.setState({ photos }))
                this.props.fetchSupplierPublicProfileLocationMainInformationInAction(data.email).then(areas => this.setState({ areas }))
                this.props.fetchSupplierPublicProfileOtherLocationInformationInAction(data.email).then(otherAreas => this.setState({ otherAreas }))   
                this.props.loadMainServicesInformationForPublicProfileAction(data.email).then(mainServices => this.setState({ mainServices }))
                this.props.loadSubCategoriesForParentCategory(data.supplier_type_id).then(data => {
                    if (data.length > 0) {                                     
                        this.setState({ 
                            supplierSubTypes: data,
                            isSupplierTypeDisabled: true,
                        }) 
                    }
                })
            } else {
                window.location.href = "/gallery"
            }
        })
    }

    selectedTab = key => this.setState({ currentTab: key })

    showWriteReview = () => this.setState({ writeReviewVisbile: true, currentTab: 2 })

    hideWriteReview = () => this.setState({ writeReviewVisbile: false })

    goToTab = key => {
        this.setState({ currentTab: key })
        this.selectedTab(key)
    }

    activateTab = (index, mainServiceId) => {
        const { activeTab, supplierUserEmail } = this.state
        if (activeTab === parseInt(mainServiceId) ) {
            this.setState({ activeTab: 0 })
        } else { 
            this.setState({ activeTab: parseInt(mainServiceId) }, () => (
                this.props.loadSupplierSubServicesForMainServicePublicProfile(mainServiceId, supplierUserEmail).then(subServicesInfo => {                
                    this.props.loadSupplierOtherSubServicesForMainServicePublicProfile(mainServiceId, supplierUserEmail).then(otherSubServicesInfo => {
                        this.setState({ 
                            subServicesInfo: subServicesInfo,
                            otherSubServicesInfo: otherSubServicesInfo
                        })
                    })
                })    
            ))
        }
    }

    render() {

        let activeComponent = null
        const { 
            tabs,
            basic,
            areas,
            photos,
            reviews,
            supplier,
            services,
            activeTab,
            otherAreas,
            currentTab,
            mainServices,
            otherServices,
            subServicesInfo,
            supplierUserEmail,
            writeReviewVisbile
        } = this.state
        const { emailAddress } = this.props

        if (currentTab === 1) {
            activeComponent = <Photos photos={photos} />
        }
        if (currentTab === 2) {
            activeComponent = <Reviews 
                                    reviews={reviews} 
                                    logo={basic.profile_photo}     
                                    companyName={basic.company_name}    
                                    supplierUserEmail={supplierUserEmail}                                                                
                                    showWriteReview={this.showWriteReview} 
                                    hideWriteReview={this.hideWriteReview}                                                                         
                                    writeReviewVisbile={writeReviewVisbile}                                     
                                />                                                
        }
        if (!currentTab) {
            console.log(supplier)
            activeComponent = <Overview                                 
                                areas={areas}                                
                                supplier={basic}
                                services={services} 
                                activeTab={activeTab}
                                otherAreas={otherAreas}                                                                  
                                mainServices={mainServices}                                
                                otherServices={otherServices}
                                activateTab={this.activateTab}
                                subServicesInfo={subServicesInfo}
                            />
        }

        return (
            <div className="main">
                <SupplierHeader 
                    supplier={basic}
                    reviews={reviews}
                    loggedUserEmail={emailAddress}                                                                   
                    showWriteReview={this.showWriteReview} 
                />
                <SupplierFilter 
                    tabs={tabs} 
                    goToTab={this.goToTab}
                    currentTab={currentTab}                    
                    website={basic.website}  
                    country={basic.country}
                    number={basic.tel_no}                     
                    activeTab={this.selectedTab}
                />
                {activeComponent}
            </div>
        )
    }
}

const mapStateToProps = ({ session }) => ({
    emailAddress: session.emailAddress,
})

const mapDispatchToProps = dispatch => ({
    checkLoginStatus: () => dispatch(checkLoginStatus()),    
    loadSubCategoriesForParentCategory: parentCatId => dispatch(loadSubCategoriesForParentCategory(parentCatId)),    
    fetchSupplierEventPhotosForPublicInAction: userEmail => dispatch(fetchSupplierEventPhotosForPublicInAction(userEmail)),
    checkTheGivenCompanyNameIsValidInAction: companyName => dispatch(checkTheGivenCompanyNameIsValidInAction(companyName)),
    loadSupplierOtherSubServicesForMainService: userEmail => dispatch(loadSupplierOtherSubServicesForMainService(userEmail)),    
    fetchSupplierPublicProfileServiceInformation: userEmail => dispatch(fetchSupplierPublicProfileServiceInformationInAction(userEmail)),    
    loadMainServicesInformationForPublicProfileAction: userEmail => dispatch(loadMainServicesInformationForPublicProfileAction(userEmail)),
    fetchAllReviewsForSelectedSupplierPublicProfile: userEmail => dispatch(fetchAllReviewsForSelectedSupplierPublicProfileInAction(userEmail)),           
    fetchSupplierProfileBasicInformationForPublicInAction: userEmail => dispatch(fetchSupplierProfileBasicInformationForPublicInAction(userEmail)),    
    fetchSupplierPublicProfileLocationMainInformationInAction: userEmail => dispatch(fetchSupplierPublicProfileLocationMainInformationInAction(userEmail)),
    fetchSupplierPublicProfileOtherServiceInformationInAction: userEmail => dispatch(fetchSupplierPublicProfileOtherServiceInformationInAction(userEmail)),
    fetchSupplierPublicProfileOtherLocationInformationInAction: userEmail => dispatch(fetchSupplierPublicProfileOtherLocationInformationInAction(userEmail)),        
    loadSupplierSubServicesForMainServicePublicProfile: (mainServiceId, userEmail) => dispatch(loadSupplierSubServicesForMainServicePublicProfile(mainServiceId, userEmail)),
    loadSupplierOtherSubServicesForMainServicePublicProfile: (mainServiceId, userEmail) => dispatch(loadSupplierOtherSubServicesForMainServicePublicProfile(mainServiceId, userEmail))
})

export default connect(mapStateToProps, mapDispatchToProps)(PublicProfile)
