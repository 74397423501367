import BackendClient from './backendClient'

export const supplierActivation = key => BackendClient.post('supplier/account-activate', { "key": key })

export const postSubscribe = ({ firstName, lastName, emailAddress, company }) => BackendClient.post("subscribe", {
    "emailAddress": emailAddress,
    "firstName": firstName,
    "lastName": lastName,
    "company": company
})

export const supplierRegister = (email, password, userType, firstName, lastName, company) => BackendClient.post(`supplier/register?userType=${userType}`, {
    "email": email,
    "password": password,
    "firstName": firstName,
    "lastName": lastName,
    "company": company
})

export const checkUsername = email => BackendClient.postForAuth("auth/check-username", { "email": email })

export const getAuthToken = email => BackendClient.postForAuth("auth/token", { "email": email })

export const authLoginToken = (email, password, token) => BackendClient.postForAuth("auth/login", {
    "email": email,
    "password": password
}, token)

export const authLoginTokenWithoutPassword = (email, token) => BackendClient.postForAuth("auth/login-initial", { "email": email }, token)

export const revokeToken = accessToken => BackendClient.postForAuth("auth/revoke-token", {}, accessToken)

export const PasswordReset = email => BackendClient.postForAuth("auth/reset-password", { "email": email })