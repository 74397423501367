import React, { Component } from 'react'

import HireContactModal from '../../IndexScreen/hire/HireContactModal'
import { LazyLoad } from '../../../reusables/LazyLoad/LazyLoad'

import { checkTheStringThenTruncate, replaceGenericNameSpacesWithDashes } from '../../../utils/misc'
// import { galleryImagesUploadingPath } from '../../../utils/config'

export default class HireGallery extends Component {

    state = {

    }

    render() {

        const { 
            touched,
            connect,
            handleBlur,
            errorFields,
            mainHeading,
            contactEmail, 
            onCloseHandler,
            errorsInTheForm,
            showContactModal,             
            updateFormHandler,
            contactTheSupplier,
            relatedSupplierInfo,
            supplierEmailMessage
        } = this.props

        if (!relatedSupplierInfo || relatedSupplierInfo.length === 0) return <h4 className="no__suppliers-error">{'No suppliers found!'}</h4>

        return (  
            <div className="find-pro__gallery-container">
                <div className="find-pro__gallery-title">
                    <h3>{mainHeading}</h3>
                </div>      
                <div className="find-pro__gallery">
                    {(relatedSupplierInfo && relatedSupplierInfo.length > 0) && relatedSupplierInfo.map(eachSupplier => 
                        <div className="find-pro__gallery__container">
                            <div className="find-pro__gallery__image">
                                <LazyLoad 
                                    src={eachSupplier.company_photos.image_link}
                                    style="find-pro__gallery__image-item" alter="Hire" />
                                {/* <img className="find-pro__gallery__image-item" src={galleryImagesUploadingPath + eachSupplier.company_photos.image_link} alt="gallery image" /> */}
                            </div>
                            <h5 className="find-pro__gallery-heading"><a href={`../../supplier-public/${replaceGenericNameSpacesWithDashes(eachSupplier.company_info.company_name)}`}>{eachSupplier.company_info.company_name}</a></h5>
                            <p className="find-pro__description">{eachSupplier.company_info.company_bio && checkTheStringThenTruncate(eachSupplier.company_info.company_bio, 210)}</p>
                            <div className="find-pro__footer">
                                <ul className="find-pro__tag-list">
                                    {(eachSupplier.event_keywords && eachSupplier.event_keywords.map) && eachSupplier.event_keywords.map(eachTag => <li className="find-pro__tag-list-item">{eachTag}</li>)}
                                </ul>
                                <button type="submit" className="btn btn-gold" onClick={() => connect(eachSupplier.contact_info.email_address && eachSupplier.contact_info.email_address)}>Contact</button>
                            </div>
                        </div>
                    )}
                </div>
                <HireContactModal 
                    touched={touched}
                    handleBlur={handleBlur}
                    errorFields={errorFields}
                    contactEmail={contactEmail}
                    onCloseHandler={onCloseHandler}
                    errorsInTheForm={errorsInTheForm}
                    showContactModal={showContactModal}
                    updateFormHandler={updateFormHandler}
                    contactTheSupplier={contactTheSupplier}
                    supplierEmailMessage={supplierEmailMessage}
                />
            </div>
        )
    }
}