import React, { Component } from 'react'
import { connect } from "react-redux"

import { checkLoginStatus } from '../../actions/sessionActions'

import ComingSoon from '../../reusables/ComingSoon/ComingSoon'

class Advertise extends Component {

    state = {
        userId: null
    }

    componentDidMount () {
        this.props.checkLoginStatus().then(data => data)
        setTimeout(_ => { this.setState({ overlay: false }) }, 100)   
    }

    render() {

        return (
            <div className="adverts__class-override">
                <ComingSoon />
            </div>            
            // <div className="profile__advertise">
            //     <div className="profile__section">
            //         <h6 className="profile__sub-title">
            //             Get in front of event organisers who are actively planning and designing their events
            //         </h6>
            //         <div className="profile__section-content">
            //             <div className="profile__form-container">
            //                 <form className="eb-forms eb-forms--profile">  
            //                     <div className="eb-forms__form-group">
            //                         <div className="profile__button-container profile__button-container--reviews">
            //                             <button className="btn btn-gold profile__button profile__button-next">Check Availability</button>
            //                             <button className="btn btn-white profile__button-skip">Skip for now</button>
            //                         </div>
            //                     </div>
            //                 </form>
            //             </div>
            //         </div>
            //     </div>
            // </div>
        )
    }
}

const mapStateToProps = ({ session }) => ({
    userId: session.userId,
})
  
const mapDispatchToProps = dispatch => ({
    checkLoginStatus: _ => dispatch(checkLoginStatus())
})
  
export default connect(mapStateToProps, mapDispatchToProps)(Advertise)  