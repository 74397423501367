import React, { Component } from 'react'
import { connect } from "react-redux"
import Select from 'react-select'

import { checkLoginStatus } from '../../actions/sessionActions'
import {
    removeMainServiceAreas,
    removeAddionalServiceAreas,
    loadProfileServedCountries,
    loadProfileServedOtherAreas,
    postSupplierLocationInformationAction,
    retrieveAllSupplierBusinessInformation
} from '../../actions/profileActions'
import { showNotification, hideNotification } from "../../actions/notificationActions"
import { retrieveAllCountries, retrieveAllStatesByCountry, retrieveAllCitiesByStateAndCountry } from '../../actions/generalActions'

class Locations extends Component {

    state = { 
        gotoNext: false,
        city: "",
        state: "", 
        country: "", 
        allCities: [],
        allStates: [],   
        cityObject: {},    
        stateObject: {},         
        allCountries: [],                
        countryObject: {},
        countryAsName: "",        
        servedLocations: [],
        allUnselected: false,
        errorsInTheForm: true,    
        selectedCityObject: {},       
        selectedStateObject: {},     
        loggedInRealStatus: true,        
        otherServedLocations: [],
        selectedCountryObject: {},
        additionalServiceAreas: [],        
        showForAddionalLocations: false,
        showNewAdditionalServiceAreasBlock: false
    }

    componentDidMount () {                             
        this.props.checkLoginStatus().then(data => {
            if (data) {
                this._loadProfileLocationInformation()
            }            
        })
    }

    _loadProfileLocationInformation = _ => {
        this.props.retrieveAllSupplierBusinessInformation().then(supplierInfo => {  
            if (supplierInfo !== null) {
                this.setState({                                 
                    country: supplierInfo.country,
                    countryAsName: this.state.allCountries.filter(eachCountry => eachCountry.value === supplierInfo.country && eachCountry) 
                })        
                this.props.loadProfileServedCountries().then(servedLocations => this.setState({ servedLocations })) 
                this.props.loadProfileServedOtherAreas().then(otherServedLocations => this.setState({ otherServedLocations }))
                this.props.retrieveAllCountries().then(allCountries => {
                    this.setState({ allCountries })     
                    supplierInfo.country && this.props.retrieveAllStatesByCountry(supplierInfo.country).then(allStates => this.setState({ allStates }))                            
                    allCountries.map(eachCountry => {
                        let contryObject = {
                            value: eachCountry.id,
                            label: eachCountry.name
                        }
                        this.setState({ allCountries: this.state.allCountries.concat(contryObject) })
                    })
                })  
            }
        })                
    }    

    onRegionCheck = code => {
        const { regionsSelected } = this.state
        this.setState({ regionsSelected: this.state.regionsSelected.concat(code), allUnselected: true })
        if (regionsSelected.indexOf(code) !== -1) this.setState({ regionsSelected: regionsSelected.filter(item => item != code) })
    }

    addAdditionalServiceAreasToQueue = e => {
        const currentServiceArea = e.target.value
        const { additionalServiceAreas } = this.state
        if (currentServiceArea !== '') {
            e.target.value = ''
            this.setState({ additionalServiceAreas: this.state.additionalServiceAreas.concat(currentServiceArea) })
            if (((additionalServiceAreas.indexOf(currentServiceArea) !== -1)) && (currentServiceArea !== '')) {
                this.setState({ additionalServiceAreas: additionalServiceAreas.filter(item => item != currentServiceArea) })
            }
        }               
    }

    removeAddedAddtionalServiceArea = area => {
        const { additionalServiceAreas } = this.state
        if (((additionalServiceAreas.indexOf(area) !== -1)) && (additionalServiceAreas !== '')) {
            this.setState({ additionalServiceAreas: additionalServiceAreas.filter(item => item != area) })
        }
    }

    addAddionalServiceAreas = e => {
        e.preventDefault()
        this.setState({ showNewAdditionalServiceAreasBlock: true })
    }

    updateFormHandler = field => event => {     
        if ((field === "city") || (field === "state") || (field === "country")) {
            if (event && event.value) {
                this.setState({ 
                    [field]: event.value, 
                    errorFields: {
                        [field]: (!event.value) ? true : false
                    },
                    errorsInTheForm: (!event.value) ? true : false
                }) 
                if (field === "country") {
                    this.props.retrieveAllStatesByCountry(event.value).then(allStates => this.setState({ allStates: allStates, countryObject: event, stateObject: {}, cityObject: {} }))
                }            
                if (field === "state") {
                    this.props.retrieveAllCitiesByStateAndCountry(this.state.country, event.value).then(allCities => this.setState({ allCities: allCities, stateObject: event, cityObject: {} }))
                }     
                if (field === "city") {
                    this.setState({ cityObject: event })       
                }
            } else {
                if (field === "country") {
                    this.setState({ allStates: [], allCities: [] })       
                }       
                if (field === "state") {
                    this.setState({ allCities: [] })       
                }            
                this.setState({ [field]: '' }) 
            }
        }
    }

    showForAddionalLocationsTab = () => this.setState(prevState => ({ showForAddionalLocations: !prevState.showForAddionalLocations }))

    removeSelectedMainLocation = mainLocation => {
        this.props.removeMainServiceAreas(mainLocation.CountryName, mainLocation.City).then(() => {
            const { servedLocations } = this.state
            const filteredMainAreas = servedLocations.filter(eachMainArea => eachMainArea.City != mainLocation.City)
            this.setState({ servedLocations: filteredMainAreas })
            this.props.showNotification('Your location information have been updated!', 'success')
            setTimeout(() => this.props.hideNotification(), 6000)
        })
    }

    removeSelectedOtherLocation = otherLocation => {
        this.props.removeAddionalServiceAreas(otherLocation.CountryName, otherLocation.City).then(() => {
            const { otherServedLocations } = this.state
            const filteredOtherArea = otherServedLocations.filter(eachOtherArea => eachOtherArea.City != otherLocation.City)
            this.setState({ otherServedLocations: filteredOtherArea })
            this.props.showNotification('Your location information have been updated!', 'success')
            setTimeout(() => this.props.hideNotification(), 6000)
        })
    }

    addNewLocations = e => {
        e.preventDefault()
        const {
            cityObject,
            stateObject,
            countryObject,
            additionalServiceAreas            
        } = this.state        
        if ((countryObject.label !== '') && (stateObject.label !== '')) {
            const postData = {
                city: cityObject.label,
                state: stateObject.label,
                country: countryObject.label,                
                additionalServiceAreas: additionalServiceAreas
            }    
            this.props.postSupplierLocationInformationAction(postData).then(data => {
                if (data.status === "supplier_location_information_updated") {
                    this.setState({                                                 
                        allCities: [],
                        allStates: [], 
                        allUnselected: false,
                        cityObject: {}, 
                        stateObject: {}, 
                        countryObject: {},
                        gotoNext: true
                    })
                    this.props.showNotification('Your service area information have been updated!', 'success')
                } else {
                    this.props.showNotification('Something went wrong! Please contact support...', 'error')                         
                }
                setTimeout(() => this.props.hideNotification(), 5000)           
            })
        } 
    }

    render() {
        if (this.state.gotoNext) {
            window.location.href = "/supplier"
        }

        const { 
            state,
            country,
            allCities,
            allStates,
            cityObject,
            stateObject,
            allCountries,
            countryObject,
            servedLocations,
            otherServedLocations,
            additionalServiceAreas,
            showForAddionalLocations,
            showNewAdditionalServiceAreasBlock
        } = this.state
        
        const { loading, isLoggedIn } = this.props

        return (
            <>
                <div className={loading ? "overlay" : "overlay hide"}>
                    <div className="profile__loader-container">
                        <div className="loader"><span>Loading...</span></div>
                    </div>
                </div>  
                <div className="profile__services-location">
                    <div className="profile__section">
                    <h6 className="profile__sub-title sub--heading_presentations sub--heading_presentations">Select the countries and Cities you operate in</h6>
                        <div className="profile__section-content">
                            <div className="profile__form-container">
                                <form className="eb-forms eb-forms--profile">
                                    <div className="eb-forms__form-group">
                                        <label htmlFor="job-cost lower__case-text">Countries of operation</label>
                                        <div className="eb-forms--not-fullwidth">
                                            <div className="container">
                                                {(allCountries && allCountries.length > 0) && 
                                                    <Select 
                                                        isClearable   
                                                        value={countryObject}                                                                                                                  
                                                        className="searchable-dropdown"                                                    
                                                        placeholder="Select the Country"                                                   
                                                        options={allCountries && allCountries}                                                                                                                                                                                                      
                                                        onChange={this.updateFormHandler('country')}
                                                    />                                                
                                                }                                                
                                            </div>                                            
                                            <div className="container">
                                                {(allStates && allStates.length > 0) &&
                                                    <Select 
                                                        isClearable   
                                                        value={stateObject}                                                          
                                                        placeholder="Select the State"                                                        
                                                        className="searchable-dropdown"
                                                        options={allStates && allStates}                                                                                                                                                                                                             
                                                        onChange={this.updateFormHandler('state')}
                                                    />
                                                }
                                            </div> 
                                            <div className="container">
                                                {(allCities && allCities.length > 0) &&
                                                    <Select 
                                                        isClearable   
                                                        value={cityObject}                                                          
                                                        placeholder="Select the City"                                                        
                                                        className="searchable-dropdown"
                                                        options={allCities && allCities}                                                                                                                                                                                                                                                                     
                                                        onChange={this.updateFormHandler('city')}
                                                    />
                                                }
                                            </div>                                             
                                        </div>
                                    </div>
                                    <div className="eb-forms__form-group">
                                        <div className="eb-forms--not-fullwidth">
                                            <div className="profile__supplier-subcategories eb-checkbox">                                                                                                
                                                <label for="showForAdditionalLocations">Please check for additonal locations...</label>                                                
                                                <input type="checkbox" id="showForAdditionalLocations" onChange={this.showForAddionalLocationsTab} />
                                                <span className="checkmark"></span>
                                            </div>                                            
                                        </div>                                        
                                    </div>  
                                    {showForAddionalLocations &&
                                    <div className="eb-forms__form-group">
                                        <label>Other Areas Served</label>
                                        <div className="eb-forms--not-fullwidth">
                                            <input 
                                                type="text" 
                                                id="other-services" 
                                                name="other-services" 
                                                onBlur={this.addAdditionalServiceAreasToQueue}                                                
                                            />
                                            <button onClick={this.addAddionalServiceAreas} className="btn btn-gold btn-gold--profile-add-services">+ Add</button>
                                        </div>
                                    </div>}
                                    {(showNewAdditionalServiceAreasBlock && additionalServiceAreas && additionalServiceAreas.length > 0) && additionalServiceAreas.map((eachAdditionalServiceArea, i) => (
                                        <div key={i} className="additional-services__block">
                                            <label></label>                                            
                                            <svg viewBox='0 0 19.5 19.5' onClick={() => this.removeAddedAddtionalServiceArea(eachAdditionalServiceArea)}>
                                                <defs />
                                                <g id='Layer_2' data-name='Layer 2'>
                                                    <g id='Layer_1-2' data-name='Layer 1'>
                                                        <path 
                                                            className='cls-1' 
                                                            d='M.45.45a1.56,1.56,0,0,1,2.19,0L19.05,16.86a1.55,1.55,0,0,1-2.19,2.19L.45,2.64A1.56,1.56,0,0,1,.45.45Z'
                                                        />
                                                        <path 
                                                            className='cls-1' 
                                                            d='M19.05.45a1.56,1.56,0,0,1,0,2.19L2.64,19.05A1.55,1.55,0,0,1,.45,16.86L16.86.45A1.56,1.56,0,0,1,19.05.45Z'
                                                        />
                                                    </g>
                                                </g>
                                            </svg>
                                            <h6>{eachAdditionalServiceArea}</h6>
                                        </div>
                                    ))}   
                                </form>
                            </div>
                        </div>
                        <div className="profile__button-container">
                            <button className="btn btn-gold profile__button profile__button-next" disabled={!country || !state} onClick={this.addNewLocations}>Save</button>
                        </div>
                        {servedLocations && servedLocations.length > 0 && <h6 className="profile__sub-title sub--heading_presentations">Locations you are serving...</h6>}
                        <div className="profile__section-content">                            
                            {servedLocations && 
                                servedLocations.map(eachLocationInfor =>
                                    <div className="location-list">
                                        <div className={'sub__service-or-location-content-wrapper'}>
                                            <span className="close" onClick={() => this.removeSelectedMainLocation(eachLocationInfor)}>x</span>
                                            <p>{eachLocationInfor.city} ({eachLocationInfor.regions_served} - {eachLocationInfor.country})</p>
                                        </div>
                                    </div>
                            )}  
                            {otherServedLocations && 
                                otherServedLocations.map(eachOtherLocation =>
                                    <div className="location-list">
                                        <div className={'sub__service-or-location-content-wrapper'}>
                                            <span className="close" onClick={() => this.removeSelectedOtherLocation(eachOtherLocation)}>x</span>
                                            <p>{eachOtherLocation.City} ({eachOtherLocation.CountryName})</p>
                                        </div>
                                    </div>
                            )}                      
                        </div>
                    </div>                    
                </div>
            </>
        )
    }
}

const mapStateToProps = ({ profile, session }) => ({
    loading: profile.loading,
    isLoggedIn: session.isLoggedIn  
})
  
const mapDispatchToProps = dispatch => ({
    checkLoginStatus: _ => dispatch(checkLoginStatus()),    
    hideNotification: _ => dispatch(hideNotification()),    
    retrieveAllCountries: _ => dispatch(retrieveAllCountries()),
    loadProfileServedCountries: _ => dispatch(loadProfileServedCountries()),
    loadProfileServedOtherAreas: _ => dispatch(loadProfileServedOtherAreas()),
    retrieveAllStatesByCountry: countryId => dispatch(retrieveAllStatesByCountry(countryId)),
    removeMainServiceAreas: (country, city) => dispatch(removeMainServiceAreas(country, city)),
    retrieveAllSupplierBusinessInformation: _ => dispatch(retrieveAllSupplierBusinessInformation()),
    removeAddionalServiceAreas: (country, city) => dispatch(removeAddionalServiceAreas(country, city)),
    showNotification: (message, notificationType) => dispatch(showNotification(message, notificationType)),
    postSupplierLocationInformationAction: postData => dispatch(postSupplierLocationInformationAction(postData)),
    retrieveAllCitiesByStateAndCountry: (countryId, stateId) => dispatch(retrieveAllCitiesByStateAndCountry(countryId, stateId)),    
})
  
export default connect(mapStateToProps, mapDispatchToProps)(Locations)