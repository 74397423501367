import * as actions from "../actions/sessionActions"

const initialState = {
    userId: "",
    userType: 0,
    username: "",
    password: "",
    loading: false,
    displayName: "",
    accessToken: "",  
    emailAddress: "", 
    isLoggedIn: false        
}

export const sessionReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SESSION_INITIALISE:
            return {
                ...state,
                isLoggedIn: true,
                emailAddress: action.emailAddress, 
                accessToken: action.accessToken,
                displayName: action.displayName
            }
        case actions.LOGIN_FORM_UPDATE:
            return {
                ...state,
                [action.field]: action.value
            }
        case actions.SEND_LOGIN_FORM_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actions.SEND_LOGIN_FORM_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_LOGIN_FORM_REQUEST_FAILURE:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_REVOKE_TOKEN_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actions.SEND_REVOKE_TOKEN_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_REVOKE_TOKEN_REQUEST_FAILURE:
            return {
                ...state,
                loading: false
            }
        case actions.SUBMIT_EMAIL_FOR_PASSWORD_RESET_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actions.SUBMIT_EMAIL_FOR_PASSWORD_RESET_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actions.SUBMIT_EMAIL_FOR_PASSWORD_RESET_REQUEST_FAILURE:
            return {
                ...state,
                loading: false
            }
        case actions.UPDATE_DISPLAY_NAME_ACTION:
            localStorage.setItem('displayName', action.displayName)   
            return {
                ...state,
                displayName: action.displayName
            }                               
        case actions.SESSION_END:            
            return initialState
        default:
            return state
    }
}
