import { ActionTypes as types } from '../constants/newLogin';

const initialState = {
    step: 1,
    count: 1,
    userType: '',
    modalOpen: false,
    newUserType: '',
    newUserTypes: {
        supplier: [
            { "index": 1, "text": "I'm an Event Supplier" },
            { "index": 2, "text": "I'm an Events Company" },
            { "index": 3, "text": "I'm a Venue (coming soon)" },
            { "index": 4, "text": "I'm Looking for Event Ideas and Suppliers" }
        ],
        jobs: [
            { "index": 5, "text": "I'm an Employer Who Wants to Find Available People" },
            { "index": 6, "text": "I'm Searching for Work" }
        ]
    },
    supplier: false,
    jobs: false
}

export const newLoginReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.OPEN_MODAL:
            return {
                ...state,
                modalOpen: true,
                step: 1
            } 
        case types.CLOSE_MODAL:
            return {
                ...state,
                modalOpen: false,
                // count: state.count + 1
            }
        case types.SET_USER_TYPE:
            return {
                ...state,
                userType: action.data
            }
        case types.CHANGE_STEP_USER: 
            return {
                ...state,
                step: state.userType === 1 ? 2 : 4
            }
        case types.RESET_PASSWORD:
            return {
                ...state, 
                step: 5
            }
        case types.SET_NEW_USER_TYPE:
            return {
                ...state,
                newUserType: action.data.userType,
                supplier: action.data.section === 'supplier' ? true : false,
                jobs: action.data.section === 'jobs' ? true : false
            }
        case types.GOTO_NEXT_STEP: 
            return {
                ...state,
                step: state.step + 1
            }
        default:
            return state
    }
}