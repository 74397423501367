/*
 * Actions
 */
export const ActionTypes = {
    OPEN_MODAL: 'OPEN_MODAL',
    CLOSE_MODAL: 'CLOSE_MODAL',
    SET_USER_TYPE: 'SET_USER_TYPE',
    CHANGE_STEP_USER: 'CHANGE_STEP_USER',
    RESET_PASSWORD: 'RESET_PASSWORD',
    SET_NEW_USER_TYPE: 'SET_NEW_USER_TYPE',
    GOTO_NEXT_STEP: 'GOTO_NEXT_STEP'
}