import React, { Component } from 'react'
import { connect } from "react-redux"
import { Redirect } from 'react-router-dom'

import profileIcon from '../../assets/images/icons/Edit_Pencil_White.PNG'
import servicesIcon from '../../assets/images/icons/location.svg'
import advertiseIcon from '../../assets/images/icons/promote.svg'
import reviewsIcon from '../../assets/images/icons/reviews.svg'
import uploadIcon from '../../assets/images/icons/upload.svg'

import { Sidebar } from './Sidebar'
import jwt_decode from "jwt-decode";
import {backendBaseUrl} from "../../utils/config";

class ProfileLayout extends Component {

    state = {        
        activeTab: 1,
        sidebar: true,
        allStates: [],
        postFilter: '',
        selectedTab: 1,   
        selectedIndex: 0,         
        stateFilter: 'ALL',         
        country: {
            label: 'Australia',
            value: 'AU'
        },
        allowedUrlsList: [
            '/', '/home', '/review-policy',
            '/signup', '/login', '/gallery', 
            'discover', '/venues', '/about', 
            '/find-pro', 'find-pro-details', 
            '/hire', 'hire-details', '/wishlist', 
            '/supplier', '/profile', '/buttons-and-badges',
            '/my-folders', '/services', '/locations',
            '/supplier-reviews', '/my-reviews', '/upload',
            '/add-event', '/change-password', '/advertise', 
            '/my-adverts', '/reset-password', '/acceptable-use-policy', '/copy-right-trademark'
        ], 
        pages: [
        {
            'id': 1,
            'name': 'Upload',
            'url': 'add-event',
            'icon': uploadIcon,
            'subItems': [
            {
                'id': 1,
                'name': 'Upload new photos',
                'url': 'add-event',
                'parent': 1
            },{
                'id': 2,
                'name': 'Edit / Change posts',
                'url': 'my-folders',
                'parent': 1
            },]
        },              
        {
            'id': 2,
            'name': 'My Profile',
            'url': 'profile',
            'icon': profileIcon,
            'subItems': [
            {
                'id': 1,
                'name': 'edit profile',
                'url': 'profile',
                'parent': 2
            },
            {
                'id': 2,
                'name': 'View profile',
                'url': 'supplier',
                'parent': 2
            },{ 
                'id': 3,
                'name': 'change password',
                'url': 'change-password',
                'parent': 2
            }]
        },{
            'id': 3,
            'name': 'Services',
            'url': 'services',
            'icon': servicesIcon,
            'subItems': [{
                'id': 1,
                'name': 'services',
                'url': 'services',
                'parent': 3
            },{
                'id': 2,
                'name': 'locations',
                'url': 'locations',
                'parent': 3
            }]
        },          
        {
            'id': 4,
            'name': 'References',
            'url': 'supplier-reviews',
            'icon': reviewsIcon,
            'subItems': [
            {
                'id': 1,
                'name': 'request reviews',
                'url': 'supplier-reviews',
                'parent': 4
            },
            {
                'id': 2,
                'name': 'my reviews',
                'url': 'my-reviews',
                'parent': 4
            }]
        },
        {
            'id': 5,
            'name': 'Promote',
            'url': 'advertise',
            'icon': advertiseIcon,
            'subItems': [
            {
                'id': 1,
                'name': 'check availability',
                'url': 'advertise',
                'parent': 5
            },
            {
                'id': 2,
                'name': 'current adverts',
                'url': 'my-adverts',
                'parent': 5
            }]
        }]
    }

    componentDidMount() {
        const currentUrl = window.location.pathname.replace('/', '')
        this.state.pages.filter(eachBlock => {
            eachBlock.subItems.map(eachInternalBlock => {
                if (currentUrl === eachInternalBlock.url) {
                    this.setState({ sidebar: true, selectedIndex: eachInternalBlock.id, activeTab: eachBlock.id, selectedTab: eachBlock.id })
                }
            })
        })                
    }

    toggleSidebar = () => {
        const { sidebar } = this.state
        this.setState({ sidebar: !sidebar })
    }

    subLink = (subId, parentId) => this.setState({ selectedTab: parentId, selectedIndex: subId, activeTab: parentId })

    mainLink = (e, id) => { 
        const { sidebar } = this.state
        if (sidebar) {          
            this.setState({ activeTab: id, selectedIndex: 1 })
            e.preventDefault()
        } 
        else {
            this.setState({ 
                activeTab: id,
                selectedTab: id,                
            })
        }
    }

    render () {

        const { children, isLoggedIn } = this.props
        const { 
            pages,
            sidebar,             
            activeTab,
            allowedUrlsList
        } = this.state

        const accessToken = localStorage.getItem('accessToken')
        if (accessToken) {
            const accessTokenData = jwt_decode(accessToken)
            if (Date.now() >= accessTokenData.exp * 1000) {
                return <Redirect to={'/profile'} />
            }
        } else {
            return <Redirect to={'/gallery'} />
        }

        if (window.location.pathname.indexOf('/activate/') !== 0) {
            if (!allowedUrlsList.includes(window.location.pathname) && !isLoggedIn) return <Redirect to={'/gallery'} />
        }

        return (            
            <div className="profile__layout-container">                    
                <Sidebar 
                    pages={pages}
                    sidebar={sidebar}                    
                    subLink={this.subLink}                    
                    mainLink={this.mainLink}           
                    activeTab={activeTab}
                    toggleSidebar={this.toggleSidebar}
                />
                <div id="profilePageContainer" className="profile main-container-profile-edit">
                    <div className={`profile__main-container ${sidebar ? "additional__margin-left" : ""}`}>                        
                        <div className="profile__middle-content">
                            {children}
                        </div>
                    </div>
                </div>
            </div> 
        )
    }
}

const mapStateToProps = ({ session }) => ({      
    username: session.username,
    isLoggedIn: session.isLoggedIn,
    displayName: session.displayName
}) 

export default connect(mapStateToProps)(ProfileLayout)