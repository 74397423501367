import React from 'react'

import { checkTheStringForSpacesAndReplaceWithDashesThenConvertLower } from '../../utils/misc'

export const Submenu = ({ children, isGalleryPageOn, currentSelectedEventType, filterImagesForSelectedEventType }) => (
  <ul className="header__submenu-list">
    <li className="header__submenu-list-item">
      <span className={`header__submenu-list-link ${isGalleryPageOn && currentSelectedEventType == '' ? 'active' : ''}`} onClick={() => filterImagesForSelectedEventType('all')}>{'All'}</span>
    </li>
    {children.map(type => (
      <li className="header__submenu-list-item" key={type.id}>
        <span className={`header__submenu-list-link ${type.event_type_name == currentSelectedEventType ? 'active' : ''}`} onClick={() => filterImagesForSelectedEventType(checkTheStringForSpacesAndReplaceWithDashesThenConvertLower(type.event_type_name))}>
          {type.event_type_name}
        </span>
      </li>
    ))}
  </ul>
)