import React, { Component } from 'react'
import { connect } from "react-redux"
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { sessionEnd, checkLoginStatus, clearLocalStorage } from '../../actions/sessionActions'
import { retrieveAllEventTypes } from '../../actions/galleryActions'
import { retrieveAllSupplierContactInformation } from '../../actions/profileActions'

import { capitalizeFirstLetter } from '../../utils/misc'
  
import BlackTopBar from '../Global/BlackTopBar'
import LoginModal from './loginPopup/LoginModal'
import { Menu } from '../Global/Menu'
import logo from '../../assets/images/logo.png'
import logoScrolled from '../../assets/images/logo_transparent.png'
import hamburgerIcon from '../../assets/images/List.svg'

library.add(faBars)

class Header extends Component  {

    state = {                 
        menuOpen: false,
        notAllowedUrlsList: [
            '/home', '/login', '/signup', '/activate', 'gallery', '/reset-password'
        ],        
        searchTerm: '',
        scrolled: false,
        displayName: "",
        allEventTypes: [],
        searchOpen: false,        
        mainMenuOpen: false,
        isGalleryPageOn: false,
        currentSelectedEventType: "",
        userLoggedIn: false,
    }

    componentDidMount() {
        this.handleScroll = this.handleScroll.bind(this)
        window.addEventListener('scroll', this.handleScroll)

        this.props.checkLoginStatus().then(data => {  
            if (data) {
                this.props.retrieveAllSupplierContactInformation().then(contactInfo => {  
                    this.setState({ 
                        userLoggedIn: true, 
                        displayName: contactInfo !== null ? contactInfo.first_name : data.displayName,                         
                    })   
                }) 
            }   
        })       
        this.props.retrieveAllEventTypes().then(allEventTypes => this.setState({ allEventTypes }))
        const currentUrl = window.location.pathname
        if (currentUrl.indexOf('gallery') > -1) {
            this.setState({ isGalleryPageOn: true })
            const tempSplittedUrl = currentUrl.split('/')
            if (tempSplittedUrl[2]) this.setState({ currentSelectedEventType: capitalizeFirstLetter(tempSplittedUrl[2].replace('-', ' ')) }) 
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
    }

    handleScroll = () => {        
        if (window.pageYOffset > 140) {
            this.setState({ scrolled: true })
        } else {
            this.setState({ scrolled: false })
        }        
    }

    changeScrolledPosition = () => this.setState({ scrolled: !this.state.scrolled })
  
    userLogout = () => {
        this.props.logoutUser()
        this.props.clearLocalStorage()
    }

    toggleMenu = () => this.setState({ menuOpen: !this.state.menuOpen })
    
    toggleMainMenu = () => this.setState({ mainMenuOpen: !this.state.mainMenuOpen })

    toggleSearchBar = () => this.setState({ searchOpen: !this.state.searchOpen })

    closeSearchBar = () => this.setState({ searchOpen: false })

    setSearchTerm = value => this.setState({ ...this.state.searchTerm, searchTerm: value })

    filterImagesForSelectedEventType = evnetType => window.location.href = `/gallery/${evnetType}`

    render () {

        const { 
            username,
            isLoggedIn,
            displayName,
            activeCountry 
        } = this.props

        const {              
            menuOpen,
            scrolled,
            mainMenuOpen,
            allCountries,
            allEventTypes,            
            isGalleryPageOn,
            notAllowedUrlsList,
            currentSelectedEventType
        } = this.state 
        
        return (
            (!notAllowedUrlsList.includes(window.location.pathname) && (window.location.pathname.indexOf('/activate/') !== 0)) &&
            <header id="header" className={scrolled ? "scrolled" : ""}>
                <div className="header__black-top-bar">
                    <div className="header__scrolled-logo-container">
                        <button className="header__hamburger-button btn" onClick={this.toggleMainMenu}>
                            <img className="header__black-top-bar-hamburger" src={hamburgerIcon} alt="hamberger icon" />
                        </button>
                        <a href="/">
                            <img className="header__scrolled-logo" src={logoScrolled} alt="logo" />
                        </a>
                    </div>
                    <BlackTopBar
                        username={username}                        
                        isLoggedIn={isLoggedIn}                         
                        countries={allCountries}
                        displayName={displayName}                        
                        userLogout={this.userLogout}
                        activeCountry={activeCountry}                         
                        filteredCountry={this.filteredCountry} 
                    />  
                    <div className="header__menu-side">
                        {/* <button className="header__button btn" onClick={this.toggleSearchBar}>
                            <img className="header__button-image" src={magnifier} alt="magnifier" />
                        </button> */}
                    </div>
                </div>                
                <div className="header__logo-container">
                    <button className="header__menu-toggle" onClick={this.toggleMenu}>
                        <FontAwesomeIcon icon="bars" size="2x" />
                    </button>
                    <a href="/">
                        <img className="header__logo" src={logo} alt="logo" />
                    </a>
                </div>
                <nav className={mainMenuOpen ? "header__main-menu header__main-menu--open" : "header__main-menu"}>
                    <Menu                         
                        menu={menuOpen}                         
                        toggleMenu={this.toggleMenu} 
                        isGalleryPageOn={isGalleryPageOn}
                        allEventTypes={allEventTypes.sort()}
                        currentSelectedEventType={currentSelectedEventType}
                        filterImagesForSelectedEventType={this.filterImagesForSelectedEventType}
                    />
                    {/* {searchOpen && 
                        <SearchBar 
                            searchOpen={searchOpen} 
                            searchTerm={searchTerm}
                            setSearchTerm={this.setSearchTerm}                             
                            closeSearchBar={this.closeSearchBar} 
                        />} */}
                </nav> 
                <LoginModal />               
            </header>
        )
    }
}

const mapStateToProps = ({ session }) => ({      
    username: session.username,    
    isLoggedIn: session.isLoggedIn,
    displayName: session.displayName,
})

const mapDispatchToProps = dispatch => ({
    logoutUser: () => dispatch(sessionEnd()),
    checkLoginStatus: () => dispatch(checkLoginStatus()),
    clearLocalStorage: () => dispatch(clearLocalStorage()),
    retrieveAllEventTypes: () => dispatch(retrieveAllEventTypes()),
    retrieveAllSupplierContactInformation: () => dispatch(retrieveAllSupplierContactInformation()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)