import React, { Component } from 'react'
import Select from 'react-select'

export class CountryPopover extends Component {

    state = {}

    handleChange = selectedOption => {
        this.setState({ selectedOption })
    }

    render() {
        
        const { countries } = this.props
        
        return (
            <div className="popover popover--country">
                <div className="popover__arrow"></div>
                <div className="popover__content eb-forms eb-forms--country-popover">
                    <label>Country</label>
                    <div className="container">
                        <Select 
                            isClearable 
                            options={countries} 
                            onChange={this.handleChange}
                            className="country-searchable-dropdown"
                        />
                    </div>
                    <label>Language</label>
                    <select>
                        <option>English</option>
                    </select>
                    <label>Currency</label>
                    <select>
                        <option>Australian Dollar (AUD)</option>
                    </select>
                </div>
            </div>
        )
    }
}