import React, { Component } from 'react'
import backToTopIcon from '../../assets/images/icons/chevron.svg'

export class BackToTop extends Component {
    state = {
        show: false
    }

    componentDidMount() {
        this.handleScroll = this.handleScroll.bind(this)
        window.addEventListener('scroll', this.handleScroll)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
    }

    handleScroll = () => {        
        if (window.pageYOffset > 500) {
            this.setState({ show: true })
        } else {
            this.setState({ show: false })
        }        
    }

    backToTop = _ => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    render () {
        return (
            <button 
                className={this.state.show ? "back-to-top" : "back-to-top hide"}
                onClick={() => this.backToTop()}
                >
                    {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M367.954 213.588L160.67 5.872c-7.804-7.819-20.467-7.831-28.284-.029-7.819 7.802-7.832 20.465-.03 28.284l207.299 207.731c7.798 7.798 7.798 20.486-.015 28.299L132.356 477.873c-7.802 7.819-7.789 20.482.03 28.284A19.935 19.935 0 00146.513 512c5.125 0 10.25-1.958 14.157-5.873l207.269-207.701c23.394-23.394 23.394-61.459.015-84.838z"/>
                    </svg> */}
                    <img className="back-to-top__icon" src={backToTopIcon} alt="back to top icon" />
            </button>
        )
    }
}