import React, { Component } from 'react'
import ReactDOM from 'react-dom'

export default class Panel extends Component {

    state = {
        height: 100
    }

	componentDidMount() {
		window.setTimeout(() => {
			const el = ReactDOM.findDOMNode(this)
			const height = el.querySelector('.panel__inner').scrollHeight
			this.setState({ height })
		}, 333)
	}

	render () {

		const { 
			label, 
			index,
			serviceId,
			activeTab,
			isLoggedIn,
			activateTab, 
			subServicesInfo,
			otherSubServicesInfo,
			removeSelectedSubService,
			removeSelectedMainService,
			removeSelectedSubOtherService
		} = this.props

		const isActive = activeTab === parseInt(serviceId)
		const innerStyle = {
			height: `${isActive ? 'auto' : '0px'}`,
			paddingBottom: `${isActive ? 10 : 0}px`,
		}
		
		return (
            <div
                role='tabpanel' 
                className='panel'				
				aria-expanded={isActive} >
                <button 
                    role='tab'                    
                    className='panel__label'										
                    onClick={() => activateTab(index, serviceId)}
                >{label}</button>
				{isLoggedIn && <span className="close" onClick={() => removeSelectedMainService(serviceId)}>x</span>}
                <div
                    style={innerStyle}                    					
					aria-hidden={!isActive}
					className='panel__inner'
                >
					{(subServicesInfo && subServicesInfo.map) && subServicesInfo.map(eachSubService =>
						isLoggedIn && eachSubService.id ? (
							<div className={'sub__service-or-location-content-wrapper'}>
								<span className="close" onClick={() => removeSelectedSubService(serviceId, eachSubService.id)}>x</span>
								<p className='panel__content'>{eachSubService.label}</p>
							</div>
						) : <p className='panel__content'>{eachSubService.label}</p>
					)}
					{(otherSubServicesInfo && otherSubServicesInfo.map) && otherSubServicesInfo.map(eachOtherSubService =>
						isLoggedIn && eachOtherSubService.id ? (
							<div className={'sub__service-or-location-content-wrapper'}>
								<span className="close" onClick={() => removeSelectedSubOtherService(serviceId, eachOtherSubService.label)}>x</span>
								<p className='panel__content'>{eachOtherSubService.label}</p>
							</div>
						) : <p className='panel__content'>{eachOtherSubService.label}</p>
					)}
				</div>
			</div>
		)
	}
}