import _ from 'lodash'

export const promiseReject = value => new Promise((resolve, reject) => reject(value))

export const checkTheStringThenTruncate = (string, charLimit) => {
    const truncate = _.truncate
    return truncate(string, {
        length: charLimit,
        separator: /,?\.* +/
    })
}

export const sortBy = (key, reverse) => {
    const moveSmaller = reverse ? 1 : -1
    const moveLarger = reverse ? -1 : 1
    return (a, b) => {
        if (a[key] < b[key]) {
            return moveSmaller
        }
        if (a[key] > b[key]) {
            return moveLarger
        }
        return 0
    }
}

export const checkTheStringForSpacesAndReplaceWithDashesThenConvertLower = string => string.replace(/[.*+?^${}()|[\]\\\s/\//]/g, '-').toLowerCase()

export const capitalizeFirstLetter = str => {
    let pieces = str.split(" ")
    for ( let i = 0; i < pieces.length; i++ ) {
        let j = pieces[i].charAt(0).toUpperCase()
        pieces[i] = j + pieces[i].substr(1)
    }
    return pieces.join(" ")
}

export const formatDate = dateString => {
    const d = new Date(dateString)
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
    const mo = new Intl.DateTimeFormat('en', { month: 'long' }).format(d)
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)
    return `${da} ${mo} ${ye}`
}

export const replaceGenericNameSpacesWithDashes = string => string

export const formatPhoneNumber = phone => "(" + phone.substring(0,3)+") " + phone.substring(3,6) + "-" + phone.substring(6,11)

export const isValidURL = string => {
    const res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g)
    return (res !== null)
}

export const cleanTheWebSiteUrlAndReturnPlainURL = url => url.replace(/^http:\/\//, '')

export const formatUrl = url => {
    const httpString = "http://"
    const httpsString = "https://"
    if (url.substr(0, httpString.length).toLowerCase() !== httpString && url.substr(0, httpsString.length).toLowerCase() !== httpsString)
        url = httpString + url
    return url
}

export const isTouchDevice = () => {
    if ("ontouchstart" in window) {
        return true
    }
    return false
}

export const shuffle = array => {
    let currentIndex = array.length
    let temporaryValue = null
    let randomIndex = null
    while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex -= 1
        temporaryValue = array[currentIndex]
        array[currentIndex] = array[randomIndex]
        array[randomIndex] = temporaryValue
    }
    return array
}
