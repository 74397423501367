import BackendClient from './backendClient'

export const loadSubTypes = parentCatId => BackendClient.get(`supplier/list-sub-types?parentCategoryId=${parentCatId}`) 

export const loadSupplierParentTypes = () => BackendClient.get('supplier/list-parent-types')

export const loadSupplierTypesWithSubCategories = main_type => BackendClient.get(`supplier/list-types-all?main_type=${main_type}`)

export const retrieveSupplierBusinessInformation = accessToken => BackendClient.get(`supplier/supplier-business-information`, accessToken)

export const retrieveSupplierContactInformation = accessToken => BackendClient.get(`supplier/supplier-contact-information`, accessToken)

export const retrieveSupplierSocialInformation = accessToken => BackendClient.get(`supplier/supplier-social-information`, accessToken)

export const postSupplierBusinessInformation = (accessToken, postData) => BackendClient.post(`supplier/supplier-business-information`, postData, accessToken)

export const postSupplierContactInformation = (accessToken, postData) => BackendClient.post(`supplier/supplier-contact-information`, postData, accessToken)

export const postSupplierSocialInformation = (accessToken, postData) => BackendClient.post(`supplier/supplier-social-information`, postData, accessToken)

// export const supplierProfilePicUpload = (userId, accessToken, profilePic, oldFile, mediaUpload) => BackendClient.post(`supplier/supplier-profile-picture?userId=${userId}&oldFile=${oldFile}`, profilePic, accessToken, mediaUpload)

// export const supplierCoverImageUpload = (userId, accessToken, coverImage, oldCoverImage, mediaUpload) => BackendClient.post(`supplier/supplier-cover-image?userId=${userId}&oldCoverImage=${oldCoverImage}`, coverImage, accessToken, mediaUpload)

export const supplierProfileChangePassword = (postData, accessToken) => BackendClient.post(`supplier/supplier-change-password`, postData, accessToken)

export const supplierAddEvent = (postData, accessToken) => BackendClient.post(`supplier/supplier-add-event`, postData, accessToken)

export const supplierAddEventPhotos = (image, order, id, width, height, eventId, eventTypeId, currentTimeStamp, accessToken, mediaUpload) => BackendClient.post(`supplier/supplier-new-event-photos?&eventId=${eventId}&eventTypeId=${eventTypeId}&order=${order}&id=${id}&width=${width}&height=${height}&currentTimeStamp=${currentTimeStamp}`, image, accessToken, mediaUpload)

export const retrievalProfileSupplierImageFolders = accessToken => BackendClient.get(`supplier/supplier-fetch-uploaded-folders`, accessToken)

export const retrievalProfileSupplierImageFoldersPhotos = (eventId, accessToken) => BackendClient.get(`supplier/supplier-fetch-uploaded-photos?eventId=${eventId}`, accessToken)

export const postSupplierServiceInformation = (postData, serviceTypeId, accessToken) => BackendClient.post(`supplier/supplier-service-information?serviceTypeId=${serviceTypeId}`, postData, accessToken)

export const postSupplierLocationInformation = (postData, accessToken) => BackendClient.post(`supplier/supplier-location-information`, postData, accessToken)

export const loadGalleryImages = () => BackendClient.get('supplier/load-gallery-images')

export const fetchSupplierEventInformation = (eventId, accessToken) => BackendClient.get(`supplier/supplier-event-information?eventId=${eventId}`, accessToken)

export const fetchMatchingSupplierNames = (keyword, accessToken) => BackendClient.get(`supplier/list-matching-suppliers?keyword=${encodeURIComponent(keyword)}`, accessToken)

export const fetchSupplierProfileBasicInformation = accessToken => BackendClient.get(`supplier/supplier-profile-basic-information`, accessToken)

export const fetchSupplierEventPhotos = accessToken => BackendClient.get(`supplier/supplier-profile-all-events-photos`, accessToken)

export const fetchAllSuppliers = (accessToken, userId) => BackendClient.get(`supplier/list-all-suppliers?user_id=${userId}`, accessToken)

export const fetchAllSupplierEventKeywords = (userId, eventId, accessToken) => BackendClient.get(`supplier/supplier-fetch-keywords?userId=${userId}&eventId=${eventId}`, accessToken)

export const fetchAllSupplierEventAllKeywords = (userId, eventId, accessToken) => BackendClient.get(`supplier/supplier-fetch-all-other-keywords?userId=${userId}&eventId=${eventId}`, accessToken)

export const supplierUpdateEvent = (eventId, postData, accessToken) => BackendClient.post(`supplier/supplier-update-event?eventId=${eventId}`, postData, accessToken)

export const fetchAllConnectedSuppliers = (userId, eventId, accessToken) => BackendClient.get(`supplier/list-all-connected-suppliers?userId=${userId}&eventId=${eventId}`, accessToken)

export const supplierUpdateEventNewPhotos = (image, order, id, width, height, eventId, eventTypeId, accessToken, mediaUpload, folderId) => BackendClient.post(`supplier/supplier-event-update-new-photos?&eventId=${eventId}&eventTypeId=${eventTypeId}&folderId=${folderId}&order=${order}&id=${id}&width=${width}&height=${height}`, image, accessToken, mediaUpload)

export const fetchAllSupplierEventAllKeywordsNoException = accessToken => BackendClient.get(`supplier/supplier-fetch-all-keywords-no-excpetion`, accessToken)

export const supplierAddReview = (postData, accessToken) => BackendClient.post(`supplier/supplier-add-new-review`, postData, accessToken)

export const listAllSupplierReviews = accessToken => BackendClient.get(`supplier/list-all-supplier-reviews`, accessToken)

export const listAllMainServiceForCurrentProfile = accessToken => BackendClient.get(`supplier/supplier-main-service-information`, accessToken)

export const listAllSubServicesForCurrentProfile = (mainServiceId, accessToken) => BackendClient.get(`supplier/supplier-sub-services-information-per-main-service?mainServiceId=${mainServiceId}`, accessToken)

export const listAllOtherSubServicesForCurrentProfile = (mainServiceId, accessToken) => BackendClient.get(`supplier/supplier-other-sub-services-information-per-main-service?mainServiceId=${mainServiceId}`, accessToken)

export const loadRelatedSuppliersForFindProOrHire = (main, categoryName) => BackendClient.get(`supplier/list-related-suppliers?main=${main}&category_name=${categoryName}`)

export const loadAllRegisteredEventsIndexPhotos = eventTypeId => BackendClient.get(`supplier/list-all-registered-events-photos${eventTypeId ? '?event_type_id=' + eventTypeId : ''}`)

export const loadEventIdFromImageId = imageId => BackendClient.get(`supplier/fetch-supplier-event-information-event-id?imageId=${imageId}`)

export const loadEventRelatedKeywordsForPublic = eventId => BackendClient.get(`supplier/supplier-fetch-keywords-public?eventId=${eventId}`)

export const loadImagePages = eventTypeId => BackendClient.get(`supplier/list-all-registered-events-photos-page-count?${eventTypeId ? 'event_type_id=' + eventTypeId : ''}`)

export const loadPaginatedImages = (pageNum, eventTypeId) => BackendClient.get(`supplier/list-all-registered-events-photos?${pageNum ? 'current_page=' + pageNum : ''}${eventTypeId ? '&event_type_id=' + eventTypeId : ''}`)

export const loadAllEventTypes = () => BackendClient.get(`supplier/list-all-event-types`)

export const checkTheExistingEventTypeValidKeyword = keyword => BackendClient.get(`supplier/validate-keyword-as-eventype?keyword=${keyword}`)

export const submitCommentForEventApiCall = (eventId, postData, accessToken) => BackendClient.post(`supplier/add-event-specific-comment?eventId=${eventId}`, postData, accessToken)

export const addLikesForSupplierSpecificEvents = (eventId, postData, accessToken) => BackendClient.post(`supplier/add-event-specific-likes?eventId=${eventId}`, postData, accessToken)

export const fetchSupplierProfileBasicInformationForPublic = userEmail => BackendClient.get(`supplier/supplier-profile-basic-information-public?userEmail=${userEmail}`)

export const checkTheGivenCompanyNameIsValid = companyName => BackendClient.get(`supplier/check-the-given-company-name-is-valid/${companyName}`)

export const fetchSupplierEventPhotosForPublic = userEmail => BackendClient.get(`supplier/supplier-profile-all-events-photos-public?userEmail=${userEmail}`)

export const addSupplierPublicProfileReview = (userEmail, supplierUserEmail, postData) => BackendClient.post(`supplier/add-supplier-public-profile-review?supplierUserEmail=${supplierUserEmail}${userEmail ? '&userEmail=' + userEmail : ''}`, postData)

export const fetchAllReviewsForSelectedSupplierPublicProfile = userEmail => BackendClient.get(`supplier/supplier-public-profile-reviews-public?userEmail=${userEmail}`)

export const fetchAllReviewsForSupplierProfile = accessToken => BackendClient.get(`supplier/supplier-public-profile-reviews`, accessToken)

export const fetchSupplierProfileLocationInformationCountries = accessToken => BackendClient.get(`supplier/supplier-profile-location-information-main`, accessToken)

export const fetchSupplierProfileLocationInformationOthers = accessToken => BackendClient.get(`supplier/supplier-profile-location-information-others`, accessToken)

export const fetchSupplierPublicProfileServiceInformation = userEmail => BackendClient.get(`supplier/supplier-public-profile-service-information?userEmail=${userEmail}`)

export const fetchSupplierPublicProfileOtherServiceInformation = userEmail => BackendClient.get(`supplier/supplier-public-profile-service-information-others?userEmail=${userEmail}`)

export const fetchSupplierPublicProfileLocationMainInformation = userEmail => BackendClient.get(`supplier/supplier-public-profile-location-information-main?userEmail=${userEmail}`)

export const fetchSupplierPublicProfileOtherLocationInformation = userEmail => BackendClient.get(`supplier/supplier-public-profile-location-information-others?userEmail=${userEmail}`)

export const removeTheCurrentEvent = (eventId, currentTimeStamp, accessToken) => BackendClient.get(`supplier/supplier-event-remove-event?eventId=${eventId}&currentTimeStamp=${currentTimeStamp}`, accessToken)

export const checkAlreadyGivenALikeToThatEventDataFetch = (eventId, accessToken) => BackendClient.get(`supplier/supplier-event-check-previous-like-status?eventId=${eventId}`, accessToken)

export const loadSupplierFilledSubCategoriesIds = () => BackendClient.get('supplier/fetch-supplier-filled-sub-categories')

export const loadMainServicesInformationForPublicProfile = supplierEmail => BackendClient.get(`supplier/supplier-main-service-information-public?supplierEmail=${supplierEmail}`)

export const listAllSubServicesForCurrentPublicProfile = (mainServiceId, supplierEmail) => BackendClient.get(`supplier/supplier-sub-services-information-per-main-service-public-profile?mainServiceId=${mainServiceId}&supplierEmail=${supplierEmail}`)

export const listAllOtherSubServicesForCurrentPublicProfile = (mainServiceId, supplierEmail) => BackendClient.get(`supplier/supplier-other-sub-services-information-per-main-service-public-profile?mainServiceId=${mainServiceId}&supplierEmail=${supplierEmail}`)

export const updateTheOrderForTheExistingImagesProfile = (eventId, folderInsideImagesSet, accessToken) => BackendClient.post(`supplier/update-the-profile-selected-event-existing-photos-order?eventId=${eventId}`, folderInsideImagesSet, accessToken)

export const removeTheSelectedImage = (imageId, accessToken) => BackendClient.get(`supplier/removeTheSelectedImage?imageId=${imageId}`, accessToken)

export const removeSelectedSubService = (mainServiceId, subServiceId, accessToken) => BackendClient.get(`supplier/remove-supplier-sub-service?main_service_id=${mainServiceId}&sub_service_id=${subServiceId}`, accessToken)

export const removeSelectedOtherSubService = (mainServiceId, subService, accessToken) => BackendClient.get(`supplier/remove-supplier-other-sub-service?main_service_id=${mainServiceId}&sub_service=${subService}`, accessToken)

export const removeAddionalServiceAreasService = (serviceLocationCountry, serviceLocationCity, accessToken) => BackendClient.get(`supplier/remove-supplier-other-area?country=${serviceLocationCountry}&city=${serviceLocationCity}`, accessToken)

export const removeMainServiceAreasService = (serviceLocationCountry, serviceLocationCity, accessToken) => BackendClient.get(`supplier/remove-supplier-main-area?country=${serviceLocationCountry}&city=${serviceLocationCity}`, accessToken)

export const removeSelectedMainService = (mainServiceId, accessToken) => BackendClient.get(`supplier/remove-supplier-main-service?main_service_id${mainServiceId}`, accessToken)