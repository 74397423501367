import React from 'react'

import profile_image from '../../../assets/images/Person-Icon.png'
import './styles/supplier-header.css'

export const SupplierHeader = ({ supplier, reviews, loggedUserEmail, showWriteReview }) => (
    <header className="supplier-header">
        <div className="supplier-header__cover-image-container">
            {supplier.cover_image && <img src={supplier.cover_image} className="supplier-header__cover-image" alt="supplier cover image"/>}      
        </div>
        <div className="supplier-header__info-container">
            <div className="supplier-header__info">
                <div className="supplier-header__logo-container">
                    {supplier.profile_photo ? 
                        <img src={supplier.profile_photo && supplier.profile_photo} className="supplier-header__logo" alt="supplier logo image" />
                        : <img className="supplier-header__logo--dummy" src={profile_image && profile_image}  />}
                </div>
                <div className="supplier-header__details">
                    <h1 className="supplier-header__supplier-name">{supplier.company_name}</h1>
                    <div className="supplier-header__star-container">                        
                        <span className="supplier-header__current-reviews">{reviews ? reviews.length : 0} Review(s) </span>
                        {loggedUserEmail !== supplier.email_address && <a onClick={showWriteReview} className="supplier-header__review-link">Review me</a>}                        
                    </div>
                </div>
            </div>
            <div className="supplier-header__contact-container">
                <a target="_blank" href={`mailto:${supplier.email_address}`} className="btn supplier-header__contact supplier_profile-header-btn">Contact</a>
            </div>
        </div>
    </header>
)