import React, { Component } from 'react'
import { connect } from "react-redux"
import arrayMove from "array-move"
import { DndProvider } from "react-dnd"
import HTML5Backend from "react-dnd-html5-backend"
import TouchBackend from "react-dnd-touch-backend"
import cuid from "cuid"

import { retrieveAllEventTypes } from '../../actions/galleryActions'
import { checkLoginStatus } from '../../actions/sessionActions'
import { showNotification, hideNotification } from "../../actions/notificationActions"
import {
    processSupplierAddEvent,
    processSupplierPhotosUpload,
    supplierProfileEventInformationFormUpdate,
} from '../../actions/profileActions'

import ImageList from '../../reusables/ImageList/ImageList'
import Dropzone from '../../reusables/Dropzone/Dropzone'

import { isTouchDevice } from '../../utils/misc'

const backendForDND = isTouchDevice() ? TouchBackend : HTML5Backend

class Upload extends Component {

    constructor() {
        super()
        this.state = {
            eventTypeId: "",
            allSuppliers: [],
            imagesAll: false,
            otherCredits: "",
            eventLocation: "",
            allEventTypes: [],
            selectedImages: [],
            eventDescription: "",
            imagesProfile: true,
            connectedSuppliers: [],
            folderInsideImages: [],
            photographerCredits: "",
            selectedImagesPreviews: [],
            errorFields: {
                eventLocation: true,
                eventDescription: true
            },
            touched: {
                eventLocation: false,
                eventDescription: false
            },
            imagesAppearIn: "profile",
            projectBudgetTypes: [
                {
                    "key": "0-20",
                    "text": "under $20,000"
                },
                {
                    "key": "20-30",
                    "text": "$20,000-$30,000"
                },
                {
                    "key": "30-50",
                    "text": "$20,000-$50,000"
                },
                {
                    "key": "50-100",
                    "text": "over $50,000"
                }
            ],
            imagesCurrentCountUploaded: [],
            photosUploadingInProcess: false,
        }
    }

    componentDidMount() {
        this.props.checkLoginStatus().then(data => {
            if (data) {
                setTimeout(() => { this.setState({ overlay: false, selectedImages: [], photosUploadingInProcess: false, imagesCurrentCountUploaded: [] }) }, 100)
                this.props.retrieveAllEventTypes().then(allEventTypes => this.setState({ allEventTypes }))
            }
        })
    }

    componentWillUnmount() {
        this.setState({ selectedImages: [], photosUploadingInProcess: false, imagesCurrentCountUploaded: [] })
    }

    updateFormHandler = field => event => {
        if (field === 'eventDescription') {
            const eventDescription = event.target.value
            this.setState({ [field]: eventDescription.replace(/^\s+/, '') })
        } else {
            if ((event) && (event.target) && (event.target.value)) {
                this.setState({ [field]: event.target.value })
            }
        }
        this.props.supplierProfileEventInformationFormUpdate([field], event.target.value)
    }

    handleBlur = field => event => {
        this.setState({
            touched: {
                ...this.state.touched,
                [field]: true
            },
            errorFields: {
                ...this.state.errorFields,
                [field]: (!event.target.value) ? true : false
            },
        })
    }

    imagesAppearance = field => {
        if (field === 'all') {
            this.setState({ imagesAll: true, imagesProfile: true, imagesAppearIn: 'all' })
        } else {
            this.setState({ imagesAll: false, imagesProfile: true, imagesAppearIn: 'profile' })
        }
    }

    removeSelectedImages = index => {
        const { selectedImages } = this.state
        if (selectedImages && selectedImages.length > 0) {
            this.setState(prev => ({
                selectedImages: prev.selectedImages.filter(el => el.id !== index),
                selectedImagesPreviews: prev.selectedImagesPreviews.filter(el => el.id !== index),
            }))
        }
    }

    onDrop = acceptedFiles => {
        acceptedFiles.map((file, i) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = e => {
                const image = new Image
                image.src = reader.result
                image.onload = () => {
                    const imageObjectForSubmit = {
                        id: cuid(),
                        current_order: i + 1,
                        src: file,
                        width: image.width,
                        height: image.height,
                    }
                    const imageObjectPreview = {
                        id: cuid(),
                        current_order: i + 1,
                        src: reader.result
                    }
                    this.setState({
                        selectedImages: this.state.selectedImages.concat(imageObjectForSubmit),
                        selectedImagesPreviews: this.state.selectedImagesPreviews.concat(imageObjectPreview)
                    })
                }
            }
        })
    }

    moveImage = (dragIndex, hoverIndex) => {
        const { selectedImages, selectedImagesPreviews } = this.state
        const updatedImagesSet = arrayMove(selectedImages, hoverIndex, dragIndex)
        const updatedImagesSetPreview = arrayMove(selectedImagesPreviews, hoverIndex, dragIndex)
        const modifiedImageSet = updatedImagesSet.map((eachImage, i) => {
            eachImage.current_order = i + 1
            return eachImage
        })
        const modifiedImageSetPreview = updatedImagesSetPreview.map((eachImage, i) => {
            eachImage.current_order = i + 1
            return eachImage
        })
        this.setState({ selectedImages: modifiedImageSet, selectedImagesPreviews: modifiedImageSetPreview })
    }

    addEvent = () => {
        let postData = {}
        let notToProceedTheForm = false
        Object.values(this.state.errorFields).map(eachField => {
            if (eachField) notToProceedTheForm = true
        })
        if ((!notToProceedTheForm) || (this.state.selectedImages > 0)) {
            const {
                eventTypeId,
                otherCredits,
                eventLocation,
                imagesAppearIn,
                selectedImages,
                eventDescription
            } = this.state
            if (imagesAppearIn === 'all') {
                postData = {
                    eventTypeId: eventTypeId,
                    otherCredits: otherCredits,
                    eventLocation: eventLocation,
                    imagesAppearIn: imagesAppearIn,
                    eventDescription: eventDescription,
                    imageCount: (selectedImages && selectedImages.length !== 0) ? selectedImages.length : 0
                }
            } else {
                postData = {
                    otherCredits: otherCredits,
                    eventLocation: eventLocation,
                    imagesAppearIn: imagesAppearIn,
                    eventDescription: eventDescription,
                    imageCount: (selectedImages && selectedImages.length !== 0) ? selectedImages.length : 0
                }
            }
            this.props.processSupplierAddEvent(postData, selectedImages).then(data => {
                if (data.status === 'supplier_event_information_added') {
                    this.props.showNotification('Your event information have been added! Please wait while until we upload the photos.', 'success')
                    this.setState({
                        eventTypeId: "",
                        otherCredits: "",
                        eventLocation: "",
                        imagesAppearIn: "",
                        photographerCredits: "",
                        errorFields: {
                            eventLocation: false,
                            eventDescription: false
                        },
                        touched: {
                            eventLocation: false,
                            eventDescription: false
                        },
                        photosUploadingInProcess: true
                    })
                    setTimeout(() => this.props.hideNotification(), 6000)
                    if (selectedImages.length > 0) {
                        selectedImages.map((eachFile, i) => {
                            this.props.processSupplierPhotosUpload(eachFile.src, eachFile.current_order, eachFile.id, eachFile.width, eachFile.height, data.eventId, eventTypeId, data.currentTimeStamp).then(data => {
                                if (data.imageurl !== '') {
                                    this.setState({ imagesCurrentCountUploaded: this.state.imagesCurrentCountUploaded.concat(data.imageurl) })
                                }
                                if (selectedImages.length - 1 === i) {
                                    setTimeout(() => window.location.href = "/add-event", 3000)
                                }
                            })
                        })
                    }
                } else {
                    this.props.showNotification('Something went wrong! Please contact support...', 'error')
                    setTimeout(() => this.props.hideNotification(), 6000)
                }
            }).catch(() => {
                this.props.showNotification('Something went wrong! Please contact support...', 'error')
                setTimeout(() => this.props.hideNotification(), 6000)
            })
        }
    }

    render() {

        const { loading } = this.props

        const {
            touched,
            imagesAll,
            eventTypeId,
            errorFields,
            otherCredits,
            imagesProfile,
            allEventTypes,
            eventLocation,
            imagesAppearIn,
            selectedImages,
            eventDescription,
            selectedImagesPreviews,
            photosUploadingInProcess,
            imagesCurrentCountUploaded
        } = this.state

        let notToProceedTheForm = false

        if (imagesAppearIn === 'all') {
            if ((eventLocation === '') || (eventTypeId === null) || (imagesAppearIn === '')) {
                notToProceedTheForm = true
            }
        } else {
            if ((eventLocation === '') || (imagesAppearIn === '')) {
                notToProceedTheForm = true
            }
        }

        return (
            <>
                {(!photosUploadingInProcess) &&
                <div className={loading ? "overlay" : "overlay hide"}>
                    <div className="profile__loader-container">
                        <div className="loader"><span>Loading...</span></div>
                    </div>
                </div>}
                {(photosUploadingInProcess) &&
                <div className={(imagesCurrentCountUploaded.length !== selectedImages.length) ? "overlay" : "overlay hide"}>
                    <div className="profile__loader-container">
                        <div className="loader"><span>Please wait while we upload the photos...</span></div>
                    </div>
                </div>}
                <div className="profile__add-events">
                    <div className="profile__section">
                        <h5 className="profile__sub-title">Upload photos of an event</h5>
                        <div className="profile__event-container">
                            <div className="profile__first-event-container">
                                <span className="profile__section-content-tips">* Indicates required fields!</span>
                                <h6 className="profile__sub-title">An event is a collection of photos of your work. Events are one of the key ways event organisers can find you on Eventbuzz360</h6>
                            </div>
                        </div>
                        <div id="addEventSection" className="profile__section-content">
                            <div className="profile__form-container">
                                <div className="eb-forms eb-forms--profile">
                                    <div className="eb-forms__form-group">
                                        <div className="appear-in-container">
                                            <div className="appear-in-btn-container">
                                                <button
                                                    onClick={() => this.imagesAppearance('all')}
                                                    className={imagesAll ? "btn btn-appear-in active" : "btn btn-appear-in"}
                                                >Image Gallery</button>
                                                <button className={imagesProfile ? "btn btn-appear-in active" : "btn btn-appear-in"} onClick={() => this.imagesAppearance('profile')} value="profile">My profile page</button>
                                            </div>
                                            {imagesAll && <div className="appear-in-help">
                                                <p>Images in the gallery will automatically be uploaded to profile page and need to be approved by Eventbuzz360</p>
                                            </div>}
                                            <label className="fullwidth">Upload Event Images <span className="required__indicator">*</span></label>
                                        </div>
                                    </div>
                                    <div className="add__events-internal-wrapper">
                                        <div className="eb-forms__form-group eb-forms__form-group--photo-upload">
                                            <div className="dropzoneContainer">
                                                <Dropzone onDrop={this.onDrop} accept={"image/*"} />                                                
                                            </div>
                                            <textarea
                                                id="eventDescription"
                                                name="eventDescription"
                                                value={eventDescription}
                                                onBlur={this.handleBlur('eventDescription')}
                                                placeholder="Write a great event image description"
                                                onChange={this.updateFormHandler('eventDescription')}
                                                className={`${((touched.eventDescription && !eventDescription) || (touched.eventDescription && errorFields.eventDescription)) ? 'error__fields-indicator-profile' : ''}`}
                                            />
                                        </div>
                                        {selectedImagesPreviews && selectedImagesPreviews.length > 0 && (
                                            <h6 className="text-center">Drag the Images to change positions</h6>
                                        )}
                                        <DndProvider backend={backendForDND}>
                                            <ImageList images={selectedImagesPreviews} moveImage={this.moveImage} removeImage={this.removeSelectedImages} />
                                        </DndProvider>
                                        {imagesAppearIn === 'all' &&
                                            <div className="eb-forms__form-group leftMarginEmpty">
                                                <label htmlFor="event-year">Event Type <span className="required__indicator">*</span></label>
                                                <div className="eb-forms--not-fullwidth">
                                                    <select
                                                        id="eventTypeId"
                                                        value={eventTypeId}
                                                        className="leftMarginEmpty"
                                                        onBlur={this.handleBlur('eventTypeId')}
                                                        onChange={this.updateFormHandler('eventTypeId')}
                                                    >
                                                        <option value="ALL">-- Event Type --</option>
                                                        {allEventTypes.map(eventType => <option key={eventType.id} value={eventType.id}>{eventType.event_type_name}</option>)}
                                                    </select>
                                                </div>
                                            </div>
                                        }
                                        <div className="eb-forms__form-group">
                                            <label htmlFor="event-location">Event Location <span className="required__indicator">*</span></label>
                                            <div className="profile__input-container">
                                                <input
                                                    type="text"
                                                    id="event-location"
                                                    value={eventLocation}
                                                    name="event-location"
                                                    onBlur={this.handleBlur('eventLocation')}
                                                    onChange={this.updateFormHandler('eventLocation')}
                                                    className={`passwordMask ${((touched.eventLocation && !eventLocation) || (touched.eventLocation && errorFields.eventLocation)) && 'error__fields-indicator-profile'}`}
                                                />
                                            </div>
                                        </div>
                                        <div className="eb-forms__form-group">
                                            <label htmlFor="keywords">Other Supplier Credits</label>
                                            <textarea
                                                id="credits"
                                                name="credits"
                                                value={otherCredits}
                                                onChange={this.updateFormHandler('otherCredits')}
                                            >
                                            </textarea>
                                        </div>
                                        <div className="profile__button-container profile__button-container--single">
                                            <button className="btn btn-gold profile__button profile__button-next" disabled={notToProceedTheForm || selectedImages.length < 1} onClick={this.addEvent}>Upload Images</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="profile__tips-section"></div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = ({ profile, session }) => ({
    loading: profile.loading,
    isLoggedIn: session.isLoggedIn,
    formUpdated: profile.supplierEventInformation.formUpdated
})

const mapDispatchToProps = dispatch => ({
    checkLoginStatus: () => dispatch(checkLoginStatus()),
    hideNotification: () => dispatch(hideNotification()),
    retrieveAllEventTypes: () => dispatch(retrieveAllEventTypes()),
    showNotification: (message, notificationType) => dispatch(showNotification(message, notificationType)),
    processSupplierAddEvent: (postObject, imageSet) => dispatch(processSupplierAddEvent(postObject, imageSet)),
    supplierProfileEventInformationFormUpdate: (field, value) => dispatch(supplierProfileEventInformationFormUpdate(field, value)),
    processSupplierPhotosUpload: (image, order, id, width, height, eventId, eventName, eventTypeId, currentTimeStamp) => dispatch(processSupplierPhotosUpload(image, order, id, width, height, eventId, eventName, eventTypeId, currentTimeStamp)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Upload)  