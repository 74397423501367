import React, { Component } from 'react'
import { connect } from 'react-redux'

import { setUserType, changeStepOnUserType, resetPassword, setNewUserType, nextStep } from '../../../actions/newLoginActions'

import { LoginInitial } from './LoginInitial'
import SignUp from './SignUp'
import SignIn from './SignIn'
import ForgotPassword from './ForgotPassword'
import { UserType } from './UserType'

class LoginModalWizard extends Component {

    nextStep = () => this.props.nextStep()

    filteredUserType = userType => {
        const { setUserType, changeStepOnUserType } = this.props
        setUserType(userType)
        setTimeout(() => changeStepOnUserType(), 500)
    }

    newFilteredUserType = (userType, section) => this.props.setNewUserType(userType, section)

    resetPassword = () => this.props.resetPassword()

    render () {
        const { step, newUserTypes, supplier, jobs } = this.props
        switch(step) {
            case 1:
                return <LoginInitial filteredUserType={this.filteredUserType} />
            case 2:
                return <UserType
                            nextStep={this.nextStep}
                            userTypes={newUserTypes}
                            supplier={supplier}
                            jobs={jobs}
                            newFilteredUserType={this.newFilteredUserType}
                        />
            case 3:
                return <SignUp nextStep={this.nextStep} />            
            case 4:
                return <SignIn resetPassword={this.resetPassword} />
            case 5:
                return <ForgotPassword nextStep={this.nextStep} />
        }
    }  
}

const mapStateToProps = ({ newLogin }) => ({ 
    step: newLogin.step,
    jobs: newLogin.jobs,
    supplier: newLogin.supplier,
    newUserTypes: newLogin.newUserTypes,
}) 

const mapDispatchToProps = dispatch => ({
    nextStep: () => dispatch(nextStep()),
    resetPassword: () => dispatch(resetPassword()),
    setUserType: userType => dispatch(setUserType(userType)),
    changeStepOnUserType: () => dispatch(changeStepOnUserType()),
    setNewUserType: (userType, section) => dispatch(setNewUserType(userType, section)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginModalWizard)