import React, { Component } from 'react'
import Rodal from 'rodal'

import { AddStarRating } from '../../Global/AddStarRating'

import profile_image from '../../../assets/images/Person-Icon.png'

// import { changeTheFileUploadedPathToPublicUrl } from '../../../utils/misc'

import './styles/write-review.css'

export default class WriteReview extends Component {

    render () {

        const { 
            logo,
            rating,
            visible,
            onClose,
            acceptTerms,  
            reviwerEmail,          
            handleChange,
            onStarChange,
            submitReview,            
            reviewerFirstName,            
            reviewerLastName,
            reviewerProjectCost,
            reviewerDescription,
            reviewerRelationship,
            reviewerStreetAddress,
            acceptTermsAndConditions,     
            reviewerProjectCompletionMonth,
            reviewerProjectCompletionYear                                                            
        } = this.props 

        return (
            <Rodal className="rodal--write-review" visible={visible} onClose={onClose.bind(this)} animation={'slideDown'}>
                <header className="rodal__header">
                    <h4 className="rodal__heading">Write a Review</h4>
                </header>
                <div className="rodal__main-container">
                    <form className="eb-forms eb-forms--write-review">
                        <div className="write-review__header">
                            <div className="write-review__logo-container">
                            {logo ? <img src={logo} className="write-review__logo" alt="supplier logo image" /> : <img className="supplier-header__logo--dummy" src={profile_image}  />}   
                            </div>
                            <div className="write-review__rate">
                                <p className="profile__section-content-tips overrided__tips-section">* All fields are required!</p>                                
                                <AddStarRating stars={rating} onStarChange={onStarChange} />                            
                            </div>
                        </div>
                        <div className="write-review__main">
                            <div className="eb-forms__form-group">
                                <textarea 
                                    id="review-description" 
                                    name="review-description"                        
                                    defaultValue={reviewerDescription}            
                                    onChange={handleChange('reviewerDescription')}                                    
                                    placeholder="Write your review here... help others decide on the right professional. Minimum 30 words"
                                ></textarea>
                            </div>
                            <div className="eb-forms--review-not-fullwidth">
                                <div className="write-review__form-col">
                                    <div className="eb-forms__form-group">                            
                                        <label htmlFor="reviewerFirstName">First Name</label>
                                        <input 
                                            type="text" 
                                            id="reviewerFirstName"                                             
                                            name="reviewerFirstName"                                            
                                            placeholder="First Name" 
                                            defaultValue={reviewerFirstName} 
                                            onChange={handleChange('reviewerFirstName')}
                                        />
                                    </div>
                                </div>
                                <div className="write-review__form-col">
                                    <div className="eb-forms__form-group">  
                                        <label htmlFor="reviewerLastName">Last Name</label>    
                                        <input 
                                            type="text" 
                                            id="reviewerLastName"                                                 
                                            name="reviewerLastName"
                                            placeholder="Last Name"                                                 
                                            defaultValue={reviewerLastName} 
                                            onChange={handleChange('reviewerLastName')}
                                        />
                                    </div>
                                </div>
                            </div> 
                            <div className="eb-forms__form-group eb-forms__form-group--write-review">
                                <label htmlFor="event-year">Your relationship to the professional</label>
                                <select value={reviewerRelationship} onChange={handleChange('reviewerRelationship')}>
                                    <option value="ALL">-- PLEASE SELECT --</option>
                                    <option value="I hired this company">I hired this company</option>
                                    <option value="I am a professional who worked with this company">I am a professional who worked with this company</option>
                                    <option value="I received an estimate/consultation but did not hire them">I received an estimate/consultation but did not hire them</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                            <div className="eb-forms__form-group--write-review eb-forms--review-not-fullwidth">
                                <div className="write-review__form-col">
                                    <div className="eb-forms__form-group">                            
                                        <label htmlFor="completion-date">Approximate Project Completion Date</label>
                                        <select id="completion-date" value={reviewerProjectCompletionMonth} onChange={handleChange('reviewerProjectCompletionMonth')}>
                                            <option value="ALL">-- MONTH --</option>
                                            <option value="January">January</option>
                                            <option value="February">February</option>
                                            <option value="March">March</option>
                                            <option value="April">April</option>
                                            <option value="May">May</option>
                                            <option value="June">June</option>
                                            <option value="July">July</option>
                                            <option value="Augest">Augest</option>
                                            <option value="September">September</option>
                                            <option value="Octomber">Octomber</option>
                                            <option value="November">November</option>
                                            <option value="December">December</option>
                                        </select>
                                        <select id="completion-year" value={reviewerProjectCompletionYear} onChange={handleChange('reviewerProjectCompletionYear')}>
                                            <option value="ALL">-- YEAR --</option>
                                            <option value="2017">2017</option>
                                            <option value="2018">2018</option>
                                            <option value="2019">2019</option>
                                            <option value="2020">2020</option>
                                            <option value="2021">2021</option>
                                            <option value="2022">2022</option>
                                            <option value="2023">2023</option>
                                            <option value="2024">2024</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="write-review__form-col">
                                    <div className="eb-forms__form-group">  
                                        <label htmlFor="review-cost">Approximate Project or Purchase Cost</label>    
                                        <select id="review-cost" value={reviewerProjectCost} onChange={handleChange('reviewerProjectCost')}>
                                            <option value="ALL">-- CHOOSE ONE --</option>
                                            <option value="Less than $1,000">Less than $1,000</option>
                                            <option value="$1,000 - $9,999">$1,000 - $9,999</option>
                                            <option value="$10,000 - $49,999">$10,000 - $49,999</option>
                                            <option value="$50,000 - $100,000">$50,000 - $100,000</option>
                                            <option value="over $100,000">over $100,000</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="write-review__verification">
                                <p>Verification (not publicly shown)</p>
                                <div className="eb-forms__form-group eb-forms__form-group--write-review">
                                    <label htmlFor="reviwerEmail">Your Email</label>
                                    <input                                         
                                        type="text" 
                                        id="reviwerEmail"                                                                                                                         
                                        name="reviwerEmail"
                                        placeholder="Your Email" 
                                        defaultValue={reviwerEmail} 
                                        onChange={handleChange('reviwerEmail')}                                        
                                    />                  
                                </div>
                                <div className="eb-forms__form-group eb-forms__form-group--write-review">
                                    <label htmlFor="reviewerStreetAddress">Project Street Address</label>
                                    <input 
                                        type="text" 
                                        id="reviewerStreetAddress"                                         
                                        name="reviewerStreetAddress"
                                        defaultValue={reviewerStreetAddress} 
                                        onChange={handleChange('reviewerStreetAddress')}
                                        placeholder="Enter project street name, locally or full address (will not be shared)" 
                                    />                  
                                </div>
                            </div>    
                            <div className="eb-forms__form-group eb-forms__form-group--review-terms">
                                <div className="profile__supplier-subcategories eb-checkbox">
                                    <input 
                                        id='terms'
                                        type='checkbox'
                                        onChange={acceptTermsAndConditions} 
                                    />
                                    <span className="checkmark"></span>
                                    <label htmlFor='terms'>I confirm that the information here is true and accurate. I confirm that I do not work for, am not in competition with and am not related to this service provider</label>
                                </div>
                            </div>
                            <span className="signup__password-hint">By signing up, signing in or continuing, I agree to EventBuzz360's <a target="_blank" href="/terms-of-use">Terms of Use</a> and <a target="_blank" href="/privacy-policy">Privacy Policy</a></span>
                        </div> 
                        <div className="rodal__btn-container">
                            <input                                 
                                type="button" 
                                value="Done" 
                                onClick={submitReview} 
                                className="btn btn-gold image-crop-complete" 
                                disabled={!acceptTerms || !rating || !reviewerDescription || !reviewerFirstName || !reviewerLastName || !reviewerRelationship || !reviewerProjectCompletionMonth || !reviewerProjectCompletionYear || !reviewerProjectCost || !reviwerEmail || !reviewerStreetAddress} 
                            />
                        </div>
                    </form>
                    <div className="profile__tips-section write-review__tips-section">
                        <div className="write-review__tips-container">
                            <header className="write-review__tips-header">
                                <h5>Review Quality</h5>
                            </header>
                            <ul className="write-review__tips-list">
                                <li className="write-review__tips-list-item">20+ Words</li>
                                <li className="write-review__tips-list-item">Project date and cost</li>
                                <li className="write-review__tips-list-item">Project photos</li>
                            </ul>
                            <p className="write-review__tip-description">When writing a review, consider things like the Pro's professionalism, knowledge, work quality and the ability to meet deadlines and budget</p>
                        </div>
                    </div>
                </div>                
            </Rodal>
        )
    }
}

