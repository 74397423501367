import * as actions from "../actions/notificationActions"

const initialState = {
    message: '',
    notificationType: "",
    showNotification: false    
}

export const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SHOW_NOTIFICATION:
            return {
                ...state,
                showNotification: true,
                message: action.message,
                notificationType: action.notificationType
            }
        case actions.HIDE_NOTIFICATION:
            return {
                ...state,
                showNotification: false
            }
        default:
            return state
    }
}