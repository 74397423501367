import { combineReducers } from "redux"

import { registerReducer as register } from "./registerReducer"
import { profileReducer as profile } from "./profileReducer"
import { notificationReducer as notification } from "./notificationReducer"
import { sessionReducer as session } from "./sessionReducer"
import { galleryReducer as gallery } from "./galleryReducer"
import { generalReducer as general } from "./generalReducer"
import { newLoginReducer as newLogin } from "./newLoginReducer"

const rootReducer = combineReducers({
    profile,
    session,
    register,
    notification,
    gallery,
    general,
    newLogin
})

export default rootReducer