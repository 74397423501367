import React, { Component } from 'react'
import { connect } from "react-redux"
import { Redirect } from 'react-router'
import PasswordMask from 'react-password-mask'

import { checkLoginStatus } from '../../actions/sessionActions'
import { authLogin, loginFormUpdate } from '../../actions/sessionActions'
import { showNotification, hideNotification } from "../../actions/notificationActions"
import { retrieveAllSupplierBusinessInformation } from '../../actions/profileActions'

import img1 from '../../assets/images/frontpage.jpg'
import logo_transparent from '../../assets/images/logo.png'
import facebookIcon from '../../assets/images/icons/icon_facebook1.png'
import instagramIcon from '../../assets/images/icons/icon_instagram1.png'
import linkedInIcon from '../../assets/images/icons/icon_linkedin1.png'
import twitterIcon from '../../assets/images/icons/icon_twitter1.png'
import pinterestIcon from '../../assets/images/icons/icon_pinterest1.png'
import './styles/login.css'

class Login extends Component {

    state = {
        pass: "",
        email: "",
        image: img1,
        overlay: true,
        errorFields: {
            email: false,
            pass: false,
        },
        touched: {
            email: false,                        
            pass: false            
        },
        modalBoxVisbile: false,
        modalBoxVisbileError: false,
        social: {
            facebook: "www.facebook.com/EventBuzz360",
            linkedin: "www.linkedin.com/company/eventbuzz360-2",
            instagram: "www.instagram.com/eventbuzz360/",
            pinterest: "www.pinterest.com.au/eventbuzz360/",
            twitter: "twitter.com/EventBuzz360"
        },
        errorsInTheForm: false,
        loggedInRealStatus: false,             
        imageCredits: "Audio Visual Events",
        emailRule: new RegExp(/[^@]+@[^\.]+\..+/g)    
    }

    componentDidMount () {
        this.props.checkLoginStatus().then(data => {
            if (data) {
                this.setState({ loggedInRealStatus: true })
            }            
        })        
        setTimeout(_ => { this.setState({ overlay: false }) }, 100)   
    }

    handleBlur = field => _ => this.setState({ touched: { ...this.state.touched, [field]: true } })

    updateFormHandler = field => event => {                
        switch(field){
            case "username":
                this.setState({ email: event.target.value, errorsInTheForm: (!this.state.emailRule.test(event.target.value)) ? true : false })                
                break
            case "password":
                this.setState({ pass: event.target.value })  
                break   
        }
        this.props.updateForm(field, event.target.value)
    }    

    formSubmitHandler = e => {
        e.preventDefault()
        if ((this.state.pass) && (this.state.email)) {                        
            this.props.authLogin().then(data => {
                if (data.status === "user_credentials_ok") {                    
                    this.props.retrieveAllSupplierBusinessInformation().then(profileInfo => {  
                        if (profileInfo.company_name && profileInfo.profile_photo && profileInfo.cover_image && profileInfo.company_bio && profileInfo.country && profileInfo.website) {
                            this.props.history.push('add-event')
                        } else {
                            this.props.history.push('profile')
                        }
                    })
                } else if (data.status === "user_credentials_wrong") {
                    this.props.showNotification('Username or password incorrect!', 'error')
                    setTimeout(_ => this.props.hideNotification(), 5000)  
                } else if (data.status === "user_not_activated") {
                    this.props.showNotification('Please activate your account!', 'info')
                    setTimeout(_ => this.props.hideNotification(), 5000)  
                }
            })
        } else {
            if ((!this.state.email) || (!this.state.emailRule.test(this.state.email))) 
                this.setState({ touched: { ...this.state.touched, ['email']: true } })
            if (!this.state.pass) 
                this.setState({ touched: { ...this.state.touched, ['pass']: true } })
        }
    }    

    redirect = path => this.props.history.push(`/${path}`)

    render() {

        const { 
            pass,
            email,   
            social,
            touched,
            errorFields, 
            loggedInRealStatus
        } = this.state

        const {
            username, 
            password 
        } = this.props

        if (loggedInRealStatus) return <Redirect to={'/profile'} />

        return (
            <div id="landing-page" className="landing-page">
                <div className="landing-page__main">
                    <div className="landing-page__main-container">
                        <header className="header-coming-soon__header">
                            <a href="/"><img className="header-coming-soon__logo" src={logo_transparent} alt="logo" /></a>
                        </header>
                        <div className="landing-page__middle-container">
                            <div className="login__form-wrapper">
                                <div className="login__form-container">
                                    <form className="eb-forms" onSubmit={this.formSubmitHandler}>
                                        <div className="eb-forms__form-group eb-forms__form-group--login">
                                            <label htmlFor="emailAddress">Email</label>
                                        <input 
                                                type="text" 
                                                id="emailAddress" 
                                                placeholder="Enter email"
                                                onBlur={this.handleBlur('email')}
                                                onChange={this.updateFormHandler('username')} 
                                            />                                    
                                            {((touched.email && !email) || (errorFields.email)) && <span className="error">Enter a valid email address</span>}                        
                                        </div>
                                        <div className="eb-forms__form-group eb-forms__form-group--login">
                                            <label htmlFor="passwordLogin">Password</label>
                                            <PasswordMask
                                                name="password"
                                                value={password}                                        
                                                id="passwordLogin"
                                                className="passwordMask"
                                                placeholder="Enter password"    
                                                onBlur={this.handleBlur('pass')}                                    
                                                onChange={this.updateFormHandler('password')} 
                                            />
                                            <span className="signup__password-hint">Use 8 or more characters with a mix of letters, numbers and symbols</span>
                                            {(pass && errorFields.pass) && <span className="error">Enter a valid password</span>}                        
                                        </div>
                                        <button type="submit" className="btn btn-gold signup__button" disabled={(username === "" || password === "")}>Sign In</button>
                                    </form>     
                                </div>    
                                <footer className="login-form__footer">
                                    <p className="login-form__footer-copy">Don't have an account? <a onClick={() => this.redirect('home')}>Sign Up</a></p>
                                    <p className="login-form__footer-copy">Forget your password? <a onClick={() => this.redirect('reset-password')}>Reset password</a></p>
                                    <span className="signup__password-hint">By signing up, signing in or continuing, I agree to EventBuzz360's <a target="_blank" href="/terms-of-use">Terms of Use</a> and <a target="_blank" href="/privacy-policy">Privacy Policy</a></span>
                                </footer> 
                            </div>
                        </div>
                        <footer className="header-coming-soon__footer">
                            <fieldset className="fieldset">
                                <legend className="legend">follow us on social</legend>
                                <div className="landing-page__social-media-container">
                                    <ul className="landing-page__social-media">
                                        { 
                                            social.instagram && 
                                            (<li className="landing-page__social-media-list">
                                                <a className="landing-page__social-media-link" target="_blank" href={"http://" + social.instagram}>
                                                    <img src={instagramIcon} alt="instagram" />
                                                </a>
                                            </li>)
                                        }
                                        { 
                                            social.facebook &&
                                            (<li className="landing-page__social-media-list">
                                                <a className="landing-page__social-media-link" target="_blank" href={"http://" + social.facebook}>
                                                    <img src={facebookIcon} alt="facebook" />
                                                </a>
                                            </li>)
                                        }
                                        { 
                                            social.linkedin &&
                                            (<li className="landing-page__social-media-list">
                                                <a className="landing-page__social-media-link landing-page__social-media-link--small" target="_blank" href={"http://" + social.linkedin}>
                                                    <img src={linkedInIcon} alt="linkedIn" />
                                                </a>
                                            </li>)
                                        }
                                        { 
                                            social.pinterest &&
                                            (<li className="landing-page__social-media-list">
                                                <a className="landing-page__social-media-link landing-page__social-media-link--small" target="_blank" href={"http://" + social.pinterest}>
                                                    <img src={pinterestIcon} alt="pinterest" />
                                                </a>
                                            </li>)
                                        }  
                                        { 
                                            social.twitter &&
                                            (<li className="landing-page__social-media-list">
                                                <a className="landing-page__social-media-link" target="_blank" href={"http://" + social.twitter}>
                                                    <img src={twitterIcon} alt="twitter" />
                                                </a>
                                            </li>)
                                        }
                                    </ul>
                                </div>
                            </fieldset>
                        </footer>
                    </div>
                </div>                    
            </div>            
        )
    }
}

const mapStateToProps = ({ session }) => ({    
    username: session.username,
    password: session.password    
})
  
const mapDispatchToProps = dispatch => ({
    authLogin: _ => dispatch(authLogin()),
    checkLoginStatus: _ => dispatch(checkLoginStatus()),
    hideNotification: _ => dispatch(hideNotification()),    
    updateForm: (field, value) => dispatch(loginFormUpdate({ field, value })),            
    showNotification: (message, notificationType) => dispatch(showNotification(message, notificationType)),
    retrieveAllSupplierBusinessInformation: userId => dispatch(retrieveAllSupplierBusinessInformation(userId)),
})
  
export default connect(mapStateToProps, mapDispatchToProps)(Login)