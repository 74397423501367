import React, { Component } from 'react'

import './styles/static.css'

export default class TermsAndUse extends Component  {

    render () {
        return (
            <section className="static-page">
                <div className="static-page__static-container">
                    <header className="static-page__header">
                        <h3 className="static-page__title">EVENTBUZZ360 - TERMS OF USE </h3>
                    </header>
                    <div className="static-page__content">
                        <p>EventBuzz360 is an online platform that takes event professionals on a journey inside the world’s most spectacular corporate and social events, and connects event organisers with suppliers of the finest event goods and services. Our vision is to be the foremost events industry website showcasing excellence in event design and production through outstanding photography and videography, and the leading on-line marketplace for event professionals.</p>                        
                        <p>Our Terms of Use (the <strong>"Terms"</strong> or the <strong>"Agreement"</strong>) set forth the binding legal agreement between you and EventBuzz360 Pty Ltd. The Agreement governs your use of www.eventbuzz360.com.au and all of the related websites, mobile apps, products and services offered by EventBuzz360, including our plug-ins and embedded content made available on other sites (collectively, the <strong>"EventBuzz360 Platform"</strong>). </p>
                        <p><strong>These Terms provide important information to you including your agreement to grant rights to your content, our limitation of liability to you, your agreement to resolve any disputes by individual arbitration and to waive the right to participate in a class action (described in Section 8 below) and, if you choose to provide us with your phone number, your agreement to receive calls and text messages from us in accordance with applicable law.</strong></p>
                        <p><strong>By agreeing to these Terms, you agree to resolve all disputes through binding individual arbitration, which means that you waive any right to have those disputes decided by a judge or jury, and that you waive your right to participate in class actions, class arbitrations, or any type of representative actions.</strong></p>
                        <p>We encourage you to review this Agreement carefully. Any use of the EventBuzz360 Platform including browsing www.eventbuzz360.com.au, shall be governed by the Agreement. If you do not agree to any of the Terms, you should stop using the EventBuzz360 Platform.</p>
                        <ol type="1">
                            <li><h5>Using the EventBuzz360 Platform.</h5>
                                <ol type="a">
                                    <li>
                                        <p><strong>Who can use it?:</strong> You must be at least the age of the majority in the state where you live to use the EventBuzz360 Platform. Use of the EventBuzz360 Platform by anyone under 13 years of age is strictly prohibited!</p>
                                    </li>
                                    <li>
                                        <p><strong>Who can use it?:</strong> You must be at least the age of the majority in the state where you live to use the EventBuzz360 Platform. Use of the EventBuzz360 Platform by anyone under 13 years of age is strictly prohibited!</p>
                                    </li>
                                    <li>
                                        <p><strong>Privacy Policy:</strong> Our privacy practices are set forth in our Privacy Policy. By using the EventBuzz360 Platform in any way, you agree to the terms of the Privacy Policy, regardless of whether you are a registered user.</p>
                                    </li> 
                                    <li>
                                        <p><strong>Profile for Professionals:</strong> If you are a professional services provider, you may set up a Professional Profile. If you do, you give us the right to list your Professional Profile in our directory of service providers, and you will be able to connect with others on the EventBuzz360 Platform while identified by your professional profile. Your professional profile is subject to our Professional Profile Policy.</p>
                                    </li> 
                                    <li>
                                        <p><strong>Acceptable Use Policy:</strong> When using the EventBuzz360 Platform, you agree to abide by common standards of etiquette and act in accordance with the law as described further in our Acceptable Use Policy.</p>
                                    </li> 
                                    <li>
                                        <p><strong>Prohibited Products Policy:</strong> If you are a professional, a vendor, or a seller, who is authorised to offer products through the EventBuzz360 Platform, you agree to abide by the <a className="hight__lighted-text-underlined" target="_blank" href="/acceptable-use-policy">Prohibited Products Policy.</a></p>
                                    </li> 
                                    <li>
                                        <p><strong>Termination:</strong> You may close your account at any time by going to account settings and disabling your account. We may permanently or temporarily suspend your use of the site or the service at any time for any reason, without any notice or liability to you. We may terminate your account at any time for any or no reason including if you violate any EventBuzz360 policy (including the <a className="hight__lighted-text-underlined" target="_blank" href="/acceptable-use-policy">Acceptable Use Policy or Prohibited Products Policy</a>). Upon termination of your use of the service, certain provisions will survive termination, as detailed in Section 10(m).</p>
                                    </li> 
                                    <li>
                                        <p><strong>Feedback:</strong> We welcome your feedback and suggestions about how to improve the EventBuzz360 Platform. Feel free to submit feedback at <a className="hight__lighted-text-underlined" target="_blank" href="mailto:hello@eventbuzz360.com">hello@eventbuzz360.com.</a> By submitting feedback in this or in any other manner to us you agree to grant us the right, at our discretion, to use, disclose and otherwise exploit the feedback, in whole or part, without any restriction or compensation to you.</p>
                                    </li> 
                                </ol>
                            </li>
                            <li><h5>Your Content.</h5>
                                <ol type="a">
                                    <li>
                                        <p><strong>Definition of Your Content:</strong> The EventBuzz360 Platform enables you to post materials including without limitation photos, profile pictures, messages, and commentary. You may also post reviews of third-party service providers, third-party products, or third-party services <strong>("Reviews").</strong> All materials that you post on the EventBuzz360 Platform will be referred to collectively as <strong>"Your Content."</strong> You acknowledge and agree that, as part of using the EventBuzz360 Platform, Your Content may be viewed by the general public and will not be treated as private or confidential.</p>
                                    </li> 
                                    <li>
                                        <p><strong>License and Permission to Use Your Content:</strong> You hereby grant to us and our affiliates, licensees and sublicensees, without compensation to you or others, a non-exclusive, perpetual, irrevocable, royalty-free, fully paid-up, worldwide license (including the right to sublicense through multiple tiers) to use, reproduce, process, adapt, publicly perform, publicly display, modify, prepare derivative works, publish, transmit and distribute Your Content, or any portion thereof, throughout the world in any format, media or distribution method (whether now known or hereafter created) for the duration of any copyright or other rights in Your Content. Such permission will be perpetual and may not be revoked for any reason, to the maximum extent permitted by law. Further, to the extent permitted under applicable law, you waive and release and covenant not to assert any moral rights that you may have in Your Content. If you identify yourself by name or provide a picture or audio or video recording of yourself, you further authorise us and our affiliates, licensees and sublicensees, without compensation to you or others, to reproduce, print, publish and disseminate in any format or media (whether now known or hereafter created) your name, voice and likeness throughout the world, and such permission will be perpetual and cannot be revoked for any reason, except as required by the applicable law. You further agree that we may use Your Content in any manner that we deem appropriate or necessary.</p>
                                    </li> 
                                    <li>
                                        <p><strong>Ownership:</strong> We acknowledge and agree that you, or your licensors, as applicable, retain ownership of any and all copyrights in Your Content, subject to the non-exclusive rights granted to us in the paragraph above, and that no ownership of such copyrights is transferred to us under this agreement. Further, with respect to Your Content in the form of photos, and subject to EventBuzz360 product and user experience considerations, we will:</p>
                                        <ol type="a">
                                            <li>
                                                <p> use commercially reasonable efforts to maintain the attribution of such photos as submitted by you; and</p>
                                            </li>
                                            <li>
                                                <p> not license or sublicense to third parties individual photos or collections of photos, except in each case for EventBuzz360 Business Purposes. <strong>"EventBuzz360 Business Purposes"</strong> means any use in connection with an EventBuzz360-branded or co-branded website, application, publication or service, or any use which advertises, markets or promotes the EventBuzz360 Platform, the services or the information it contains, EventBuzz360, or its affiliates. EventBuzz360 Business Purpose specifically includes the use of Your Content on the EventBuzz360 Platform in connection with features and functions offered by EventBuzz360 to our users that enable them to view and interact with Your Content (such as Sketch, View in My Room and the embed tool).</p>
                                            </li>
                                        </ol>                                        
                                    </li> 
                                    <li>
                                        <p><strong>Your Responsibilities for Your Content:</strong> By posting Your Content on the EventBuzz360 Platform, you represent and warrant to us that you have the ownership rights, or you have obtained all needed licenses or permissions from any necessary parties, to use Your Content in this manner. This includes obtaining the right to grant us the rights to the use of Your Content in accordance with this agreement. You are in the best position to judge whether Your Content is in violation of intellectual property or personal rights of any third-party. <sreong>You accept full responsibility for avoiding infringement of the intellectual property or personal rights of others in connection with Your Content.</sreong> You are responsible for ensuring that your content does not violate EventBuzz360's <a className="hight__lighted-text-underlined" target="_blank" href="/acceptable-use-policy">Acceptable Use Policy, Prohibited Products Policy</a> or <a className="hight__lighted-text-underlined" target="_blank" href="/copy-right-trademark">Copyright and Trademark Policy</a> or any applicable law or regulation. You agree to pay all royalties, fees and any other monies owed to any person by reason of Your Content.</p>
                                    </li> 
                                    <li>
                                        <p><strong>Limits:</strong> We reserve the right to edit, abridge, or remove Your Content, in whole or part, for any reason (which may include a reported violation of our Acceptable Use Policy or Prohibited Products Policy or Copyright and Trademark Policy). We reserve the right to edit, refuse to post or remove Your Content submitted by you for any reason without notice. We do not guarantee that we will publish all of Your Content. If you seek to publish a Review, the requirements will be governed by our <a className="hight__lighted-text-underlined" target="_blank" href="/review-policy">Review Policy</a>.</p>
                                    </li> 
                                </ol>
                            </li>
                            <li><h5>Our Content and Materials.</h5>
                                <ol type="a">
                                    <li>
                                        <p><strong>Definition of Our Content and Materials:</strong> All intellectual property in or related to the EventBuzz360 Platform (specifically including, but not limited to, our software, the EventBuzz360 marks, the EventBuzz360 logo, and EventBuzz360 buttons, badges, and widgets, but excluding Your Content), is the property of EventBuzz360 Pty Ltd, its subsidiaries and affiliates or its licensors <strong>("Our Content and Materials")</strong>.</p>
                                    </li> 
                                    <li>
                                        <p><strong>Our License to You:</strong> Subject to these terms of use, including the restrictions below, we grant you a limited, non-exclusive license to use and access Our Content and Materials in connection with your use of the EventBuzz360 Platform. Except as expressly agreed to otherwise by us (such as your eligibility for creation of a Professional Profile or your entering into a vendor, seller, advertiser, Site Designer, or other agreement with us), your use of the EventBuzz360 Platform must be limited to personal, non-commercial use. We may terminate this license at any time for any reason. Except for the rights and license granted in these terms, we reserve all other rights and grant no other rights or licenses, implied or otherwise.</p>
                                    </li> 
                                    <li>
                                        <p><strong>No Endorsement or Verification:</strong> Please note that the EventBuzz360 Platform enables access to third-party content, products and services, and it offers interactions with third-parties over which we have no control. We assume no responsibility for, nor do we endorse or verify the content, offerings or conduct of third-parties (including but not limited to the products or services offered by third-parties or the descriptions of the products or services offered by third-parties). For example, EventBuzz360 does not conduct background checks or otherwise vet the professionals listed on the EventBuzz360 Platform. Participation or availability on the EventBuzz360 Platform does not amount to endorsement or verification by us. We make no warranties or representations with respect to the accuracy, completeness or timeliness of any content posted on the EventBuzz360 Platform by anyone.</p>
                                    </li> 
                                    <li>
                                        <p><strong>Restrictions:</strong> Except as expressly provided in these Terms, you agree not to use, modify, reproduce, distribute, sell, license, reverse engineer, decompile, or otherwise exploit Our Content and Materials without our express written permission. EventBuzz360's permission to you for your use of the EventBuzz360 Platform expressly excludes commercial use by you of any information concerning product descriptions or professional listings for the benefit of another merchant. You expressly are prohibited from any use of data mining, robots, or similar data gathering and extraction tools in your use of the EventBuzz360 Platform. You may view and print a reasonable number of copies of web pages located on the EventBuzz360 Platform for your own personal use, provided that you retain all proprietary notices contained in the original materials including attribution to EventBuzz360.</p>
                                    </li> 
                                    <li>
                                        <p><strong>Ownership:</strong> You acknowledge and agree that the EventBuzz360 Platform and EventBuzz360 marks will remain the property of EventBuzz360. The content, information and services made available on the EventBuzz360 Platform are protected by Australia and international copyright, trademark, and other laws, and you acknowledge that these rights are valid and enforceable. You acknowledge that you do not acquire any ownership rights by using the EventBuzz360 Platform.</p>
                                    </li> 
                                    <li>
                                        <p><strong>EventBuzz360 Buttons, Links and Widgets:</strong> You have permission to use the EventBuzz360 buttons, links and widgets including those made available at <a className="hight__lighted-text-underlined" target="_blank" href="/buttons-and-badges">Buttons and Badges</a>, subject to these Terms of Use (including the disclaimers and limitations of liability) and the further understanding that:</p>
                                        <ol type="a">
                                            <li>
                                                <p>your use of such buttons, links and widgets link only to the EventBuzz360 Platform;</p>
                                            </li> 
                                            <li>
                                                <p>you will not modify such buttons, links, widgets or associated code in any manner;</p>
                                            </li> 
                                            <li>
                                                <p>you will not use any such buttons, links, widgets in any manner which implies or suggests that we endorse, sponsor, or recommend the website on which such buttons, links and widgets are used; and</p>
                                            </li>
                                            <li>
                                                <p>the use of such buttons, links and widgets, and the website on which such buttons, links and widgets are used do not violate the EventBuzz360's <a className="hight__lighted-text-underlined" target="_blank" href="/acceptable-use-policy">Acceptable Use Policy</a> or <a className="hight__lighted-text-underlined" target="_blank" href="/acceptable-use-policy">Prohibited Products Policy</a>.</p>
                                            </li>
                                        </ol>
                                    </li> 

                                </ol>
                            </li>
                            <li><h5>Other Offerings on the EventBuzz360 Platform.</h5>
                                <ol type="a">
                                    <li>
                                        <p><strong>Purchase of Goods through the EventBuzz360 Shop.</strong> <a className="hight__lighted-text-underlined" target="_blank" href="">EventBuzz360 Shop</a> and third-party sellers offer home goods, furniture, and other products for sale on the EventBuzz360 Platform. If you purchase products on the EventBuzz360 Platform, your purchase is subject to the <a className="hight__lighted-text-underlined" target="_blank" href="">EventBuzz360 Shop Terms of Sale</a>. The availability of products sold by third-parties on the EventBuzz360 Platform does not imply our endorsement or verification of the products or their descriptions.</p>
                                    </li> 
                                    <li>
                                        <p><strong>Site Designer.</strong> The EventBuzz360 Platform allows you to use Site Designer such as video, tutorials, reviews, subject to the Site Designer Terms of Use.</p>
                                    </li> 
                                    <li>
                                        <p><strong>Embed Tool.</strong> If you are a user of the embed tool, which enables you to link to content on the EventBuzz360 Platform, you understand and agree that we cannot guarantee that the content, which originates from third-parties, is non-infringing or will be free from claims about infringement. Such third-party content may be subject to takedown by us at any time, in accordance with our policies, if we receive a notice of infringement.</p>
                                    </li> 
                                    <li>
                                        <p><strong>Third-Party Services.</strong> You may be provided the opportunity on the EventBuzz360 Platform to purchase services that are offered by third parties (collectively <strong>"Third Party Services"</strong>), including those offered by professionals registered with Professional Profiles on the EventBuzz360 Platform. The availability of any Third Party Services on the EventBuzz360 Platform does not imply our endorsement of the Third Party Services.</p>
                                    </li> 
                                    <li>
                                        <p><strong>Third-Party Sites.</strong> The EventBuzz360 Platform may contain links to other websites (the <strong>"Third-Party Sites"</strong>) for your convenience. We do not control the linked websites or the content provided through such Third-Party Sites. Your use of Third-Party Sites is subject to the privacy practices and terms of use established by the specific linked Third-Party Site, and we disclaim all liability for such use. The availability of such links does not indicate any approval or endorsement by us.</p>
                                    </li> 
                                </ol>
                            </li>
                            <li><h5>Reporting Violations of Your Intellectual Property Rights, EventBuzz360 Policies or Applicable Laws</h5>
                                <p>We have a special process for reporting violations of your intellectual property rights or other violations of EventBuzz360 policies or applicable laws.</p>
                                <ol type="a">
                                    <li>
                                        <p><strong>Copyright and Trademark Policy:</strong> We have adopted and implemented a Copyright and Trademark Policy. For more information including detailed information about how to submit a request for takedown if you believe content on the EventBuzz360 Platform infringes your intellectual property rights, please read our Copyright and Trademark Policy.</p>
                                    </li> 
                                </ol>
                                <p>We have no obligation to delete content that you personally may find objectionable or offensive. We endeavour to respond promptly to requests for content removal, consistent with our policies described above and applicable law.</p>
                            </li>
                            <li><h5>Disclaimers and Limitations of Liability.</h5>
                                <p><strong>Please read this section carefully since it limits the liability of eventbuzz 360 entities to you.</strong></p>
                                <p>The "Eventbuzz 360 entities" means EventBuzz360 Pty Ltd and any subsidiaries, affiliates, related companies, suppliers, licensors and partners, and the officers, directors, employees, agents and representatives of each of them. Each provision below applies to the maximum extent permitted under applicable law:</p>
                                <ol type="a">
                                    <li>
                                        <p>We are providing you the EventBuzz 360 Platform, services, information, products, product descriptions, and third-party content on an "as is" and "as available" basis, without warranty of any kind, express or implied. Without limiting the foregoing, the EventBuzz360 entities expressly disclaim any and all warranties and conditions of merchantability, title, accuracy and completeness, uninterrupted or error-free service, fitness for a particular purpose, quiet enjoyment, and non-infringement, and any warranties arising out of course of dealing or trade usage.</p>
                                    </li> 
                                    <li>
                                        <p>The EventBuzz360 entities make no promises with respect to, and expressly disclaim all liability, to the maximum extent permitted by law, for: (i) content posted by any third-party on the EventBuzz360 platform, (ii) the product descriptions or products, (iii) third-party sites and any third-party product or service listed on or accessible to you through the site, (iv) plants or seeds from the exchanges, and (v) the quality or conduct of any third-party you encounter in connection with your use of the site.</p>
                                    </li> 
                                    <li>
                                        <p>You agree that under the maximum extent permitted by law, the EventBuzz360 entities will not be liable to you under any theory of liability. Without limiting the foregoing, you agree that the EventBuzz360 entities specifically will not be liable for any indirect, incidental, consequential, special, or exemplary damages, loss of profits, business interruption, reputational harm, or loss of data (even if the EventBuzz360 entities have been advised of the possibility of such damages or such damages are foreseeable) arising out of and in any way connected with your use of, or inability to use, the EventBuzz360 platform or products. Your use of the EventBuzz360 platform, products, information, or services is at your sole risk.</p>
                                    </li> 
                                </ol>
                            </li>
                            <li><h5>Indemnification.</h5>
                                <p>You agree to fully indemnify, defend, and hold the EventBuzz360 entities and their directors, officers, employees, consultants, and other representatives, harmless from and against any and all claims, damages, losses, costs (including reasonable attorneys' fees), and other expenses that arise directly or indirectly out of or from:</p>
                                <ol type="a">
                                    <li>
                                        <p>your breach of any part of this agreement, including but not limited to the Acceptable Use Policy and Prohibited Products Policy;</p>
                                    </li> 
                                    <li>
                                        <p>any allegation that any materials you submit to us or transmit to the EventBuzz360 Platform infringe or otherwise violate the copyright, patent, trademark, trade secret, or other intellectual property or other rights of any third party;</p>
                                    </li> 
                                    <li>
                                        <p>your activities in connection with the EventBuzz360 Platform or other websites to which the EventBuzz360 Platform is linked; and/or (d) your negligent or wilful misconduct.</p>
                                    </li> 
                                </ol>
                            </li>
                            <li><h5>Dispute Resolution.</h5>
                                <p>If you have a dispute with EventBuzz360, you agree to contact us through <a className="hight__lighted-text-underlined" target="_blank" href="mailto:hello@eventbuzz360.com">hello@eventbuzz360.com.</a> to attempt to resolve the issue informally first. If we are not able to resolve the dispute informally, then this section will govern any legal dispute that relates to the EventBuzz360 Platform or involves our services.</p>
                                <ol type="a">
                                    <li>
                                        <p><strong>Binding Arbitration:</strong> You and EventBuzz360 agree that any dispute, claim or controversy arising out of or relating to this Agreement or to your use of the EventBuzz360 Platform (collectively <strong>"Disputes"</strong>) will be settled by binding arbitration, except that each party retains the right to seek injunctive or other equitable relief in a court of competent jurisdiction to prevent the actual or threatened infringement, misappropriation, or violation of a party's copyrights, trademarks, trade secrets, patents, or other intellectual property rights. <strong>This means that you and EventBuzz360 both agree to waive the right to a trial by jury.</strong> Notwithstanding the foregoing, you may bring a claim against EventBuzz360 in "small claims" court, instead of by arbitration, but only if the claim is eligible under the rules of the small claims court and is brought in an individual, non-class, and non-representative basis, and only for so long as it remains in the small claims court and in an individual, non-class, and non-representative basis.</p>
                                    </li> 
                                    <li>
                                        <p><strong>Class Action Waiver:</strong> You and EventBuzz360 agree that any proceedings to resolve Disputes will be conducted on an individual basis and not in a class, consolidated, or representative action. <strong>This means that you and EventBuzz360 both agree to waive the right to participate as a plaintiff as a class member in any class action proceeding. Further, unless you and EventBuzz360 agree otherwise in writing, the arbitrator in any Dispute may not consolidate more than one person's claims and may not preside over any form of class action proceeding.</strong></p>
                                    </li> 
                                    <li>
                                        <p><strong>Arbitration Administration and Rules:</strong> The arbitration will be administered by <strong>The Australian Centre for International Commercial Arbitration (ACICA)</strong> is Australia’s international dispute resolution institution. <a className="hight__lighted-text-underlined" target="_blank" href="mailto:hello@eventbuzz360.com">https://acica.org.au</a> in accordance with the Commercial Arbitration Rules and the Supplementary Procedures for Consumer Related Disputes (the <strong>"ACICA Rules"</strong>) then in effect, except as modified by this "Dispute Resolution' section. (The ACICA Rules are available at <a className="hight__lighted-text-underlined" target="_blank" href="mailto:hello@eventbuzz360.com">www.acica.org.au</a> or by calling the ACICA at <a className="hight__lighted-text-underlined" target="_blank" href="tel:+61 (0) 2 9223 1099">+61 (0) 2 9223 1099</a>).</p>
                                    </li> 
                                    <li>
                                        <p><strong>Arbitration Process:</strong> Any dispute, controversy or claim arising out of, relating to or in connection with this contract, including any question regarding its existence, validity or termination, shall be resolved by arbitration in accordance with the ACICA Arbitration Rules.</p>
                                    </li> 
                                    <li>
                                        <p>The arbitration process will comply with the ACICA Rules.</p>
                                    </li>
                                    <li>
                                        <p><strong>Arbitration Location and Procedure.</strong> Unless you and EventBuzz360 agree otherwise, any dispute, controversy or claim arising out of, relating to or in connection with this contract, including any questions regarding its existence, validity or termination, shall be resolved by arbitration in accordance with the ACICA Expedited Arbitration Rules. The seat of arbitration shall be Sydney, Australia</p>
                                    </li> 
                                    <li>
                                        <p><strong>Arbitrator's Decision and Governing Law.</strong> The arbitrator shall apply Australian law consistent with the ACICA Rules. Australian Arbitration Act and applicable statutes of limitations, and shall honour claims of privilege recognised by law. The arbitrator will render an award within the timeframe specified in the ACICA Rules. Judgment on the arbitration may be entered in any court having jurisdiction thereof. Any award of damages by an arbitrator must be consistent with the "Disclaimers and Limitations of Liability" section above. The arbitrator may award declaratory or injunctive relief in favour of the claimant only to the extent necessary to provide relief warranted by the claimant's individual claim.</p>
                                    </li> 
                                    <li>
                                        <p><strong>Fees.</strong> Each party's responsibility to pay the arbitration filing, administrative and arbitrator fees will depend on the circumstances of the arbitration and are set forth in the (ACICA) Rules.</p>
                                    </li> 
                                </ol>
                            </li>
                            <li><h5>Communications.</h5>
                                <p><strong>You are not required to agree to receive promotional text messages, calls or pre-recorded messages as a condition of using the EventBuzz360 Platform.</strong> By electing to submit your phone number to us and agreeing to these Terms, you agree to receive communications from the EventBuzz360 entities including via text messages, calls, pre-recorded messages, and push notifications, any of which may be generated by automatic telephone dialling systems. These communications include, for example, operational communications concerning your account or use of the EventBuzz360 Platform, updates concerning new and existing features on the EventBuzz360 Platform, communications concerning promotions run by us or third parties, and news relating to the EventBuzz360 Platform and industry developments. Standard text message charges applied by your telephone carrier may apply to text messages we send.</p>
                                <p><strong>If you wish to stop receiving promotional emails or promotional text messages, we provide the following methods for you to opt-out or unsubscribe:</strong></p>
                                <ol type="a">
                                    <li><strong>follow the instructions we provide in the email or initial text message for that category of promotional emails or text messages; or</strong></li>
                                    <li><strong>if you have an account on the EventBuzz360 Platform, you may opt-out or unsubscribe using your settings.</strong></li>
                                </ol>
                            </li>
                            <li><h5>Communications.</h5>
                                <ol type="a">
                                    <li><strong>Supplemental Terms for Certain Services:</strong> Certain services offered on the EventBuzz360 Platform may require you to enter into a separate agreement and/or be subject to additional terms. For example: placement of advertisements, participation in brand services, offering products for sale in the EventBuzz360 Shop, or use of Site Designer each require you to enter into a separate agreement with terms specific to that service. In the event of any conflict between this Agreement and the terms of that separate agreement, the terms of that separate agreement will control.</li>
                                    <li><strong>Application Provider Terms:</strong> If you access the EventBuzz360 Platform through a EventBuzz360 application, you acknowledge that this agreement is between you and EventBuzz360 only, and not with another application service or application platform provider (such as Apple, Pty Ltd. or Google Pty Ltd.), which may provide you the application subject to its own terms.</li>
                                    <li><strong>Controlling Law and Jurisdiction:</strong> This agreement will be interpreted in accordance with the laws of Australia, without regard to their conflict-of-law provisions. You and we agree to submit to the personal jurisdiction of a Australian courts located in Sydney, Australia for any actions for which the arbitration provision, as set forth in Section 8, does not apply.</li>
                                    <li><strong>Export:</strong> The EventBuzz360 Platform is controlled and operated from our Sydney office in Australia. EventBuzz360 software is further subject to Australia export controls. No software for EventBuzz360 may be downloaded or otherwise exported or re-exported in violation of any applicable laws or regulations. You represent that you are not (1) located in a country that is subject to Australian government embargo or that has been designated by the Australian as a "terrorist supporting" country, and (2) listed on any Australian government list of prohibited or restricted parties.</li>
                                    <li><strong>Changes:</strong> We reserve the right at any time to:
                                        <ol type="i">
                                            <li>change the terms and conditions of this Agreement, consistent with applicable law;</li>
                                            <li>change the EventBuzz360 Platform, including eliminating or discontinuing any information or services or other features in whole or in part; and</li>
                                            <li>deny or terminate your EventBuzz360 account, or use of and access to the EventBuzz360 Platform.</li>
                                        </ol>
                                        <p>Any changes we make to the Terms will be effective immediately upon our making such changes available on the EventBuzz360 Platform and posting notice of such changes on the EventBuzz360 Platform or in another manner in our reasonable discretion. You agree that your continued use of the EventBuzz360 Platform after such changes constitutes your acceptance of such changes. If you do not agree with any of the updates to this Agreement, you should not use the EventBuzz360 Platform. Be sure to return to this page periodically to ensure your familiarity with the most current version of the Terms of Use.</p>
                                    </li>
                                    <li><strong>Languages:</strong> English is the authoritative text of this Agreement and all communications, notices, arbitrations and other actions and proceedings relating to this Agreement will be made and conducted in English, even if we choose to provide translations of this Agreement into the native languages in certain countries. The number of arbitrators shall be one on Article 10 of the ACIC Arbitration Rules.</li>
                                    <li><strong>Assignment:</strong> No terms of this Agreement, nor any right, obligation, or remedy hereunder is assignable, transferable, delegable, or sublicensable by you except with EventBuzz360's prior written consent, and any attempted assignment, transfer, delegation, or sublicense shall be null and void. EventBuzz360 may assign, transfer, or delegate this Agreement or any right or obligation or remedy hereunder in its sole discretion.</li>
                                    <li><strong>Waiver:</strong> Our failure to assert a right or provision under this Agreement will not constitute a waiver of such right or provision.</li>                                    
                                    <li><strong>Headings:</strong> Any heading, caption, or section title contained is inserted only as a matter of convenience and in no way defines or explains any section or provision hereof.</li>                                    
                                    <li><strong>Further Assurances:</strong> You agree to execute a hard copy of this Agreement and any other documents, and take any actions at our expense that we may request to confirm and effect the intent of this Agreement and any of your rights or obligations under this Agreement.</li>                                    
                                    <li><strong>Entire Agreement/Severability:</strong> This Agreement supersedes all prior terms, agreements, discussions and writings regarding the EventBuzz360 Platform and constitutes the entire agreement between you and us regarding the EventBuzz360 Platform. If any part of this Agreement is found to be unenforceable, then that part will not affect the enforceability of the remaining parts of the Agreement, which will remain in full force and effect.</li>                                                                        
                                    <li><strong>Survival:</strong> The following provisions will survive expiration or termination of this Agreement: Section 2 (Your Content), Section 3(d)(Restrictions) and 3(e)(ownership), Section 6 (Disclaimers and Limitations of Liability), Section 7 (Indemnification), Section 8 (Dispute Resolution) and Section 10 (Miscellaneous).</li>                                                                        
                                    <li><strong>l)	Contact:</strong> Feel free to contact us by visiting <a className="hight__lighted-text-underlined" target="_blank" href="mailto:hello@eventbuzz360.com">hello@eventbuzz360.com</a> with any questions about this agreement.</li>                                                                                                            
                                </ol>
                            </li>
                        </ol>
                    </div>
                </div>                
            </section>
        )
    }
}