import React, { Component } from 'react'

import { StarRating } from '../../Global/StarRating'

export default class Reviews extends Component {

    state = {
        starExplanations: [
            "Would not recommend this pro",
            "Just ok, could have been better",
            "Pretty good overall",
            "Great job, recommend",
            "Excellent job, highly recommend"
        ],
    }

    render() { 

        const { reviews } = this.props 
        
        return (
            <div className="supplier-profile__reviews-container">
                <div className="supplier-profile__reviews">
                {reviews && reviews.map(review => (
                    <div key={review.id} className="supplier-profil__review">
                        <div className="supplier-profile__review-header">
                            <div className="supplier-profile__company-details">
                                <h5 className="supplier-profile__review-company-name">{review.first_name + ' ' + review.last_name}</h5>
                                <div className="supplier-header__star-container">
                                    <StarRating stars={review.rating}></StarRating>   
                                </div>                                 
                            </div>
                        </div>
                        <div className="supplier-profile__review-details">
                            <p>
                                <span className="each__review-header"><strong>Relationship: </strong></span>
                                {review.relationshiip}
                            </p>
                            <p>
                                <span className="each__review-header"><strong>Event Date: </strong></span>
                                {review.project_completion_month + '-' + review.project_completion_year}
                            </p>
                            <p>
                                <span className="each__review-header"><strong>Project Price: </strong></span>
                                {review.project_cost}
                            </p>
                        </div>
                        <p className="supplier-profile__review-comment">{review.review_comment}</p>
                    </div>))}                    
                </div>
            </div>
        )
    }
}