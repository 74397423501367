import React, { Component } from 'react'
import { connect } from "react-redux"

import { checkLoginStatus } from '../../../actions/sessionActions'
import { 
    loadSupplierEventPhotos, 
    loadSupplierMainServices,
    loadSubCategoriesForParentCategory,
    loadSupplierProfileViewInformation,
    loadSupplierSubServicesForMainService,
    fetchAllReviewsForSupplierProfileInAction,
    loadSupplierOtherSubServicesForMainService
} from "../../../actions/profileActions"

import {
    fetchSupplierPublicProfileServiceInformationInAction,
    fetchSupplierPublicProfileLocationMainInformationInAction,
    fetchSupplierPublicProfileOtherServiceInformationInAction,
    fetchSupplierPublicProfileOtherLocationInformationInAction
} from '../../../actions/galleryActions'

import { SupplierHeader } from './SupplierHeader'
import SupplierFilter from './SupplierFilter'
import { Overview } from './Overview'
import { Photos } from './Photos'
import Reviews from './Reviews'

import './styles/supplier-profile.css'

class PrivateProfile extends Component {

    state = {
        currentTab: 0,
        supplier: {
            basic: {},
            photos: [],
            reviews: []
        },   
        activeTab: 0,  
        mainServices: [],  
        mainServiceId: null,
        subServicesInfo: [],
        additionalServices: [],                                          
        tabs: [ 'overview', 'photos', 'reviews' ]
    }

    componentDidMount() {
        this.props.checkLoginStatus().then(data => {
            if (data) {
                this._loadProfileDetails()
            }            
        })        
    }

    _loadProfileDetails = () => {
        this.props.loadSupplierProfileViewInformation().then(data => {
            this.props.loadSupplierEventPhotos().then(photos => {
                this.props.fetchAllReviewsForSupplierProfile().then(reviews => {
                    this.setState({ supplier: { basic: data, photos: photos, reviews: reviews }})
                })                            
            })            
            this.props.fetchSupplierPublicProfileServiceInformation(data.email).then(services => this.setState({ services }))       
            this.props.fetchSupplierPublicProfileOtherServiceInformationInAction(data.email).then(otherServices => this.setState({ otherServices }))                       
            this.props.fetchSupplierPublicProfileLocationMainInformationInAction(data.email).then(areas => this.setState({ areas }))
            this.props.fetchSupplierPublicProfileOtherLocationInformationInAction(data.email).then(otherAreas => this.setState({ otherAreas }))         
            this.props.loadSupplierMainServices().then(mainServices => this.setState({ mainServices }))
            this.props.loadSubCategoriesForParentCategory(data.supplier_type_id).then(data => {
                if (data.length > 0) {                                     
                    this.setState({ 
                        supplierSubTypes: data,
                        isSupplierTypeDisabled: true,
                    }) 
                }
            })
        })
    } 

    selectedTab = key => this.setState({ currentTab: key })

    activateTab = (index, mainServiceId) => {
        const { activeTab } = this.state
        if (activeTab === parseInt(mainServiceId) ) {
            this.setState({ activeTab: 0 })
        } else { 
            this.setState({ activeTab: parseInt(mainServiceId) }, () => (
                this.props.loadSupplierSubServicesForMainService(mainServiceId).then(subServicesInfo => {                
                    this.props.loadSupplierOtherSubServicesForMainService(mainServiceId).then(otherSubServicesInfo => {
                        console.log(subServicesInfo)
                        this.setState({ 
                            subServicesInfo: subServicesInfo,
                            otherSubServicesInfo: otherSubServicesInfo
                        })
                    })
                })    
            ))
        }
    }

    render() {

        let activeComponent = null

        const { 
            tabs,
            areas,
            supplier,            
            services,
            activeTab,
            otherAreas,
            currentTab,
            mainServices,            
            otherServices,
            subServicesInfo
        } = this.state

        if (currentTab === 1) {
            activeComponent = <Photos photos={supplier.photos} />
        }
        if (currentTab === 2) {
            activeComponent = <Reviews 
                                reviews={supplier.reviews}
                                logo={supplier.basic.profile_photo}                                    
                                companyName={supplier.basic.company_name} 
                            /> 
        }
        if (!currentTab) {
            activeComponent = <Overview                                 
                                areas={areas}                                
                                services={services} 
                                activeTab={activeTab}
                                otherAreas={otherAreas}                                  
                                supplier={supplier.basic}
                                mainServices={mainServices}                                
                                otherServices={otherServices}
                                activateTab={this.activateTab}
                                subServicesInfo={subServicesInfo}
                            />
        }

        return (
            <div className="main">
                <SupplierHeader supplier={supplier} />
                <SupplierFilter 
                    tabs={tabs} 
                    supplier={supplier}
                    activeTab={this.selectedTab}                    
                />
                {activeComponent}
            </div>
        )
    }
}

const mapStateToProps = ({ session }) => ({      
    isLoggedIn: session.isLoggedIn
}) 

const mapDispatchToProps = dispatch => ({
    checkLoginStatus: () => dispatch(checkLoginStatus()),  
    loadSupplierEventPhotos: () => dispatch(loadSupplierEventPhotos()),    
    loadSupplierMainServices: () => dispatch(loadSupplierMainServices()),
    loadSupplierProfileViewInformation: () => dispatch(loadSupplierProfileViewInformation()),    
    fetchAllReviewsForSupplierProfile: () => dispatch(fetchAllReviewsForSupplierProfileInAction()),
    loadSubCategoriesForParentCategory: parentCatId => dispatch(loadSubCategoriesForParentCategory(parentCatId)),
    loadSupplierSubServicesForMainService: mainServiceId => dispatch(loadSupplierSubServicesForMainService(mainServiceId)),
    fetchSupplierPublicProfileServiceInformation: email => dispatch(fetchSupplierPublicProfileServiceInformationInAction(email)),
    loadSupplierOtherSubServicesForMainService: mainServiceId => dispatch(loadSupplierOtherSubServicesForMainService(mainServiceId)),
    fetchSupplierPublicProfileOtherServiceInformationInAction: email => dispatch(fetchSupplierPublicProfileOtherServiceInformationInAction(email)),
    fetchSupplierPublicProfileLocationMainInformationInAction: email => dispatch(fetchSupplierPublicProfileLocationMainInformationInAction(email)),
    fetchSupplierPublicProfileOtherLocationInformationInAction: email => dispatch(fetchSupplierPublicProfileOtherLocationInformationInAction(email)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PrivateProfile)
