import React, { Component } from 'react'

import './styles/static.css'

export default class AcceptableUsePolicy extends Component  {

    render () {

        return (
            <section id="about" className="static-page">
                <div className="static-page__static-container">
                    <header className="static-page__header">
                        <h3 className="static-page__title">EVENTBUZZ360 - ACCEPTABLE USE POLICY </h3>
                    </header>
                    <div className="static-page__content">
                        <p>As part of EventBuzz360’s Acceptable Use Policy, you agree not to use the information, services or any other part of the Site to take any action or actions that:</p>                                                
                        <ol type="a">
                            <li>are contrary to EventBuzz360's public image, goodwill or reputation;</li>
                            <li>make sure you connect to the right service on our Platform when we make any changes to the way the Platform works.</li>
                            <li>express or imply that any of your statements, activities or causes are endorsed by us, without our prior written consent in each instance;</li>
                            <li>violate any applicable law, statute, ordinance or regulation, or encourage any conduct that could constitute a criminal offense or give rise to civil liability;</li>
                            <li>are libellous, defamatory, threatening, harassing, invasive of privacy, abusive, tortious, hateful, discriminatory, pornographic or obscene;</li>
                            <li>transmit any trade secret or other material, non-public information about any person, company or entity without the authorization to do so;</li>
                            <li>restrict or inhibit any other visitor from using the Site, including, without limitation, by means of "hacking" or defacing any portion of the Site;</li>
                            <li>modify, adapt, sub-license, translate, sell, reverse engineer, decompile, or disassemble any portion of the Site or Information;</li>
                            <li>remove any copyright, trademark, or other proprietary rights notices contained in or on the Information, Site, or Services;</li>
                            <li>sublicense, sell, rent, lease, transfer, assign, or convey any rights under this Agreement to any third party, or otherwise commercially exploit or profit from the Information or content of the Site, or any portion thereof, in any manner whatsoever, except as expressly permitted herein;</li>
                            <li>"frame" or "mirror" any part of the Site without our prior written authorization;</li>                            
                            <li>distribute any virus, worm or other similar or deleterious files, scripts or programming routines;</li>                            
                            <li>interfere with or disrupt any services or equipment with the intent of causing an excessive or disproportionate load on the infrastructure of EventBuzz360 or its licensors or suppliers;</li>                            
                            <li>involve the preparation or distribution of junk mail, spam, chain letters, pyramid schemes, or other deceptive or unsolicited bulk or commercial email, or violate in any way the Controlling the Assault of Non-Solicited Pornography and Marketing Act (CAN-SPAM Act of 2003) or equivalent, applicable, foreign law;</li>                            
                            <li>use any robot, spider, site search/retrieval application, or other manual or automatic device or process to retrieve, index, "data mine" the Site or in any way reproduce or circumvent the navigational structure or presentation of the Site or its contents, authentication and security measures;</li>                                                        
                            <li>forge headers or otherwise manipulate identifiers in order to disguise the origin of any submission;</li>                                                                                    
                            <li>execute any form of network monitoring or run a network analyzer or packet sniffer or other technology to intercept, decode, mine or display any packets used to communicate between the Site's servers or any data not intended for you; and/or</li>                                                                                    
                            <li>harvest or collect information about any Site visitors or members without their express consent.</li>                                                                                    
                            <li>contains any reference to or likeness of any identifiable third parties, unless consent has been obtained from all such individuals and their parent/legal guardian if they are under the age of majority in their jurisdiction of residence.</li>                                                                                    
                        </ol>                        
                    </div>
                </div>                
            </section>
        )
    }
}