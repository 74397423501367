import React, { Component } from 'react'
import { connect } from "react-redux"
import { Redirect } from 'react-router'

import { checkLoginStatus } from '../../actions/sessionActions'
import { loadSupplierCategories } from '../../actions/profileActions'

import BusinessInformation from './BusinessInformation'
import ContactInformation from './ContactInformation'
import SocialInformation from './SocialInformation'
import Services from './Services'
import Location from './Locations'
import ProfessionalReviews from './ProfessionalReviews'
import AddEvent from './AddEvent'

class Wizard extends Component {

    state = { 
        step: 1,        
        town: "",
        jobCost: "",
        website: "",            
        postcode: "",
        jobCostTo: "",  
        allStates: [],          
        companyBio: "",
        otherAreas: "",
        jobCostFrom: "",
        areasServed: [],
        costDetails: "",
        companyName: "",
        supplierType: "",        
        otherServices: "",
        selectedState: "",
        contactMobile: "",
        selectedImage: "",
        businessAwards: "",         
        additionalAreas: [],
        supplierSubTypes: [],
        contactTelephone: "",       
        selectedCountry: "AU",        
        selectedPhoneCode: "",
        contactPersonEmail: "",
        additionalServices: [],   
        loggedInRealStatus: true,             
        contactPersonLastName: "",
        contactPersonFirstName: ""              
    }

    componentDidMount() {
        this.props.checkLoginStatus().then(data => {
            if (data) {
                this.fetchFindPro()
                this.fetchCountries()    
            }            
        })        
    }

    componentDidMount() {
        if ((this.props.emailAddress) && (this.props.password)) 
            this.setState({ emailAddress: this.props.emailAddress, password: this.props.password })
    }

    fetchFindPro = _ => this.props.loadSupplierTypes().then(supplierTypes => this.setState({ supplierTypes }))

    fetchCountries = () => {
        fetch('/countries.json')
          .then(rsp => rsp.json())
          .then(allCountries => { 
            this.setState({ allCountries })
        })
    }

    fetchCities = _ => {
        const allCountries = this.state.allCountries || []        
        if (this.state.selectedCountry) {
            const cities = allCountries.find(country => (country.value === this.state.selectedCountry), this.cities)
            this.setState({ allCities: cities.cities})
        }                
    }

    fetchPhoneCodes = _ => {
        fetch('/phoneCodes.json')
            .then(rsp => rsp.json())
            .then(allPhoneCodes => {
                this.setState({ allPhoneCodes })
                this.setState({ selectedPhoneCode: this.state.selectedCountry })
            })
    }

    filteredCountry = selectedCountry => {
        this.setState({selectedCountry : selectedCountry})
        this.setState({selectedState : ""})
        const filteredCountry = this.state.allCountries.find(country => (country.value === selectedCountry), this)
        this.setState({ allStates: filteredCountry.regions })
        this.setState({ selectedPhoneCode : selectedCountry })
    }

    selectedImage = selectedImage => this.setState({ selectedImage })

    filteredSubcategories = subCategory => {
        this.setState({ supplierSubTypes: [...this.state.supplierSubTypes, subCategory] })
        if (this.state.supplierSubTypes.indexOf(subCategory) !== -1) 
            this.setState({ supplierSubTypes: this.state.supplierSubTypes.filter(supplierSubType => supplierSubType !== subCategory) })
    }

    filteredAreas = area => {
        this.setState({ areasServed: [...this.state.areasServed, area] })
        if (this.state.areasServed.indexOf(area) !== -1) 
            this.setState({ areasServed: this.state.areasServed.filter(areaServed => areaServed !== area) })
    }

    additionalServices = additionalService => {
        this.setState({ additionalServices: [...this.state.additionalServices, additionalService] })
        this.setState({ otherServices: "" })
    }
    
    removeAdditionalService = additionalServiceIndex => this.setState({ additionalServices: this.state.additionalServices.filter((additionalServices, i) => i !== additionalServiceIndex) })

    additionalAreas = additionalArea => {
        this.setState({ additionalAreas: [...this.state.additionalAreas, additionalArea] })
        this.setState({ otherAreas: "" })
    }
    
    removeAdditionalAreas = additionalAreasIndex => this.setState({ additionalAreas: this.state.additionalAreas.filter((additionalArea, i) => i !== additionalAreasIndex) })    

    nextStep = _ => this.setState({ step: this.state.step + 1 })

    prevStep = _ => this.setState({ step: this.state.step - 1 })

    handleChange = input => event => this.setState({ [input]: event.target.value })

    render() {

        const { 
                step,
                town,
                jobCost,
                website,
                postcode,
                allCities,
                jobCostTo,                
                allStates,
                companyBio, 
                otherAreas, 
                areasServed,                              
                jobCostFrom,
                companyName,
                costDetails,
                supplierType,                                                
                allCountries,                
                supplierTypes, 
                allPhoneCodes,               
                selectedState,
                selectedImage,
                contactMobile,                
                otherServices,
                businessAwards,                                                                                                               
                selectedCountry,
                additionalAreas,
                contactTelephone,
                supplierSubTypes,                                
                selectedPhoneCode,
                contactPersonEmail,                
                additionalServices,
                loggedInRealStatus,
                contactPersonLastName,
                contactPersonFirstName
            } = this.state

        const values = { 
            town,
            jobCost,
            website,
            postcode,
            allCities,
            allStates,                
            jobCostTo,
            otherAreas,
            companyBio,
            areasServed,
            companyName,                                
            costDetails,
            jobCostFrom,
            supplierType,                
            allCountries,
            supplierTypes,              
            selectedImage,  
            selectedState,                                
            allPhoneCodes,
            otherServices,
            contactMobile,
            businessAwards,                  
            selectedCountry,                                                                               
            additionalAreas,
            supplierSubTypes,             
            contactTelephone,
            selectedPhoneCode,                
            contactPersonEmail,                
            additionalServices,
            contactPersonLastName,                                                                                
            contactPersonFirstName                                                                              
        }

        if (!loggedInRealStatus) return <Redirect to={'/login'} />

        switch(step) {
            case 1:
                return <BusinessInformation                             
                            values={values}
                            nextStep={this.nextStep} 
                            selectedImage={this.selectedImage}
                            handleChange = {this.handleChange}                            
                            filteredCountry={this.filteredCountry}                            
                        />
            case 2:
                return <ContactInformation 
                            values={values}
                            nextStep={this.nextStep}
                            prevStep={this.prevStep}
                            handleChange = {this.handleChange}                            
                        />
            case 3:
                return <SocialInformation 
                            values={values}
                            nextStep={this.nextStep}
                            prevStep={this.prevStep}
                            handleChange = {this.handleChange}                            
                        />
            case 4:
                return <Services 
                            step={4}
                            values={values}
                            nextStep={this.nextStep}
                            prevStep={this.prevStep}
                            handleChange={this.handleChange}                            
                            filteredSubcategories={this.filteredSubcategories}
                            additionalServices={this.additionalServices}
                            removeAdditionalService={this.removeAdditionalService}
                            filteredAreas={this.filteredAreas}
                            additionalAreas={this.additionalAreas}
                            removeAdditionalAreas={this.removeAdditionalAreas}
                        />
            case 5:
                return <Location 
                            step={5}
                            values={values}
                            nextStep={this.nextStep}
                            prevStep={this.prevStep}
                            handleChange={this.handleChange}                            
                        />
            case 6:
                return <ProfessionalReviews 
                            step={6}
                            values={values}
                            nextStep={this.nextStep}
                            prevStep={this.prevStep}
                            handleChange={this.handleChange}                            
                        />
            case 7:
                return <AddEvent 
                            step={7}
                            values={values}
                            nextStep={this.nextStep}
                            prevStep={this.prevStep}
                            handleChange={this.handleChange}                            
                        />
            }
    }
}

const mapStateToProps = ({ profile, session }) => ({    
    userId: session.userId,
    loading: profile.loading,
    isLoggedIn: session.isLoggedIn
})
  
const mapDispatchToProps = dispatch => ({
    checkLoginStatus: _ => dispatch(checkLoginStatus()),
    loadSupplierTypes: _ => dispatch(loadSupplierCategories())
})
  
export default connect(mapStateToProps, mapDispatchToProps)(Wizard)