import React from 'react'

import '../find-pro-hire.css'

const modifyTheSubCategoriesList = categories => {
  return categories.map(eachCategory => {
    if (eachCategory.subcategories) {
      eachCategory.subcategories = JSON.parse(eachCategory.subcategories)
    }
    return eachCategory
  })
}

export const FindProCategories = ({ categories, redirectToDetails, filledSubCategories }) => {
  const parsedCategories = modifyTheSubCategoriesList(categories)
  return (
      <div className="find-pro__container">
        {
          parsedCategories
              .map((category, i) => (
                  <div className="find-pro__categories" key={category.id}>
                    <h2 className="find-pro__category-title">{category.category_name}</h2>
                    {
                      category.subcategories &&
                      (<div className="find-pro__sub-categories">
                        {category.subcategories.map((subcategory, j) => (
                        <span onClick={() => redirectToDetails(category.category_name, category.id, subcategory.category_name, subcategory.id)}
                        className={`find-pro__sub-category-title ${filledSubCategories.indexOf(subcategory.id) !== -1 ? 'filled__find-a-pro-hire-link' : 'empty-a-pro-hire-link'}`} key={subcategory.id}>{subcategory.category_name}</span>))}
                      </div>)
                    }
                  </div>
              ))
        }
      </div>
  )
}