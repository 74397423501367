import React, { Component } from 'react'
import queryString from 'query-string'
import { Redirect } from 'react-router-dom'
import { connect } from "react-redux"

import { accountActivation } from "../../actions/registerActions"
import { showNotification, hideNotification } from "../../actions/notificationActions"
import { authLoginWithoutPasswordAfterActivation } from '../../actions/sessionActions'

import img1 from '../../assets/images/activation.png'
import logo_transparent from '../../assets/images/logo.png'
import './styles/activation.css'

class Activation extends Component {

    state = {
        image: img1,        
        overlay: true,
        activaitonkey: "",
        imageCredits: "P H O T O F L Y E R"
    }

    componentDidMount() {
        setTimeout(() => { this.setState({ overlay: false }) }, 100) 
        const qStrings = queryString.parse(this.props.location.search)
        if (qStrings.key) this.setState({ activaitonkey: qStrings.key })
    }

    activateAccount = activationKey => this.props.activateAccount(activationKey).then(data => {
        if (data) {
            this.props.showNotification('Your account have been activated!.', 'success')
            setTimeout(() => {
                this.props.hideNotification()                
                this.props.authLoginWithoutPasswordAfterActivation(data.email).then(data => {
                    if (data.status === "user_credentials_ok") {                                            
                        this.props.history.push('profile')
                    } else {
                        this.props.showNotification('Something went wrong! Please contact support...', 'error')
                        setTimeout(() => this.props.hideNotification(), 5000)  
                    }
                })
            }, 3000)                
        }else {
            this.props.showNotification('Something went wrong! Please contact support...', 'error')
            setTimeout(() => this.props.hideNotification(), 5000)                
        }
    })

    render() {

        const { loading } = this.props

        const { 
            image,            
            imageCredits,
            activaitonkey
        } = this.state

        if (!activaitonkey) return <Redirect to={'/'} />

        return (
            <>
                <div className={loading ? "overlay" : "overlay hide"}>
                    <div className="profile__loader-container">
                        <div className="loader"><span>Loading...</span></div>
                    </div>
                </div>            
                <div id="activation-page" className="activation">
                    <div className="activation__image-container">
                        <img className="activation__background-image" src={image} alt="activation feature image" />
                        {
                            imageCredits && 
                                <span className="activation__signup-credits">
                                    <p>PHOTO CREDITS:</p>
                                    <p>{imageCredits}</p>
                                </span> 
                        }
                    </div>
                    <div className="activation-container">
                        <div className="activation__form-wrapper">
                            <header className="activation-form__header">
                                <img className="" src={logo_transparent} alt="logo" />
                            </header>
                            <div className="activation__btn-container">
                                <h6 className="activation__heading">Thank you for registering with EventBuzz360.</h6>
                                <p className="activation__intro">Please verify your email</p>
                                <button className="btn btn-gold" onClick={() => this.activateAccount(activaitonkey)}>Verify my Account! </button>    
                            </div>   
                            <footer className="activation-form__footer">
                                <p>Thank you for using EventBuzz360</p>
                            </footer>   
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = ({ register }) => ({
    loading: register.loading
})
  
const mapDispatchToProps = dispatch => ({    
    hideNotification: () => dispatch(hideNotification()),
    activateAccount: key => dispatch(accountActivation(key)),        
    showNotification: (message, notificationType) => dispatch(showNotification(message, notificationType)),
    authLoginWithoutPasswordAfterActivation: email => dispatch(authLoginWithoutPasswordAfterActivation(email)),
})
  
export default connect(mapStateToProps, mapDispatchToProps)(Activation)