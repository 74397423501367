import React, { Component } from 'react'
import { createStore, applyMiddleware } from "redux"
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import thunk from "redux-thunk"
import { Provider } from "react-redux"
import 'rodal/lib/rodal.css'

import rootReducer from './reducers/rootReducer'

import Header from './screens/Global/Header'
import Footer from './screens/Global/Footer'
import ProfileLayout from './screens/ProfileScreen/ProfileLayout'

// import LandingScreen from './screens/LandingScreen/LandingScreen'
// import SignupScreen from './screens/SignupScreen/Signup'
import HoldingScreen from './screens/LandingScreen/HoldingScreen'

import EditProfileScreen from './screens/ProfileScreen/EditProfileScreen'
import IndexScreen from './screens/IndexScreen/IndexScreen'
import FindPro from './screens/IndexScreen/find-pro'
import Hire from './screens/IndexScreen/hire'
import FindProDetail from './screens/IndexScreen/find-pro/FindProDetail'
import HireDetail from './screens/IndexScreen/hire/HireDetail'
import Discover from './screens/IndexScreen/discover'
import People from './screens/IndexScreen/people'
import Venues from './screens/IndexScreen/venues'
import Wishlist from './screens/Wishlist/Wishlist'
import MyFolders from './screens/ProfileScreen/MyFolders'
import Supplier from './screens/ProfileScreen/supplier'
import Services from './screens/ProfileScreen/Services'
import Locations from './screens/ProfileScreen/Locations'
import ProfessionalReviews from './screens/ProfileScreen/ProfessionalReviews'
import ProfessionalReviewsListing from './screens/ProfileScreen/ProfessionalReviewsListing'
import Upload from './screens/ProfileScreen/Upload'
import Password from './screens/ProfileScreen/Password'
import AddEvent from './screens/ProfileScreen/AddEvent'
import Advertise from './screens/ProfileScreen/Advertise'
import MyAdverts from './screens/ProfileScreen/MyAdverts'
import ContactUs from './screens/IndexScreen/contactUs'
import Activation from './screens/SignupScreen/Activation'
import Login from './screens/SignupScreen/Login'
// import PasswordReset from './screens/SignupScreen/PasswordReset'
import Snackbars from './reusables/Snackbars/Snackbars'
import About from './screens/Global/About'
import PrivacyPolicy from './screens/Global/PrivacyPolicy'
import PublicProfile from '../src/screens/IndexScreen/supplier/PublicProfile'
import AcceptableUsePolicy from './screens/Global/AcceptableUsePolicy'
import CopyRightTrademark from './screens/Global/CopyRightTrademark'
import TermsAndUse from './screens/Global/TermsAndUse'
import ReviewPolicy from './screens/Global/ReviewPolicy'
import ButtonsAndBadges from './screens/Global/ButtonsAndBadges'

import '../src/styles/global.css'
import '../src/styles/variables.css'
import '../src/styles/grid.css'
import '../src/styles/forms.css'
import '../src/styles/rodal.css'
import '../src/styles/rich-text.css'
import '../src/styles/star-rating.css'
import './header.css'

export default class App extends Component {

    constructor(props) {
        super(props)
        this.store = createStore(
            rootReducer, 
            applyMiddleware(thunk)
        )
    }

    render() {
        return (
            <Provider store={this.store}>
                <Router>
                    <>                        
                        <Route exact path="/home" component={HoldingScreen} />
                        <Route exact path="/activate" component={Activation} />
                        <Route exact path="/activate/:key" component={Activation} />
                        {/* <Route exact path="/login" component={Login} />      
                        <Route exact path="/reset-password" component={PasswordReset} />                                          
                        <Route exact path="/signup" component={SignupScreen} />                                                
                        <Route exact path="/home" component={LandingScreen} />                        
                        */}
                        <Header />
                        <Snackbars />
                        <main id="main">
                            <Switch>                                               
                                <Route exact path="/contact-us" component={ContactUs} />     
                                <Route exact path="/discover" component={Discover} />                     
                                <Route exact path="/people" component={People} />    
                                <Route exact path="/venues" component={Venues} />                     
                                <Route exact path="/about" component={About} />
                                <Route exact path="/find-pro" component={FindPro} />
                                <Route exact path="/find-pro-details/:main/:mainId/:sub/:subId" component={FindProDetail} />
                                <Route exact path="/hire" component={Hire} />
                                <Route exact path="/hire-details/:main/:mainId/:sub/:subId" component={HireDetail} />
                                <Route exact path="/wishlist" component={Wishlist} />                            
                                <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                                <Route exact path="/review-policy" component={ReviewPolicy} />
                                <Route exact path="/acceptable-use-policy" component={AcceptableUsePolicy} />
                                <Route exact path="/copy-right-trademark" component={CopyRightTrademark} />
                                <Route exact path="/terms-of-use" component={TermsAndUse} />
                                <Route exact path="/buttons-and-badges" component={ButtonsAndBadges} />                                                             
                                <Route exact path="/" render={props => <IndexScreen {...props} />} />                                  
                                <Route exact path="/gallery/:key?" render={props => <IndexScreen {...props} />} />   
                                <Route exact path="/supplier-public/:supplier+" component={PublicProfile} />                            
                                <ProfileLayout>                                
                                    <Route exact path="/supplier" component={Supplier} />                                
                                    <Route exact path="/profile" component={EditProfileScreen} />
                                    <Route exact path="/my-folders" component={MyFolders} />
                                    <Route exact path="/services" component={Services} />
                                    <Route exact path="/locations" component={Locations} />
                                    <Route exact path="/supplier-reviews" component={ProfessionalReviews} />
                                    <Route exact path="/my-reviews" component={ProfessionalReviewsListing} />
                                    <Route exact path="/upload" component={Upload} />
                                    <Route exact path="/add-event" component={AddEvent} />
                                    <Route exact path="/change-password" component={Password} />
                                    <Route exact path="/advertise" component={Advertise} />                                                                
                                    <Route exact path="/my-adverts" component={MyAdverts} />                                                                                                
                                </ProfileLayout>                                                                                                                           
                            </Switch>   
                        </main>
                        <Footer />
                    </>                    
                </Router>
            </Provider>              
        )
    }
}