import React from 'react'

// import { galleryImagesUploadingPath } from '../../../utils/config'

export const Photos = ({ photos }) => (
    <div className="gallery">
    {
            photos && photos.map((image, i) => 
                <div className="gallery__image" key={image.image_id}>
                    <img className="gallery__image-item gallery__image-item--supplier-profile" 
                        src={image.image_link} 
                        alt="gallery" key={image.image_id} />
                </div>
            )
    }
    </div>
)