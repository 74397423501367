import React, { Component } from 'react'

export class PostsFilter extends Component {
    
    state = {
        postFilter: 'PT'
    }

    onPostFilterChange = e => this.props.filteredPost(e.target.value)

    render() {

        // const { postFilter } = this.state
        const { heading } = this.props
        // const selectOptions = this.props.postsFilters.map((filter, i) => (<option key={i} value={filter.code}>{filter.value}</option>))

        return (
            <div className="filter__posts eb-forms">
                <span className="fitler__heading">{heading}</span>
                {/* <select value={postFilter} onChange={this.onPostFilterChange}>
                    {selectOptions}
                </select> */}
            </div>
        )
    }
}