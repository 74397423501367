import React, { Component } from 'react'
import { connect } from "react-redux"
import { Redirect } from 'react-router'

import { checkLoginStatus } from '../../actions/sessionActions'
import { supplierReviewFormUpdated, postCollectedSupplierReviewInformation } from '../../actions/profileActions'
import { showNotification, hideNotification } from "../../actions/notificationActions"

class ProfessionalReviews extends Component {

    state = {    
        reviewerName: "",
        reviewerEmail: "",
        reviewerComment: "As a professional event company, our business relies on recommendations from our clients. We would appreciate it if you would write a brief review for us on EventBuzz360.com, the most influential events site for organising events.",        
        errorFields: {
            reviewerName: "",
            reviewerEmail: false,
            reviewerComment: false
        },
        touched: {
            reviewerName: false,
            reviewerEmail: false,
            reviewerComment: false
        },
        errorsInTheForm: true,
        successInTheForm: false,
        loggedInRealStatus: true      
    }

    componentDidMount () {
        this.props.checkLoginStatus().then(data => data)
    }

    updateFormHandler = field => event => {         
        this.setState({ 
            [field]: event.target.value, 
            errorFields: {
                [field]: (!event.target.value) ? true : false
            },
            errorsInTheForm: (!event.target.value) ? true : false
        })                                        
        this.props.supplierReviewFormUpdated([field], event.target.value)     
    }

    handleBlur = field => event => this.setState({ touched: { ...this.state.touched, [field]: true }, errorFields: { [field]: (!event.target.value) ? true : false }})

    sendMessage = () => {
        const {
            reviewerName,
            reviewerEmail,
            reviewerComment,
            errorsInTheForm
        } = this.state
        if (!errorsInTheForm) {
            const postData = {
                reviewerName: reviewerName,
                reviewerEmail: reviewerEmail,
                reviewerComment: reviewerComment
            } 
            this.props.postCollectedSupplierReviewInformation(postData).then(data => {
                if (data.status === "reviwer_mail_success") {
                    this.props.showNotification('Your review added!.', 'success')
                    setTimeout(() => this.props.hideNotification(), 5000)
                    if (this.props.step) {
                        this.setState({
                            reviewerName: "",
                            reviewerEmail: "",
                            reviewerComment: "",
                            errorsInTheForm: {},
                        })
                        this.props.nextStep()
                    }
                } else {
                    this.props.showNotification('Something went wrong! Please contact support...', 'error')
                    setTimeout(() => this.props.hideNotification(), 5000)
                }
            })  
        }
    }

    render() {

        const { 
            touched,
            errorFields,
            reviewerName,
            reviewerEmail,
            reviewerComment,
        } = this.state 

        return (
            <div className="profile__contact-info">
                <div className="profile__section">
                    <span className="profile__section-content-tips">* Indicates required fields!</span>
                    <h6 className="profile__sub-title">
                        Reviews are the No. 1 thing organisers look for when hiring a Pro.
                        <br />
                        Pros with 3+ reviews generally get 10 times more contacts.
                    </h6>
                    <div className="profile__section-content">
                        <div className="profile__form-container">
                            <div className="eb-forms eb-forms--profile">                                
                                <div className="eb-forms__form-group">
                                    <label htmlFor="email">Name <span className="required__indicator">*</span></label>
                                    <div className="profile__input-container">
                                        <input 
                                            id="name" 
                                            type="text"                                         
                                            name="name" 
                                            onBlur={this.handleBlur('reviewerName')}
                                            onChange={this.updateFormHandler('reviewerName')}
                                            defaultValue={(reviewerName && reviewerName)} 
                                            className={`${((touched.reviewerEmail && !reviewerName) || (errorFields.reviewerName)) && 'error__fields-indicator'}`}
                                        />
                                    </div>
                                </div>
                                <div className="eb-forms__form-group">
                                    <label htmlFor="email">Email Address <span className="required__indicator">*</span></label>
                                    <div className="profile__input-container">
                                        <input 
                                            id="email" 
                                            type="text"                                         
                                            name="email" 
                                            onBlur={this.handleBlur('reviewerEmail')}
                                            onChange={this.updateFormHandler('reviewerEmail')}
                                            defaultValue={(reviewerEmail && reviewerEmail)} 
                                            className={`${((touched.reviewerEmail && !reviewerEmail) || (errorFields.reviewerEmail)) && 'error__fields-indicator'}`}
                                        />
                                    </div>
                                </div>                                
                                <div className="eb-forms__form-group">
                                    <label htmlFor="business-awards">Message <span className="required__indicator">*</span></label>
                                    <textarea 
                                        id="business-awards" 
                                        onBlur={this.handleBlur('reviewerComment')}
                                        value={(reviewerComment && reviewerComment)} 
                                        onChange={this.updateFormHandler('reviewerComment')}                                        
                                        className={`${((touched.reviewerComment && !reviewerComment) || (errorFields.reviewerComment)) && 'error__fields-indicator'}`}
                                        name="business-awards"></textarea>
                                </div>
                                <div className="eb-forms__form-group">
                                    <label htmlFor="business-awards"></label>
                                    <div className="profile__button-container profile__button-container--reviews contentLeftToRight">
                                        <button className="btn btn-gold profile__button profile__button-next" disabled={!reviewerName || !reviewerEmail || !reviewerComment} onClick={this.sendMessage}>Send Message</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="profile__tips-section"></div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ profile, session }) => ({
    loading: profile.loading,        
    isLoggedIn: session.isLoggedIn,
    formUpdated: profile.supplierBusinessInfomration.formUpdated
})
  
const mapDispatchToProps = dispatch => ({
    checkLoginStatus: () => dispatch(checkLoginStatus()),
    hideNotification: () => dispatch(hideNotification()),
    supplierReviewFormUpdated: (field, value) => dispatch(supplierReviewFormUpdated(field, value)),                
    showNotification: (message, notificationType) => dispatch(showNotification(message, notificationType)),            
    postCollectedSupplierReviewInformation: postData => dispatch(postCollectedSupplierReviewInformation(postData))
})
  
export default connect(mapStateToProps, mapDispatchToProps)(ProfessionalReviews)