import React, { Component } from 'react'
import { connect } from "react-redux"
import { Redirect } from 'react-router'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import { checkLoginStatus, updateTheDisplayNameSessionInAction } from '../../actions/sessionActions'
import { 
    supplierProfileContactFormUpdate, 
    retrieveAllSupplierContactInformation, 
    postCollectedSupplierContactInformation 
} from '../../actions/profileActions'
import { showNotification, hideNotification } from "../../actions/notificationActions"

class ContactInformation extends Component {

    constructor(props) {
        super(props)
        this.myRef = React.createRef()
    }

    state = { 
        telNo: "",
        surname: "",
        mobileNo: "",
        firstName: "",        
        emailAddress: "",
        errorFields: {           
            surname: false,
            firstName: false,
            emailAddress: false
        },
        touched: {
            surname: false,
            firstName: false,
            emailAddress: false            
        },        
        errorsInTheForm: false,
        successInTheForm: false,
        loggedInRealStatus: true
    }

    componentDidMount () {
        setTimeout(() => {
            this.myRef.current.scrollIntoView({ behavior: 'smooth', block: "end" })  
        }, 100)
        this.props.checkLoginStatus().then(data => {
            if (!data) {
                this.setState({ loggedInRealStatus: false })
            } else {
                this.props.retrieveAllSupplierContactInformation().then(data => {   
                    if (data !== null) {
                        this.setState({
                            telNo: data.tel_no,                            
                            surname: data.surname,
                            mobileNo: data.mobile_no,
                            firstName: data.first_name,
                            emailAddress: data.email_address,                        
                            errorsInTheForm: (!data.first_name || !data.surname || !data.email_address ) ? true : false
                        })                        
                    }          
                })    
            }                 
        })
    }

    back = e => {
        e.preventDefault()
        const {
            telNo, 
            surname,
            mobileNo,
            firstName,
            emailAddress                                    
        } = this.state

        
        if (this.props.formUpdated) {
            if (!this.state.errorsInTheForm) {  
                const postData = {
                    supplierContactFirstName: firstName,
                    supplierContactSurame: surname,
                    supplierContactEmail: emailAddress,
                    supplierContactTelno: telNo,
                    supplierContactMobileNo: mobileNo
                } 
                this.props.postCollectedSupplierContactInformation(postData).then(data => {
                    if (data.status === "supplier_contact_information_updated") {
                        this.props.showNotification('Your contact information have been updated!.', 'success')
                        this.props.prevStep()
                    } else {
                        this.props.showNotification('Something went wrong! Please contact support...', 'error')
                        setTimeout(() => this.props.hideNotification(), 5000)                
                    }
                })        
            }
        } else {
            this.props.prevStep()
        }        
    }

    updateFormHandler = field => event => {  
        if ((field !== 'mobileNo') && (field !== 'telNo')) {
            this.setState({ 
                [field]: event.target.value, 
                errorFields: {
                    [field]: (!event.target.value) ? true : false
                },
                errorsInTheForm: (!event.target.value) ? true : false
            })                        
            this.props.supplierProfileContactFormUpdate([field], event.target.value)         
        } else {
            this.setState({ 
                [field]: event, 
                // errorFields: {
                //     [field]: (!event) ? true : false
                // },
                // errorsInTheForm: (!event) ? true : false
            })                        
            this.props.supplierProfileContactFormUpdate([field], event)         
        }
    }

    handleBlur = field => event => this.setState({ touched: { ...this.state.touched, [field]: true }, errorFields: { [field]: (!event.target.value) ? true : false }})

    saveAndContinue = e => {
        e.preventDefault()
        const {
            telNo, 
            surname,
            mobileNo,
            firstName,
            emailAddress                                    
        } = this.state        
        if (this.props.formUpdated) {
            if (!this.state.errorsInTheForm) {  
                const postData = {
                    supplierContactFirstName: firstName,
                    supplierContactSurame: surname,
                    supplierContactEmail: emailAddress,
                    supplierContactTelno: telNo,
                    supplierContactMobileNo: mobileNo
                }        
                this.props.postCollectedSupplierContactInformation(postData).then(data => {
                    if (data.status === "supplier_contact_information_updated") {
                        this.props.updateTheDisplayNameSessionInAction(postData.supplierContactFirstName)
                        this.props.showNotification('Your contact information have been updated!.', 'success')
                        this.props.nextStep()
                    } else {
                        this.props.showNotification('Something went wrong! Please contact support...', 'error')
                        setTimeout(() => this.props.hideNotification(), 5000)                
                    }
                })        
            }
        } else {
            this.props.nextStep()
        }
    }

    render() {

        const { 
            values, 
            loading 
        } = this.props

        const {
            telNo,  
            touched,          
            surname,
            mobileNo, 
            firstName,
            errorFields,
            emailAddress,
            errorsInTheForm,
            loggedInRealStatus           
        } = this.state        

        if (!loggedInRealStatus) return <Redirect to={'/'} />

        return (
            <>
                <div className={loading ? "overlay" : "overlay hide"}>
                    <div className="profile__loader-container">
                        <div className="loader"><span>Loading...</span></div>
                    </div>
                </div> 
                <div ref={this.myRef} className="profile__contact-info">
                    <div className="profile__section">
                        <span className="profile__section-content-tips">* Indicates required fields!</span>
                        <h6 className="profile__sub-title sub--heading_presentations sub--heading_presentations">Tell us a little about yourself</h6>
                        <div className="profile__section-content">
                            <div className="profile__form-container">
                                <form className="eb-forms eb-forms--profile">                                
                                    <div className="eb-forms__form-group">
                                        <label htmlFor="contact-person-first-name">Contact Person <span className="required__indicator">*</span></label>
                                        <div className="eb-forms--not-fullwidth">
                                            <input 
                                                type="text" 
                                                placeholder="First Name" 
                                                id="contact-person-first-name"                                             
                                                name="contact-person-first-name"                                            
                                                onBlur={this.handleBlur('firstName')}
                                                onChange={this.updateFormHandler('firstName')}                                            
                                                defaultValue={values.contactPersonFirstName || firstName} 
                                                className={`${((touched.firstName && !firstName) || (errorFields.firstName)) && 'error__fields-indicator'}`}
                                            />
                                            <input 
                                                type="text" 
                                                placeholder="Surname" 
                                                id="jcontact-person-last-name"                                             
                                                name="contact-person-last-name"                                            
                                                onBlur={this.handleBlur('surname')}
                                                onChange={this.updateFormHandler('surname')}                                                                                     
                                                defaultValue={values.contactPersonLastName || surname} 
                                                className={`${((touched.surname && !surname) || (errorFields.surname)) && 'error__fields-indicator'}`}
                                            />
                                        </div>
                                    </div>
                                    <div className="eb-forms__form-group">
                                        <label htmlFor="email">Email Address <span className="required__indicator">*</span></label>
                                        <div className="profile__input-container">
                                            <input 
                                                type="text" 
                                                id="contact-email" 
                                                name="contact-email"
                                                placeholder="Email Address" 
                                                onBlur={this.handleBlur('emailAddress')}
                                                onChange={this.updateFormHandler('emailAddress')}                                                                                     
                                                defaultValue={values.contactPersonEmail || emailAddress} 
                                                className={`${((touched.emailAddress && !emailAddress) || (errorFields.emailAddress)) && 'error__fields-indicator'}`}
                                            />
                                        </div>
                                    </div>
                                    <div className="eb-forms__form-group">
                                        <label htmlFor="telephone">
                                            Telephone number 
                                            {/* <span className="required__indicator">*</span> */}
                                        </label>
                                        <div className="eb-forms--not-fullwidth">
                                            <PhoneInput
                                                value={telNo}
                                                country={'au'}
                                                color="primary"  
                                                placeholder="Tel: No"                                     
                                                onChange={this.updateFormHandler('telNo')} 
                                                // inputClass={`${((touched.telNo && !telNo) || (errorFields.telNo)) && 'error__fields-indicator'}`}                                         
                                                // buttonClass={`${((touched.telNo && !telNo) || (errorFields.telNo)) && 'error__fields-indicator'}`}                                                                                         
                                            />
                                        </div>
                                    </div>
                                    <div className="eb-forms__form-group">
                                        <label htmlFor="mobile">
                                            Cell/Mobile number 
                                            {/* <span className="required__indicator">*</span> */}
                                        </label>
                                        <div className="eb-forms--not-fullwidth">
                                            <PhoneInput
                                                country={'au'}
                                                color="primary"
                                                value={mobileNo}
                                                placeholder="Mobile: No"       
                                                // onBlur={this.handleBlur('mobileNo')}                                       
                                                onChange={this.updateFormHandler('mobileNo')} 
                                                // inputClass={`${((touched.mobileNo && !mobileNo) || (errorFields.mobileNo)) && 'error__fields-indicator'}`}
                                                // buttonClass={`${((touched.mobileNo && !mobileNo) || (errorFields.mobileNo)) && 'error__fields-indicator'}`}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="profile__button-container">
                            <button className="btn btn-gold profile__button profile__button-prev" onClick={this.back}>Previous</button>
                            <button className="btn btn-gold profile__button profile__button-next" onClick={this.saveAndContinue} disabled={errorsInTheForm}>Next</button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = ({ profile, session }) => ({
    userId: session.userId,
    loading: profile.loading,    
    isLoggedIn: session.isLoggedIn,
    formUpdated: profile.supplierContactInfomration.formUpdated
})
  
const mapDispatchToProps = dispatch => ({
    hideNotification: () => dispatch(hideNotification()),
    checkLoginStatus: () => dispatch(checkLoginStatus()),   
    retrieveAllSupplierContactInformation: () => dispatch(retrieveAllSupplierContactInformation()),
    showNotification: (message, notificationType) => dispatch(showNotification(message, notificationType)),             
    supplierProfileContactFormUpdate: (field, value) => dispatch(supplierProfileContactFormUpdate(field, value)),        
    updateTheDisplayNameSessionInAction: displayName => dispatch(updateTheDisplayNameSessionInAction(displayName)),
    postCollectedSupplierContactInformation: postData => dispatch(postCollectedSupplierContactInformation(postData))
})
  
export default connect(mapStateToProps, mapDispatchToProps)(ContactInformation)