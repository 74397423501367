import React, { Component } from 'react'
// import { NavLink } from 'react-router-dom'

import { Submenu } from './Submenu'

export class Menu extends Component {

  state = {
        subMenuOpen: false,
        allEventTypeParameter: {
            "id": 0,
            "event_type_name": "All",
            "event_type_description": "All",
            "subcategories": []
        },
        pages: [
            {
                'name': 'gallery',
                'url': 'gallery',
            },
            { 
                'name': 'find a pro',
                'url': 'find-pro'
            },
            {
                'name': 'hire',
                'url': 'hire'
            },
            {
                'name': 'venues',
                'url': 'venues'
            },  
            {
                'name': 'people',
                'url': 'people'
            }, 
            {
                'name': 'discover',
                'url': 'discover'
            }
        ]
    }

    toggleMenu = e => this.props.toggleMenu()

    toggleSubMenu = e => this.setState({ subMenuOpen : !this.state.subMenuOpen })

    highlightTheMenuLink = (pageLink, submenuStatus) => {
        let classNames = ""
        const currentUrl = window.location.pathname.replace('/', '')
        if ((submenuStatus) && (pageLink === currentUrl)) {
            classNames = "header__menu-list-link header__menu-list-link--has-dropdown active"
        } else if ((submenuStatus) && (pageLink !== currentUrl)) {
            classNames = "header__menu-list-link header__menu-list-link--has-dropdown"
        } else if ((!submenuStatus) && (pageLink === currentUrl)) {
            classNames = "header__menu-list-link active"
        } else if ((!submenuStatus) && (pageLink !== currentUrl)) {
            classNames = "header__menu-list-link"
        }
        return classNames
    }

    render () {

        const { pages } = this.state
        

        const { 
            menu, 
            allEventTypes, 
            isGalleryPageOn,
            currentSelectedEventType,
            filterImagesForSelectedEventType 
        } = this.props

        return (
            <ul className={menu ? "header__menu-list header__menu-list--open" : "header__menu-list"}>
                <li className="header__menu-list-item header__menu-list-item--close-icon">
                    <button className="btn header__menu-close" onClick={this.toggleMenu}>
                        <i className="fa fa-times" aria-hidden="true"></i>
                    </button>          
                </li>
                {pages.map((page, i) => (
                    <li className="header__menu-list-item" key={i}>
                        <a className={this.highlightTheMenuLink(page.url, page.submenu)} key={i} href={'/' + page.url}>{page.name}</a>
                        {/* <NavLink className={page.submenu ? "header__menu-list-link header__menu-list-link--has-dropdown": "header__menu-list-link"} key={i} to={'/' + page.url} activeClassName="active">{page.name}</NavLink> */}
                        {(allEventTypes && page.url == 'gallery') && <Submenu isGalleryPageOn={isGalleryPageOn} currentSelectedEventType={currentSelectedEventType} children={allEventTypes} filterImagesForSelectedEventType={filterImagesForSelectedEventType} />}
                    </li>
                ))}
            </ul>  
        )
    }  
}