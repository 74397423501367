import React, { Component } from 'react'
import { connect } from "react-redux"

import { ProfileHeader } from './ProfileHeader'

class Upload extends Component {

    state = {
        "events": []
    }

    addEvent = _ => this.props.history.push('/add-event')

    skipTheProcess = _ => this.props.history.push('/profile')

    render() {

        const { events } = this.state

        if (!this.props.isLoggedIn) {
            // window.location.href = "/"
        }

        return (
            <div className="profile__my-events">
                <ProfileHeader title="Add Events"></ProfileHeader>
                <div className="profile__section">
                    <div className="profile__event-container">
                        <div className="profile__first-event-container">
                            <h6 className="profile__sub-title">An event is a collection of photos of your work. Events are one of the key ways event organisers can find you on Eventbuzz360</h6>
                            <div className="profile__button-container profile__button-container--reviews">
                                <button className="btn btn-gold profile__button profile__button-next" onClick={this.addEvent}>
                                    {events.length ? "Add another event" : "Add your first event"}
                                </button>
                                <button className="btn btn-white profile__button-skip" onClick={this.skipTheProcess}>Skip for now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ session }) => ({      
    isLoggedIn: session.isLoggedIn
}) 

const mapDispatchToProps = () => {

}

export default connect(mapStateToProps, mapDispatchToProps)(Upload)