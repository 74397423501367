import React, { Component } from 'react'
import { connect } from 'react-redux'

import { openModal, closeModal } from '../../../actions/newLoginActions'

import LoginModalWizard from './LoginModalWizard'
import closeIcon from '../../../assets/images/Close.svg'
import './styles/loginModal.css'
import './styles/activation.css'
import './styles//SignupScreen.css'
import './styles/login.css'
import './styles/LandingScreen.css'

class LoginModal extends Component {
    state = {}

    componentDidMount() {
        // commented for launch. Want this appearing only once just for launch 
        this.timer()

        // this.tempShowLoginModal()
    }

    componentWillUnmount() {
        // use userCheckIntervalId from the state to clear the interval
        clearInterval(this.state.userCheckIntervalId);        
    }

    timer = _ => {     
        // check if user logged in every 5 min
        // var userCheckIntervalId = setInterval(this.showLoginModal, 50000)

        var userCheckIntervalId = setInterval(this.tempShowLoginModal, 900000)
        this.setState({ userCheckIntervalId: userCheckIntervalId }) 
    }

    // showLoginModal = _ => {
    //     const { isLoggedIn, openModal } = this.props

    //     if (isLoggedIn) {   
    //         // if user logged in, clear timer  
    //         clearInterval(this.state.userCheckIntervalId)
    //     } else {
    //         // if user not logged in, show modal box after 2s
    //         setTimeout(() => {
    //             clearInterval(this.state.userCheckIntervalId)
    //             openModal()
    //             document.querySelector('body').classList.add('modal-open')
    //         }, 2000);
    //     }
    // }

    tempShowLoginModal = _ => {
        const { isLoggedIn, openModal } = this.props

        // show login pop 15min after browsing the site JUST ONCE
        if (isLoggedIn) {
            // if user logged in, clear timer  
            clearInterval(this.state.userCheckIntervalId)
        } else {
            setTimeout(() => {
                clearInterval(this.state.userCheckIntervalId)
                openModal()
                document.querySelector('body').classList.add('modal-open')
            }, 900000)
        }
    }

    closeLoginModal = _ => {
        this.props.closeModal()
        document.querySelector('body').classList.remove('modal-open')
        // this.timer()
    }

    render () {
        const { modalOpen, count } = this.props

        return (
            <div className={modalOpen ? "login-modal login-modal--open" : "login-modal"}>
                <div className="login-modal__container">
                    <header className="login-modal__header">
                        <h4 className="login-modal__title landing-page__middle-title">Join the buzz</h4>
                        <button className="login-modal__close-btn btn" 
                                onClick={() => this.closeLoginModal()}
                                disabled={count > 3 ? true : false}>
                            <img className="login-modal__close-icon" src={closeIcon} alt="close login popup" />
                        </button>
                    </header>
                    <div className="login-modal__content-container">
                        <LoginModalWizard />
                    </div>
                </div>
            </div>
        )
    }  
}

const mapStateToProps = ({ newLogin, session }) => ({ 
    modalOpen: newLogin.modalOpen,
    count: newLogin.count,
    isLoggedIn: session.isLoggedIn
}) 

const mapDispatchToProps = dispatch => ({
    openModal: () => dispatch(openModal()),
    closeModal: () => dispatch(closeModal()),
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal)