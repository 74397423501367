import React, { Component } from 'react'
import PasswordMask from 'react-password-mask'
import { connect } from 'react-redux'

import { registerFormUpdate, sendSupplierRegisterForm, checkUsernameExists } from '../../../actions/registerActions'
import { hideNotification, showNotification } from '../../../actions/notificationActions'
import { authLoginWithoutPasswordAfterActivation } from '../../../actions/sessionActions'

class SignUp extends Component {

    state = {
        user: {
            firstName: '',
            lastName: '',
            company: '',
            emailAddress: '',
            password: '',
            userType: ''
        },
        touched: {
            firstName: false,
            lastName: false,
            emailAddress: false,
            password: false
        },
        errorFields: {
            firstName: false,
            lastName: false,
            emailAddress: false,
            password: false
        }
    }

    componentDidMount() {
        this.setState({ ...this.state, user: {...this.state.user, userType: this.props.newUserType} }) 
        this.props.updateForm('userType', this.props.newUserType)
    }

    handleBlur = field => this.setState({ ...this.state, touched: { ...this.state.touched, [field]: true } })

    updateFormHandler = (field, value) => {
        this.setState({ ...this.state, user: {...this.state.user, [field]: value } }) 
        if (field === 'emailAddress') {
            this.props.checkUsernameExists(value).then(data => {
                if (data) {
                    this.setState({ emailAddressExists: true })
                    this.props.showNotification('Email address exists!', 'error')

                    setTimeout(() => this.props.hideNotification(), 6000)  
                } else {
                    this.setState({ emailAddressExists: false })
                }
            })
        }        
        this.props.updateForm(field, value)
    }

    formSubmitHandler = e => {
        e.preventDefault()
        const { emailAddressExists } = this.state
        const { firstName, lastName, emailAddress, password, userType } = this.props

        if ((password) && (emailAddress) && (firstName) && (lastName)  && (userType) && (!emailAddressExists)) { 
            this.props.supplierRegister(userType).then(data => { 
                if (data.status === "user_created_mail_success") {
                    // this.props.showNotification('Please check your mail for account activation!', 'success')
                    // this.props.showNotification('Please login in order to proceed!', 'success')
                    // The above line needs to be changed later
                    this.props.authLoginWithoutPasswordAfterActivation(emailAddress).then(data => {
                        if (data.status === "user_credentials_ok") {                                            
                            window.location.href = '/profile'
                            // this.props.history.push('profile')
                        } else {
                            this.props.showNotification('Something went wrong! Please contact support...', 'error')
                            setTimeout(() => this.props.hideNotification(), 6000)  
                        }
                    })    
                }                
            })
        } 
    }

    render () {

        const { user, touched, errorFields, emailAddressExists } = this.state

        return (
            <>
                <h5 className="login__title">Sign Up</h5>
                <div className="login__form-container">
                    <form className="eb-forms" onSubmit={this.formSubmitHandler}>
                        <div className="eb-forms__form-group eb-forms__form-group--signup">
                            <input 
                                type="text" 
                                id="firstname" 
                                value={user.firstName}
                                placeholder="First Name"
                                onBlur={() => this.handleBlur('firstName')}
                                onChange={e => this.updateFormHandler('firstName', e.target.value)} 
                            />                                    
                            {((touched.firstName && !user.firstName) || (errorFields.firstName)) && <span className="error">Please Enter a First Name</span>}                        
                        </div>
                        <div className="eb-forms__form-group eb-forms__form-group--signup">
                            <input 
                                type="text" 
                                id="lastname" 
                                placeholder="Last Name"
                                value={user.lastName}
                                onBlur={() => this.handleBlur('lastName')}
                                onChange={e => this.updateFormHandler('lastName', e.target.value)} 
                            />                                    
                            {((touched.lastName && !user.lastName) || (errorFields.lastName)) && <span className="error">Please Enter a valid Last Name</span>}                        
                        </div>                                        
                        <div className="eb-forms__form-group eb-forms__form-group--signup">
                            <input 
                                type="text" 
                                id="company" 
                                placeholder="Company Name (optional)"
                                value={user.company}
                                onBlur={() => this.handleBlur('company')}
                                onChange={e => this.updateFormHandler('company', e.target.value)} 
                            />                                  
                        </div>
                        <div className="eb-forms__form-group eb-forms__form-group--signup">
                            <input 
                                type="text" 
                                id="email" 
                                value={user.emailAddress}
                                placeholder="Enter email"
                                onBlur={() => this.handleBlur('emailAddress')}
                                onChange={e => this.updateFormHandler('emailAddress', e.target.value)} 
                            />                                    
                            { ((touched.emailAddress && !user.emailAddress) || (errorFields.emailAddress)) && <span className="error">Please Enter a valid Email Address</span> }
                            { emailAddressExists && <span className="error">Email address exists. If you have forgotten your password, please reset it</span> }
                        </div>
                        <div className="eb-forms__form-group eb-forms__form-group--signup">
                            <PasswordMask
                                id="password"
                                name="password"  
                                value={user.password}                                              
                                className="passwordMask"
                                placeholder="Enter password"    
                                onBlur={() => this.handleBlur('password')}                                    
                                onChange={e => this.updateFormHandler('password', e.target.value)} 
                            />
                            <span className="signup__password-hint">Use 8 or more characters with a mix of letters, numbers and symbols</span>
                            {((touched.password && !user.password) || (errorFields.password)) && <span className="error">Please Enter a valid Password</span>}                        
                        </div>
                        <button type="submit" className="btn btn-gold signup__button" disabled={(user.firstName === "" || user.lastName === "" || user.emailAddress === "" || user.password === "" || emailAddressExists)}>Sign Up</button>
                    </form>     
                </div>    
                <footer className="signup-form__footer">
                    { emailAddressExists && <p className="login-form__footer-copy">Forget your password? <a onClick={() => this.props.resetPassword()}>Reset password</a></p> }
                    <span className="signup__password-hint">By signing up, signing in or continuing, I agree to EventBuzz360's <a target="_blank" href="/terms-of-use">Terms of Use</a> and <a target="_blank" href="/privacy-policy">Privacy Policy</a></span>
                </footer> 
            </>
        )
    }  
}

const mapStateToProps = ({ register, newLogin }) => ({
    loading: register.loading,    
    company: register.company,
    userType: register.userType,    
    password: register.password,    
    lastName: register.lastName,
    firstName: register.firstName,
    newUserType: newLogin.newUserType,
    emailAddress: register.emailAddress,
})
  
const mapDispatchToProps = dispatch => ({
    hideNotification: () => dispatch(hideNotification()),        
    checkUsernameExists: email => dispatch(checkUsernameExists(email)),
    supplierRegister: userType => dispatch(sendSupplierRegisterForm(userType)),    
    updateForm: (field, value) => dispatch(registerFormUpdate({ field, value })),        
    showNotification: (message, notificationType) => dispatch(showNotification(message, notificationType)),
    authLoginWithoutPasswordAfterActivation: email => dispatch(authLoginWithoutPasswordAfterActivation(email)), // This code to be removed later
})

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)