import jwt_decode from 'jwt-decode'
import { 
    getAuthToken,
    PasswordReset,
    authLoginToken,
    authLoginTokenWithoutPassword   
} from "../services/authServices"

export const SESSION_END = "SESSION_END"
export const LOGIN_FORM_UPDATE = "LOGIN_FORM_UPDATE"
export const SESSION_INITIALISE = "SESSION_INITIALISE"
export const SEND_LOGIN_FORM_REQUEST = "SEND_LOGIN_FORM_REQUEST"
export const SEND_REVOKE_TOKEN_REQUEST = "SEND_REVOKE_TOKEN_REQUEST"
export const UPDATE_DISPLAY_NAME_ACTION = "UPDATE_DISPLAY_NAME_ACTION"
export const SEND_LOGIN_FORM_REQUEST_SUCCESS = "SEND_LOGIN_FORM_REQUEST_SUCCESS"
export const SEND_LOGIN_FORM_REQUEST_FAILURE = "SEND_LOGIN_FORM_REQUEST_FAILURE"
export const SEND_REVOKE_TOKEN_REQUEST_SUCCESS = "SEND_REVOKE_TOKEN_REQUEST_SUCCESS"
export const SEND_REVOKE_TOKEN_REQUEST_FAILURE = "SEND_REVOKE_TOKEN_REQUEST_FAILURE"
export const SUBMIT_EMAIL_FOR_PASSWORD_RESET_REQUEST = "SUBMIT_EMAIL_FOR_PASSWORD_RESET_REQUEST"
export const SUBMIT_EMAIL_FOR_PASSWORD_RESET_REQUEST_SUCCESS = "SUBMIT_EMAIL_FOR_PASSWORD_RESET_REQUEST_SUCCESS"
export const SUBMIT_EMAIL_FOR_PASSWORD_RESET_REQUEST_FAILURE = "SUBMIT_EMAIL_FOR_PASSWORD_RESET_REQUEST_FAILURE"

const sendAuthLoginReuqest = () => ({
    type: SEND_LOGIN_FORM_REQUEST
})

const sendAuthLoginReuqestSuccess = () => ({
    type: SEND_LOGIN_FORM_REQUEST_SUCCESS
})

const sendAuthLoginReuqestFailure = () => ({
    type: SEND_LOGIN_FORM_REQUEST_FAILURE
})

const sendRevokeTokenRequest = () => ({
    type: SEND_REVOKE_TOKEN_REQUEST
})

const sendRevokeTokenRequestSuccess = () => ({
    type: SEND_REVOKE_TOKEN_REQUEST_SUCCESS
})

const sendRevokeTokenRequestFailure = () => ({
    type: SEND_REVOKE_TOKEN_REQUEST_FAILURE
})

const submitEmailForPasswordRestRequest = () => ({
    type: SUBMIT_EMAIL_FOR_PASSWORD_RESET_REQUEST
})

const submitEmailForPasswordRestRequestSuccess = () => ({
    type: SUBMIT_EMAIL_FOR_PASSWORD_RESET_REQUEST_SUCCESS
})

const submitEmailForPasswordRestRequestFailure = () => ({
    type: SUBMIT_EMAIL_FOR_PASSWORD_RESET_REQUEST_FAILURE
})

export const sessionInitilize = (accessToken, displayName, emailAddress) => ({
    type: SESSION_INITIALISE,
    accessToken,
    displayName,
    emailAddress
})

export const sessionEnd = () => ({
    type: SESSION_END
})

export const loginFormUpdate = ({ field, value }) => ({
    type: LOGIN_FORM_UPDATE,
    field,
    value
})

export const authLogin = () => (dispatch, getState) => {
    const { username, password } = getState().session
    dispatch(sendAuthLoginReuqest())
    return getAuthToken(username).then(tokenData => {        
        return authLoginToken(username, password, tokenData.token)
            .then(data => {
                dispatch(sendAuthLoginReuqestSuccess())
                return data
            })
            .then(data => {  
                const accessToken = tokenData.token
                const accessTokenData = jwt_decode(accessToken)  
                localStorage.setItem('accessToken', accessToken)    
                localStorage.setItem('expiresIn', accessTokenData.exp)   
                localStorage.setItem('displayName', data.display_name)    
                dispatch(sessionInitilize(accessToken, data.display_name, accessTokenData.sub))
                return data
            })
            .catch(error => {
                dispatch(sendAuthLoginReuqestFailure())
                return error
            })
    })
    .catch(error => error)
}

export const authLoginWithoutPasswordAfterActivation = username => (dispatch, getState) => {
    dispatch(sendAuthLoginReuqest())
    return getAuthToken(username).then(data => {        
        return authLoginTokenWithoutPassword(username, data.token)
            .then(data => {
                dispatch(sendAuthLoginReuqestSuccess())
                return data
            })
            .then(data => {  
                const accessToken = data.result.token
                const accessTokenData = jwt_decode(accessToken)  
                localStorage.setItem('accessToken', accessToken)
                const displayName = data.result.user.displayName
                localStorage.setItem('displayName', displayName)                
                dispatch(sessionInitilize(accessToken, displayName, accessTokenData.sub))
                return data
            })
            .catch(error => {
                dispatch(sendAuthLoginReuqestFailure())
                return error
            })
    })
    .catch(error => error)
}

export const checkLoginStatus = () => dispatch => {
    dispatch(sendRevokeTokenRequest())
    const accessToken = localStorage.getItem('accessToken')
    const displayName = localStorage.getItem('displayName')
    const authObject = {
        accessToken,
        displayName
    }              
    if (accessToken) {
        const accessTokenData = jwt_decode(accessToken)                
        if (!Date.now() >= accessTokenData.exp * 1000) {
            dispatch(sessionInitilize(accessToken, displayName, accessTokenData.sub))
            return authObject
        } else {
            dispatch(sessionInitilize(accessToken, displayName, accessTokenData.sub))
            return new Promise((resolve, reject) => resolve(authObject))
        }    
    } else {
        return new Promise((resolve, reject) => reject(null))
    }    
}

export const clearLocalStorage = () => () => {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('expiresIn')
    localStorage.removeItem('displayName')
}

export const submitEmailForPasswordRest = email => dispatch => {
    dispatch(submitEmailForPasswordRestRequest())
    return PasswordReset(email).then(data => {
        dispatch(submitEmailForPasswordRestRequestSuccess())
        return data
    })
    .catch(error => {
        dispatch(submitEmailForPasswordRestRequestFailure())
        return error
    })
}

export const updateTheDisplayNameSessionInAction = displayName => ({
    type: UPDATE_DISPLAY_NAME_ACTION,
    displayName
})