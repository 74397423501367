import React, { Component } from 'react'

export class UserType extends Component {

    onUserTypeSelect = (input, section) => this.props.newFilteredUserType(input, section)

    nextStep = _ => this.props.nextStep()

    render () {

        const { userTypes, supplier, jobs } = this.props

        return (
            <div className="login-modal-user-types user-type">
                <div className="user-type__section">
                    <header className="landing-page__middle-header">
                        <p className="landing-page__middle-subtext">Tell us who you are</p>
                    </header>
                    <fieldset className="fieldset fieldset--inverse">
                        <legend className="legend legend--user-type">
                            <button className="btn new-login__legend" 
                                    onClick={() => this.nextStep()}
                                    disabled={supplier ? false : true} >Join the buzz</button>
                            <span className="legend-light">Its free</span>
                        </legend>
                        <div className="landing-page__user-type-container eb-forms">
                        {
                            userTypes.supplier.map(userType => (
                                <div key={userType.index} 
                                    className={userType.index === 3 ? "landing-page__user-type eb-radio eb-radio--disabled" : "landing-page__user-type eb-radio"}>
                                    <input 
                                        type='radio'
                                        name="userType" 
                                        id={userType.index}                                    
                                        onChange={() => this.onUserTypeSelect(userType.index, 'supplier')}                                      
                                        disabled={userType.index === 3}                           
                                    />
                                    <span className="checkmark"></span>
                                    <label htmlFor={userType.index}>{userType.text}</label>
                                </div>
                            ))
                        }
                        </div>
                    </fieldset>
                </div>
                {/*<div className="user-type__section">*/}
                {/*    <header className="landing-page__middle-header">*/}
                {/*        <p className="landing-page__middle-subtext">Match to Jobs</p>*/}
                {/*    </header>*/}
                {/*    <fieldset className="fieldset fieldset--inverse">*/}
                {/*        <legend className="legend legend--user-type">*/}
                {/*            <button className="btn new-login__legend" */}
                {/*                    onClick={() => this.nextStep()}*/}
                {/*                    disabled={jobs ? false : true} >Join the buzz</button>*/}
                {/*            <span className="legend-light">Its free</span>*/}
                {/*        </legend>*/}
                {/*        <div className="landing-page__user-type-container eb-forms">*/}
                {/*        {*/}
                {/*            userTypes.jobs.map(userType => (*/}
                {/*                <div key={userType.index} className="landing-page__user-type eb-radio">*/}
                {/*                    <input */}
                {/*                        type='radio'*/}
                {/*                        name="userType" */}
                {/*                        id={userType.index}                                    */}
                {/*                        onChange={() => this.onUserTypeSelect(userType.index, 'jobs')}                         */}
                {/*                    />*/}
                {/*                    <span className="checkmark"></span>*/}
                {/*                    <label htmlFor={userType.index}>{userType.text}</label>*/}
                {/*                </div>*/}
                {/*            ))*/}
                {/*        }*/}
                {/*        </div>*/}
                {/*    </fieldset>*/}
                {/*</div>*/}
            </div>
        )
    }  
}