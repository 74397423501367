import * as actions from "../actions/generalActions"

const initialState = {
    loading: false,
    formUpdated: false,
    supplierEmailMessage: ""
}

export const generalReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SEND_SUBMIT_CONTACT_DATA_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actions.SEND_SUBMIT_CONTACT_DATA_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actions.SEND_SUBMIT_CONTACT_DATA_REQUEST_FAILURE:
            return {
                ...state,
                loading: false
            }
        case actions.LOAD_RELATED_SUPPLIER_FOR_FIND_PRO_HIRE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actions.LOAD_RELATED_SUPPLIER_FOR_FIND_PRO_HIRE_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actions.LOAD_RELATED_SUPPLIER_FOR_FIND_PRO_HIRE_REQUEST_FAILURE:
            return {
                ...state,
                loading: false
            }                      
        case actions.CONNECT_OTHER_SUPPLIER_BY_EMAIL_REQUEST:
            return {
                ...state,
                loading: true
            }
        case actions.CONNECT_OTHER_SUPPLIER_BY_EMAIL_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case actions.CONNECT_OTHER_SUPPLIER_BY_EMAIL_REQUEST_FAILURE:
            return {
                ...state,
                loading: false
            }
        case actions.SUPPLIER_CONTACT_FORM_UPDATE:
            return {
                ...state,
                [action.field]: action.value,
                formUpdated: true
            }              
        default:
            return state
    }
}