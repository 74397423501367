import _ from 'underscore'

import { 
    loadSubTypes,
    supplierAddEvent,
    fetchAllSuppliers,
    supplierAddReview,
    supplierUpdateEvent,
    removeTheCurrentEvent,
    removeTheSelectedImage,
    listAllSupplierReviews,
    supplierAddEventPhotos,
    loadSupplierParentTypes,  
    fetchSupplierEventPhotos,
    removeSelectedSubService,
    removeSelectedMainService,
    fetchMatchingSupplierNames,
    fetchAllConnectedSuppliers,
    supplierUpdateEventNewPhotos,
    fetchAllSupplierEventKeywords,
    fetchSupplierEventInformation,
    postSupplierSocialInformation,
    supplierProfileChangePassword,
    removeSelectedOtherSubService,
    removeMainServiceAreasService,
    postSupplierServiceInformation,
    postSupplierContactInformation,
    postSupplierLocationInformation,
    postSupplierBusinessInformation,
    fetchAllSupplierEventAllKeywords,
    fetchAllReviewsForSupplierProfile,
    retrieveSupplierSocialInformation,
    removeAddionalServiceAreasService,
    retrieveSupplierContactInformation,    
    loadSupplierTypesWithSubCategories,
    listAllMainServiceForCurrentProfile,
    retrieveSupplierBusinessInformation,   
    listAllSubServicesForCurrentProfile,
    retrievalProfileSupplierImageFolders,
    fetchSupplierProfileBasicInformation,
    listAllOtherSubServicesForCurrentProfile,
    listAllSubServicesForCurrentPublicProfile,
    updateTheOrderForTheExistingImagesProfile,
    retrievalProfileSupplierImageFoldersPhotos,
    loadMainServicesInformationForPublicProfile,
    fetchAllSupplierEventAllKeywordsNoException,
    fetchSupplierProfileLocationInformationOthers,
    listAllOtherSubServicesForCurrentPublicProfile,
    fetchSupplierProfileLocationInformationCountries   
} from "../services/mediaServices"

export const SUPPLIER_REVIEW_ADD_REQUEST = "SUPPLIER_REVIEW_ADD_REQUEST"
export const SUPPLIER_LIST_REVIEW_REQUEST = "SUPPLIER_LIST_REVIEW_REQUEST"
export const SUPPLIER_REVIEW_FORM_UPDATED = "SUPPLIER_REVIEW_FORM_UPDATED"
export const REMOVE_CURRENT_EVENT_REQUEST = "REMOVE_CURRENT_EVENT_REQUEST"
export const REMOVE_SELECTED_IMAGE_REQUEST = "REMOVE_SELECTED_IMAGE_REQUEST"
export const SEND_SUPPLIER_ADD_EVENT_REQUEST = "SEND_SUPPLIER_ADD_EVENT_REQUEST"
export const SEND_SUPPLIER_UPDATE_EVENT_REQUEST = "SEND_SUPPLIER_UPDATE_EVENT_REQUEST"
export const REMOVE_SELECTED_SUB_SERVICE_REQUEST = "REMOVE_SELECTED_SUB_SERVICE_REQUEST"
export const SUPPLIER_REVIEW_ADD_REQUEST_FAILURE = "SUPPLIER_REVIEW_ADD_REQUEST_FAILURE"
export const SUPPLIER_REVIEW_ADD_REQUEST_SUCCESS = "SUPPLIER_REVIEW_ADD_REQUEST_SUCCESS"
export const REMOVE_ADDIONAL_SERVICE_AREA_REQUEST = "REMOVE_ADDIONAL_SERVICE_AREA_REQUEST"
export const SUPPLIER_LIST_REVIEW_REQUEST_SUCCESS = "SUPPLIER_LIST_REVIEW_REQUEST_SUCCESS"
export const REMOVE_CURRENT_EVENT_REQUEST_SUCCESS = "REMOVE_CURRENT_EVENT_REQUEST_SUCCESS"
export const REMOVE_CURRENT_EVENT_REQUEST_FAILURE = "REMOVE_CURRENT_EVENT_REQUEST_FAILURE"
export const SUPPLIER_LIST_REVIEW_REQUEST_FAILURE = "SUPPLIER_LIST_REVIEW_REQUEST_FAILURE"
export const SUPPLIER_PROFILE_FORM_GENERIC_UPDATE = "SUPPLIER_PROFILE_FORM_GENERIC_UPDATE"
export const REMOVE_SELECTED_IMAGE_REQUEST_SUCCESS = "REMOVE_SELECTED_IMAGE_REQUEST_SUCCESS"
export const REMOVE_SELECTED_IMAGE_REQUEST_FAILURE = "REMOVE_SELECTED_IMAGE_REQUEST_FAILURE"
export const SEND_REQUEST_LOAD_SUPPLIER_CATEGORIES = "SEND_REQUEST_LOAD_SUPPLIER_CATEGORIES"
export const SEND_SUPPLIER_ADD_EVENT_REQUEST_SUCCESS = "SEND_SUPPLIER_ADD_EVENT_REQUEST_SUCCESS"
export const SEND_SUPPLIER_ADD_EVENT_REQUEST_FAILURE = "SEND_SUPPLIER_ADD_EVENT_REQUEST_FAILURE"
export const LIST_ALL_SUB_SERVICES_FOR_PROFILE_REQUEST = "LIST_ALL_SUB_SERVICES_FOR_PROFILE_REQUEST"
export const SEND_REQUEST_LOAD_SUPPLIER_CATEGORIES_ALL = "SEND_REQUEST_LOAD_SUPPLIER_CATEGORIES_ALL"
export const SEND_SUPPLIER_UPDATE_EVENT_REQUEST_SUCCESS = "SEND_SUPPLIER_UPDATE_EVENT_REQUEST_SUCCESS"
export const HANDLE_SUPPLIER_PROFILE_PIC_UPLOAD_REQUEST = "HANDLE_SUPPLIER_PROFILE_PIC_UPLOAD_REQUEST"
export const SEND_SUPPLIER_UPDATE_EVENT_REQUEST_FAILURE = "SEND_SUPPLIER_UPDATE_EVENT_REQUEST_FAILURE"
export const SEND_PROCESS_SUPPLIER_PHOTOS_UPLOAD_REQUEST = "SEND_PROCESS_SUPPLIER_PHOTOS_UPLOAD_REQUEST"
export const REMOVE_SELECTED_SUB_SERVICE_REQUEST_SUCCESS = "REMOVE_SELECTED_SUB_SERVICE_REQUEST_SUCCESS"
export const REMOVE_SELECTED_SUB_SERVICE_REQUEST_FAILURE = "REMOVE_SELECTED_SUB_SERVICE_REQUEST_FAILURE"
export const REMOVE_ADDIONAL_SERVICE_AREA_REQUEST_SUCCESS = "REMOVE_ADDIONAL_SERVICE_AREA_REQUEST_SUCCESS"
export const REMOVE_ADDIONAL_SERVICE_AREA_REQUEST_FAILURE = "REMOVE_ADDIONAL_SERVICE_AREA_REQUEST_FAILURE"
export const SEND_SUPPLIER_PROFILE_CHANGE_PASSWORD_REQUEST = "SEND_SUPPLIER_PROFILE_CHANGE_PASSWORD_REQUEST"
export const SEND_FETCH_SUPPLIER_EVENT_INFORMATION_REQUEST = "SEND_FETCH_SUPPLIER_EVENT_INFORMATION_REQUEST"
export const SEND_REQUEST_LOAD_SUPPLIER_CATEGORIES_SUCCESS = "SEND_REQUEST_LOAD_SUPPLIER_CATEGORIES_SUCCESS"
export const SEND_REQUEST_LOAD_SUPPLIER_CATEGORIES_FAILURE = "SEND_REQUEST_LOAD_SUPPLIER_CATEGORIES_FAILURE"
export const SUPPLIER_PROFILE_EVENT_INFORMATION_FORM_UPDATE = "SUPPLIER_PROFILE_EVENT_INFORMATION_FORM_UPDATE"
export const SUPPLIER_PROFILE_SOCIAL_INFORMATION_FORM_UPDATE = "SUPPLIER_PROFILE_SOCIAL_INFORMATION_FORM_UPDATE"
export const SEND_POST_SUPPLIER_LOCATION_INFORMATION_REQUEST = "SEND_POST_SUPPLIER_LOCATION_INFORMATION_REQUEST"
export const SUPPLIER_PROFILE_CONTACT_INFORMATION_FORM_UPDATE = "SUPPLIER_PROFILE_CONTACT_INFORMATION_FORM_UPDATE"
export const SEND_REQUEST_LOAD_SUPPLIER_CATEGORIES_PER_PARENT = "SEND_REQUEST_LOAD_SUPPLIER_CATEGORIES_PER_PARENT"
export const SUPPLIER_FETCH_BASIC_PROFILE_INFORMATION_REQUEST = "SUPPLIER_FETCH_BASIC_PROFILE_INFORMATION_REQUEST"
export const SUPPLIER_FETCH_BASIC_PROFILE_INFORMATION_SUCCESS = "SUPPLIER_FETCH_BASIC_PROFILE_INFORMATION_SUCCESS"
export const SUPPLIER_FETCH_BASIC_PROFILE_INFORMATION_FAILURE = "SUPPLIER_FETCH_BASIC_PROFILE_INFORMATION_FAILURE"
export const LIST_ALL_SUB_SERVICES_FOR_PROFILE_REQUEST_SUCCESS = "LIST_ALL_SUB_SERVICES_FOR_PROFILE_REQUEST_SUCCESS"
export const LIST_ALL_SUB_SERVICES_FOR_PROFILE_REQUEST_FAILURE = "LIST_ALL_SUB_SERVICES_FOR_PROFILE_REQUEST_FAILURE"
export const SUPPLIER_PROFILE_BUSINESS_INFORMATION_FORM_UPDATE = "SUPPLIER_PROFILE_BUSINESS_INFORMATION_FORM_UPDATE"
export const SEND_REQUEST_LOAD_SUPPLIER_CATEGORIES_ALL_SUCCESS = "SEND_REQUEST_LOAD_SUPPLIER_CATEGORIES_ALL_SUCCESS"
export const SEND_REQUEST_LOAD_SUPPLIER_CATEGORIES_ALL_FAILURE = "SEND_REQUEST_LOAD_SUPPLIER_CATEGORIES_ALL_FAILURE"
export const SEND_RETRIEVE_SUPPLIER_SOCIAL_INFORMATION_REQUEST = "SEND_RETRIEVE_SUPPLIER_SOCIAL_INFORMATION_REQUEST"
export const SUPPLIER_PROFILE_SEERVICE_INFORMATION_FORM_UPDATE = "SUPPLIER_PROFILE_SEERVICE_INFORMATION_FORM_UPDATE"
export const LIST_ALL_MAIN_SERVICES_FOR_PROFILE_REQUEST_SUCCESS = "LIST_ALL_MAIN_SERVICES_FOR_PROFILE_REQUEST_SUCCESS"
export const LIST_ALL_MAIN_SERVICES_FOR_PROFILE_REQUEST_FAILURE = "LIST_ALL_MAIN_SERVICES_FOR_PROFILE_REQUEST_FAILURE"
export const SEND_RETRIEVE_SUPPLIER_CONTACT_INFORMATION_REQUEST = "SEND_RETRIEVE_SUPPLIER_CONTACT_INFORMATION_REQUEST"
export const SEND_PROCESS_SUPPLIER_PHOTOS_UPLOAD_REQUEST_SUCCESS = "SEND_PROCESS_SUPPLIER_PHOTOS_UPLOAD_REQUEST_SUCCESS"
export const SEND_PROCESS_SUPPLIER_PHOTOS_UPLOAD_REQUEST_FAILURE = "SEND_PROCESS_SUPPLIER_PHOTOS_UPLOAD_REQUEST_FAILURE"
export const SEND_RETRIEVE_SUPPLIER_BUSINESS_INFORMATION_REQUEST = "SEND_RETRIEVE_SUPPLIER_BUSINESS_INFORMATION_REQUEST"
export const SEND_PROFILE_SUPPLIER_IMAGE_FOLDERS_RETRIVAL_REQUEST = "SEND_PROFILE_SUPPLIER_IMAGE_FOLDERS_RETRIVAL_REQUEST"
export const SEND_SUPPLIER_PROFILE_CHANGE_PASSWORD_REQUEST_SUCCESS = "SEND_SUPPLIER_PROFILE_CHANGE_PASSWORD_REQUEST_SUCCESS"
export const SEND_SUPPLIER_PROFILE_CHANGE_PASSWORD_REQUEST_FAILURE = "SEND_SUPPLIER_PROFILE_CHANGE_PASSWORD_REQUEST_FAILURE"
export const SEND_FETCH_SUPPLIER_EVENT_INFORMATION_REQUEST_SUCCESS = "SEND_FETCH_SUPPLIER_EVENT_INFORMATION_REQUEST_SUCCESS"
export const SEND_FETCH_SUPPLIER_EVENT_INFORMATION_REQUEST_FAILURE = "SEND_FETCH_SUPPLIER_EVENT_INFORMATION_REQUEST_FAILURE"
export const SEND_POST_SUPPLIER_LOCATION_INFORMATION_REQUEST_SUCCESS = "SEND_POST_SUPPLIER_LOCATION_INFORMATION_REQUEST_SUCCESS"
export const SEND_POST_SUPPLIER_LOCATION_INFORMATION_REQUEST_FAILURE = "SEND_POST_SUPPLIER_LOCATION_INFORMATION_REQUEST_FAILURE"
export const SEND_POST_COLLECTED_SUPPLIER_SOCIAL_INFORMATION_REQUEST = "SEND_POST_COLLECTED_SUPPLIER_SOCIAL_INFORMATION_REQUEST"
export const SEND_POST_COLLECTED_SUPPLIER_CONTACT_INFORMATION_REQUEST = "SEND_POST_COLLECTED_SUPPLIER_CONTACT_INFORMATION_REQUEST"
export const SEND_POST_COLLECTED_SUPPLIER_SERVICE_INFORMATION_REQUEST = "SEND_POST_COLLECTED_SUPPLIER_SERVICE_INFORMATION_REQUEST"
export const SEND_REQUEST_LOAD_SUPPLIER_CATEGORIES_PER_PARENT_SUCCESS = "SEND_REQUEST_LOAD_SUPPLIER_CATEGORIES_PER_PARENT_SUCCESS"
export const SEND_REQUEST_LOAD_SUPPLIER_CATEGORIES_PER_PARENT_FAILURE = "SEND_REQUEST_LOAD_SUPPLIER_CATEGORIES_PER_PARENT_FAILURE"
export const SEND_RETRIEVE_SUPPLIER_SOCIAL_INFORMATION_REQUEST_SUCCESS = "SEND_RETRIEVE_SUPPLIER_SOCIAL_INFORMATION_REQUEST_SUCCESS"
export const SEND_RETRIEVE_SUPPLIER_SOCIAL_INFORMATION_REQUEST_FAILURE = "SEND_RETRIEVE_SUPPLIER_SOCIAL_INFORMATION_REQUEST_FAILURE"
export const SEND_POST_COLLECTED_SUPPLIER_BUSINESS_INFORMATION_REQUEST = "SEND_POST_COLLECTED_SUPPLIER_BUSINESS_INFORMATION_REQUEST"
export const SEND_RETRIEVE_SUPPLIER_CONTACT_INFORMATION_REQUEST_SUCCESS = "SEND_RETRIEVE_SUPPLIER_CONTACT_INFORMATION_REQUEST_SUCCESS"
export const SEND_RETRIEVE_SUPPLIER_CONTACT_INFORMATION_REQUEST_FAILURE = "SEND_RETRIEVE_SUPPLIER_CONTACT_INFORMATION_REQUEST_FAILURE"
export const SEND_RETRIEVE_SUPPLIER_BUSINESS_INFORMATION_REQUEST_SUCCESS = "SEND_RETRIEVE_SUPPLIER_BUSINESS_INFORMATION_REQUEST_SUCCESS"
export const SEND_RETRIEVE_SUPPLIER_BUSINESS_INFORMATION_REQUEST_FAILURE = "SEND_RETRIEVE_SUPPLIER_BUSINESS_INFORMATION_REQUEST_FAILURE"
export const SEND_PROFILE_SUPPLIER_IMAGE_FOLDERS_RETRIVAL_REQUEST_SUCCESS = "SEND_PROFILE_SUPPLIER_IMAGE_FOLDERS_RETRIVAL_REQUEST_SUCCESS"
export const SEND_PROFILE_SUPPLIER_IMAGE_FOLDERS_RETRIVAL_REQUEST_FAILURE = "SEND_PROFILE_SUPPLIER_IMAGE_FOLDERS_RETRIVAL_REQUEST_FAILURE"
export const SEND_RETRIEVAL_PROFILE_SUPPLIER_IMAGE_FOLDERS_PHOTOS_REQUEST = "SEND_RETRIEVAL_PROFILE_SUPPLIER_IMAGE_FOLDERS_PHOTOS_REQUEST"
export const SEND_POST_COLLECTED_SUPPLIER_SOCIAL_INFORMATION_REQUEST_SUCCESS = "SEND_POST_COLLECTED_SUPPLIER_SOCIAL_INFORMATION_REQUEST_SUCCESS"
export const SEND_POST_COLLECTED_SUPPLIER_SOCIAL_INFORMATION_REQUEST_FAILURE = "SEND_POST_COLLECTED_SUPPLIER_SOCIAL_INFORMATION_REQUEST_FAILURE"
export const SEND_POST_COLLECTED_SUPPLIER_CONTACT_INFORMATION_REQUEST_SUCCESS = "SEND_POST_COLLECTED_SUPPLIER_CONTACT_INFORMATION_REQUEST_SUCCESS"
export const SEND_POST_COLLECTED_SUPPLIER_CONTACT_INFORMATION_REQUEST_FAILURE = "SEND_POST_COLLECTED_SUPPLIER_CONTACT_INFORMATION_REQUEST_FAILURE"
export const SEND_POST_COLLECTED_SUPPLIER_SERVICE_INFORMATION_REQUEST_SUCCESS = "SEND_POST_COLLECTED_SUPPLIER_SERVICE_INFORMATION_REQUEST_SUCCESS"
export const SEND_POST_COLLECTED_SUPPLIER_SERVICE_INFORMATION_REQUEST_FAILURE = "SEND_POST_COLLECTED_SUPPLIER_SERVICE_INFORMATION_REQUEST_FAILURE"
export const SEND_POST_COLLECTED_SUPPLIER_BUSINESS_INFORMATION_REQUEST_SUCCESS = "SEND_POST_COLLECTED_SUPPLIER_BUSINESS_INFORMATION_REQUEST_SUCCESS"
export const SEND_POST_COLLECTED_SUPPLIER_BUSINESS_INFORMATION_REQUEST_FAILURE = "SEND_POST_COLLECTED_SUPPLIER_BUSINESS_INFORMATION_REQUEST_FAILURE"
export const SEND_RETRIEVAL_PROFILE_SUPPLIER_IMAGE_FOLDERS_PHOTOS_REQUEST_SUCCESS = "SEND_RETRIEVAL_PROFILE_SUPPLIER_IMAGE_FOLDERS_PHOTOS_REQUEST_SUCCESS"
export const SEND_RETRIEVAL_PROFILE_SUPPLIER_IMAGE_FOLDERS_PHOTOS_REQUEST_FAILURE = "SEND_RETRIEVAL_PROFILE_SUPPLIER_IMAGE_FOLDERS_PHOTOS_REQUEST_FAILURE"

const sendRequestLoadSupplierCategories = () => ({
    type: SEND_REQUEST_LOAD_SUPPLIER_CATEGORIES
})

const sendRequestLoadSupplierCategoriesSuccess = () => ({
    type: SEND_REQUEST_LOAD_SUPPLIER_CATEGORIES_SUCCESS
})

const sendRequestLoadSupplierCategoriesFailure = () => ({
    type: SEND_REQUEST_LOAD_SUPPLIER_CATEGORIES_FAILURE
})

const sendRequestLoadSupplierCategoriesAll = () => ({
    type: SEND_REQUEST_LOAD_SUPPLIER_CATEGORIES_ALL
})

const sendRequestLoadSupplierCategoriesAllSuccess = () => ({
    type: SEND_REQUEST_LOAD_SUPPLIER_CATEGORIES_ALL_SUCCESS
})

const sendRequestLoadSupplierCategoriesAllFailure = () => ({
    type: SEND_REQUEST_LOAD_SUPPLIER_CATEGORIES_ALL_FAILURE
})

const sendRetrieveSupplierBusinessInformationRequest = () => ({
    type: SEND_RETRIEVE_SUPPLIER_BUSINESS_INFORMATION_REQUEST
})

const sendRetrieveSupplierBusinessInformationRequestSuccess = () => ({
    type: SEND_RETRIEVE_SUPPLIER_BUSINESS_INFORMATION_REQUEST_SUCCESS
})

const sendRetrieveSupplierBusinessInformationRequestFailure = () => ({
    type: SEND_RETRIEVE_SUPPLIER_BUSINESS_INFORMATION_REQUEST_FAILURE
})

const sendRetrieveAllSupplierContactInformation = () => ({
    type: SEND_RETRIEVE_SUPPLIER_CONTACT_INFORMATION_REQUEST
})

const sendRetrieveAllSupplierContactInformationSuccess = () => ({
    type: SEND_RETRIEVE_SUPPLIER_CONTACT_INFORMATION_REQUEST_SUCCESS
})

const sendRetrieveAllSupplierContactInformationFailure = () => ({
    type: SEND_RETRIEVE_SUPPLIER_CONTACT_INFORMATION_REQUEST_FAILURE
})

const sendRetrieveAllSupplierSocialInformation = () => ({
    type: SEND_RETRIEVE_SUPPLIER_SOCIAL_INFORMATION_REQUEST
})

const sendRetrieveAllSupplierSocialInformationSuccess = () => ({
    type: SEND_RETRIEVE_SUPPLIER_SOCIAL_INFORMATION_REQUEST_SUCCESS
})

const sendRetrieveAllSupplierSocialInformationFailure = () => ({
    type: SEND_RETRIEVE_SUPPLIER_SOCIAL_INFORMATION_REQUEST_FAILURE
})

const sendPostCollectedSupplierBusinessInformationRequest = () => ({
    type: SEND_POST_COLLECTED_SUPPLIER_BUSINESS_INFORMATION_REQUEST
})

const sendPostCollectedSupplierBusinessInformationRequestSuccess = () => ({
    type: SEND_POST_COLLECTED_SUPPLIER_BUSINESS_INFORMATION_REQUEST_SUCCESS
})

const sendPostCollectedSupplierBusinessInformationRequestFailure = () => ({
    type: SEND_POST_COLLECTED_SUPPLIER_BUSINESS_INFORMATION_REQUEST_FAILURE
})

const sendPostCollectedSupplierContactInformationRequest = () => ({
    type: SEND_POST_COLLECTED_SUPPLIER_CONTACT_INFORMATION_REQUEST
})

const sendPostCollectedSupplierContactInformationRequestSuccess = () => ({
    type: SEND_POST_COLLECTED_SUPPLIER_CONTACT_INFORMATION_REQUEST_SUCCESS
})

const sendPostCollectedSupplierContactInformationRequestFailure = () => ({
    type: SEND_POST_COLLECTED_SUPPLIER_CONTACT_INFORMATION_REQUEST_FAILURE
})

const sendPostCollectedSupplierSocialInformationRequest = () => ({
    type: SEND_POST_COLLECTED_SUPPLIER_SOCIAL_INFORMATION_REQUEST
})

const sendPostCollectedSupplierSocialInformationRequestSuccess = () => ({
    type: SEND_POST_COLLECTED_SUPPLIER_SOCIAL_INFORMATION_REQUEST_SUCCESS
})

const sendPostCollectedSupplierSocialInformationRequestFailure = () => ({
    type: SEND_POST_COLLECTED_SUPPLIER_SOCIAL_INFORMATION_REQUEST_FAILURE
})

const sendSupplierProfileChangePasswordRequest = () => ({
    type: SEND_SUPPLIER_PROFILE_CHANGE_PASSWORD_REQUEST
})

const sendSupplierProfileChangePasswordRequestSuccess = () => ({
    type: SEND_SUPPLIER_PROFILE_CHANGE_PASSWORD_REQUEST_SUCCESS
})

const sendSupplierProfileChangePasswordRequestFailure = () => ({
    type: SEND_SUPPLIER_PROFILE_CHANGE_PASSWORD_REQUEST_FAILURE
})

const sendSupplierAddEventRequest = () => ({
    type: SEND_SUPPLIER_ADD_EVENT_REQUEST
})

const sendSupplierAddEventRequestSuccess = () => ({
    type: SEND_SUPPLIER_ADD_EVENT_REQUEST_SUCCESS
})

const sendSupplierAddEventRequestFailure = () => ({
    type: SEND_SUPPLIER_ADD_EVENT_REQUEST_FAILURE
})

const sendSupplierUpdateEventRequest = () => ({
    type: SEND_SUPPLIER_UPDATE_EVENT_REQUEST
})

const sendSupplierUpdateEventRequestSuccess = () => ({
    type: SEND_SUPPLIER_UPDATE_EVENT_REQUEST_SUCCESS
})

const sendSupplierUpdateEventRequestFailure = () => ({
    type: SEND_SUPPLIER_UPDATE_EVENT_REQUEST_FAILURE
})

const sendProcessSupplierPhotosUploadRequest = () => ({
    type: SEND_PROCESS_SUPPLIER_PHOTOS_UPLOAD_REQUEST
})

const sendProcessSupplierPhotosUploadRequestSuccess = () => ({
    type: SEND_PROCESS_SUPPLIER_PHOTOS_UPLOAD_REQUEST_SUCCESS
})

const sendProcessSupplierPhotosUploadRequestFailure = () => ({
    type: SEND_PROCESS_SUPPLIER_PHOTOS_UPLOAD_REQUEST_FAILURE
})

const sendProfileSupplierImageFoldersRetrivalRequest = () => ({
    type: SEND_PROFILE_SUPPLIER_IMAGE_FOLDERS_RETRIVAL_REQUEST
})

const sendProfileSupplierImageFoldersRetrivalRequestSuccess = () => ({
    type: SEND_PROFILE_SUPPLIER_IMAGE_FOLDERS_RETRIVAL_REQUEST_SUCCESS
})

const sendProfileSupplierImageFoldersRetrivalRequestFailure = () => ({
    type: SEND_PROFILE_SUPPLIER_IMAGE_FOLDERS_RETRIVAL_REQUEST_FAILURE
})

const sendRetrievalProfileSupplierImageFoldersPhotosRequest = () => ({
    type: SEND_RETRIEVAL_PROFILE_SUPPLIER_IMAGE_FOLDERS_PHOTOS_REQUEST
})

const sendRetrievalProfileSupplierImageFoldersPhotosRequestSuccess = () => ({
    type: SEND_RETRIEVAL_PROFILE_SUPPLIER_IMAGE_FOLDERS_PHOTOS_REQUEST_SUCCESS
})

const sendRetrievalProfileSupplierImageFoldersPhotosRequestFailure = () => ({
    type: SEND_RETRIEVAL_PROFILE_SUPPLIER_IMAGE_FOLDERS_PHOTOS_REQUEST_FAILURE
})

const sendPostCollectedSupplierServiceInformationRequest = () => ({
    type: SEND_POST_COLLECTED_SUPPLIER_SERVICE_INFORMATION_REQUEST
})

const sendPostCollectedSupplierServiceInformationRequestSuccess = () => ({
    type: SEND_POST_COLLECTED_SUPPLIER_SERVICE_INFORMATION_REQUEST_SUCCESS
})

const sendPostCollectedSupplierServiceInformationRequestFailure = () => ({
    type: SEND_POST_COLLECTED_SUPPLIER_SERVICE_INFORMATION_REQUEST_FAILURE
})

const sendRequestLoadSupplierCategoriesPerParent = () => ({
    type: SEND_REQUEST_LOAD_SUPPLIER_CATEGORIES_PER_PARENT
})

const sendRequestLoadSupplierCategoriesPerParentSuccess = () => ({
    type: SEND_REQUEST_LOAD_SUPPLIER_CATEGORIES_PER_PARENT_SUCCESS
})

const sendRequestLoadSupplierCategoriesPerParentFailure = () => ({
    type: SEND_REQUEST_LOAD_SUPPLIER_CATEGORIES_PER_PARENT_FAILURE
})

const sendPostSupplierLocationInformationRequest = () => ({
    type: SEND_POST_SUPPLIER_LOCATION_INFORMATION_REQUEST
})

const sendPostSupplierLocationInformationRequestSuccess = () => ({
    type: SEND_POST_SUPPLIER_LOCATION_INFORMATION_REQUEST_SUCCESS
})

const sendPostSupplierLocationInformationRequestFailure = () => ({
    type: SEND_POST_SUPPLIER_LOCATION_INFORMATION_REQUEST_FAILURE
})

const sendFetchSupplierEventInformationRequest = () => ({
    type: SEND_FETCH_SUPPLIER_EVENT_INFORMATION_REQUEST
})

const sendFetchSupplierEventInformationRequestSuccess = () => ({
    type: SEND_FETCH_SUPPLIER_EVENT_INFORMATION_REQUEST_SUCCESS
})

const sendFetchSupplierEventInformationRequestFailure = () => ({
    type: SEND_FETCH_SUPPLIER_EVENT_INFORMATION_REQUEST_FAILURE
})

const fetchSupplierProfileBasicInformationRequest = () => ({
    type: SUPPLIER_FETCH_BASIC_PROFILE_INFORMATION_REQUEST
})

const fetchSupplierProfileBasicInformationRequestSuccess = () => ({
    type: SUPPLIER_FETCH_BASIC_PROFILE_INFORMATION_SUCCESS
})

const fetchSupplierProfileBasicInformationRequestFailure = () => ({
    type: SUPPLIER_FETCH_BASIC_PROFILE_INFORMATION_FAILURE
})

const sendSupplierAddNewReviewRequest = () => ({
    type: SUPPLIER_REVIEW_ADD_REQUEST
})

const sendSupplierAddNewReviewRequestSuccess = () => ({
    type: SUPPLIER_REVIEW_ADD_REQUEST_SUCCESS
})

const sendSupplierAddNewReviewRequestFailure = () => ({
    type: SUPPLIER_REVIEW_ADD_REQUEST_FAILURE
})

const listAllSupplierReviewsRequest = () => ({
    type: SUPPLIER_LIST_REVIEW_REQUEST
})

const listAllSupplierReviewsRequestSuccess = () => ({
    type: SUPPLIER_LIST_REVIEW_REQUEST_SUCCESS
})

const listAllSupplierReviewsRequestFailure = () => ({
    type: SUPPLIER_LIST_REVIEW_REQUEST_FAILURE
})

const listAllSubServicesForProfileRequest = () => ({
    type: LIST_ALL_SUB_SERVICES_FOR_PROFILE_REQUEST
})

const listAllSubServicesForProfileRequestSuceess = () => ({
    type: LIST_ALL_SUB_SERVICES_FOR_PROFILE_REQUEST_SUCCESS
})

const listAllSubServicesForProfileRequestFailure = () => ({
    type: LIST_ALL_SUB_SERVICES_FOR_PROFILE_REQUEST_FAILURE
})

const removeCurrentEventRequest = () => ({
    type: REMOVE_CURRENT_EVENT_REQUEST
})

const removeCurrentEventRequestSuccess = () => ({
    type: REMOVE_CURRENT_EVENT_REQUEST_SUCCESS
})

const removeCurrentEventRequestFailure = () => ({
    type: REMOVE_CURRENT_EVENT_REQUEST_FAILURE
})

const removeSelectedImageRequest = () => ({
    type: REMOVE_SELECTED_IMAGE_REQUEST
})

const removeSelectedImageRequestSuccess = () => ({
    type: REMOVE_SELECTED_IMAGE_REQUEST_SUCCESS
})

const removeSelectedImageRequestFailure = () => ({
    type: REMOVE_SELECTED_IMAGE_REQUEST_FAILURE
})

const removeSelectedSubServiceRequest = () => ({
    type: REMOVE_SELECTED_SUB_SERVICE_REQUEST
})

const removeSelectedSubServiceRequestSuccess = () => ({
    type: REMOVE_SELECTED_SUB_SERVICE_REQUEST_SUCCESS
})

const removeSelectedSubServiceRequestFailure = () => ({
    type: REMOVE_SELECTED_SUB_SERVICE_REQUEST_FAILURE
})

const removeAddionalServiceAreasRequest = () => ({
    type: REMOVE_ADDIONAL_SERVICE_AREA_REQUEST
})

const removeAddionalServiceAreasRequestSuccess = () => ({
    type: REMOVE_ADDIONAL_SERVICE_AREA_REQUEST_SUCCESS
})

const removeAddionalServiceAreasRequestFailure = () => ({
    type: REMOVE_ADDIONAL_SERVICE_AREA_REQUEST_FAILURE
})

export const supplierProfileBusinessFormUpdate = (field, value) => ({
    type: SUPPLIER_PROFILE_BUSINESS_INFORMATION_FORM_UPDATE,
    field,
    value
})

export const supplierProfileContactFormUpdate = (field, value) => ({
    type: SUPPLIER_PROFILE_CONTACT_INFORMATION_FORM_UPDATE,
    field,
    value
})

export const supplierProfileSocialFormUpdate = (field, value) => ({
    type: SUPPLIER_PROFILE_SOCIAL_INFORMATION_FORM_UPDATE,
    field,
    value
})

export const supplierProfileFormGenericUpdate = (field, value) => ({
    type: SUPPLIER_PROFILE_FORM_GENERIC_UPDATE,
    field,
    value
})

export const supplierProfileEventInformationFormUpdate = (field, value) => ({
    type: SUPPLIER_PROFILE_EVENT_INFORMATION_FORM_UPDATE,
    field,
    value
}) 

export const supplierProfileServiceInformationFormUpdate = (field, value) => ({
    type: SUPPLIER_PROFILE_SEERVICE_INFORMATION_FORM_UPDATE,
    field,
    value
}) 

export const supplierReviewFormUpdated = (field, value) => ({
    type: SUPPLIER_REVIEW_FORM_UPDATED,
    field,
    value
})

export const loadSubCategoriesForParentCategory = parentCatId => dispatch => {
    dispatch(sendRequestLoadSupplierCategoriesPerParent())
    return loadSubTypes(parentCatId).then(data => {
        dispatch(sendRequestLoadSupplierCategoriesPerParentSuccess())
        return data
    })
    .catch(error => {
        dispatch(sendRequestLoadSupplierCategoriesPerParentFailure())
        return error
    })    
}

export const loadSupplierCategories = () => dispatch => {
    dispatch(sendRequestLoadSupplierCategories())
    return loadSupplierParentTypes().then(data => {
        dispatch(sendRequestLoadSupplierCategoriesSuccess())
        return data
    })
    .catch(error => {
        dispatch(sendRequestLoadSupplierCategoriesFailure())
        return error
    })
}

export const loadSupplierCategoriesAll = mainType => dispatch => {
    dispatch(sendRequestLoadSupplierCategoriesAll())
    return loadSupplierTypesWithSubCategories(mainType).then(data => {
        dispatch(sendRequestLoadSupplierCategoriesAllSuccess())
        return data
    })
    .catch(error => {
        dispatch(sendRequestLoadSupplierCategoriesAllFailure())
        return error
    })
}

export const retrieveAllSupplierBusinessInformation = () => dispatch => {
    const accessToken = localStorage.getItem('accessToken')
    dispatch(sendRetrieveSupplierBusinessInformationRequest())
    return retrieveSupplierBusinessInformation(accessToken).then(data => {
        dispatch(sendRetrieveSupplierBusinessInformationRequestSuccess())
        return data
    })
    .catch(error => {
        dispatch(sendRetrieveSupplierBusinessInformationRequestFailure())
        return error
    })
}

export const retrieveAllSupplierContactInformation = () => dispatch => {
    const accessToken = localStorage.getItem('accessToken')
    dispatch(sendRetrieveAllSupplierContactInformation())
    return retrieveSupplierContactInformation(accessToken).then(data => {
        dispatch(sendRetrieveAllSupplierContactInformationSuccess())
        return data
    })
    .catch(error => {
        dispatch(sendRetrieveAllSupplierContactInformationFailure())
        return error
    })
}

export const retrieveAllSupplierSocialInformation = () => dispatch => {
    const accessToken = localStorage.getItem('accessToken')
    dispatch(sendRetrieveAllSupplierSocialInformation())
    return retrieveSupplierSocialInformation(accessToken).then(data => {
        dispatch(sendRetrieveAllSupplierSocialInformationSuccess())
        return data
    })
    .catch(error => {
        dispatch(sendRetrieveAllSupplierSocialInformationFailure())
        return error
    })
}

export const postCollectedSupplierBusinessInformation = (customPostObject = {}) => (dispatch, getState) => {
    const accessToken = localStorage.getItem('accessToken')
    dispatch(sendPostCollectedSupplierBusinessInformationRequest())
    const postData = {
        supplierCompanyName: getState().profile.supplierBusinessInfomration.companyName,
        supplierProfilePhoto: getState().profile.supplierBusinessInfomration.profilePhoto,
        supplierProfileCoverImage: getState().profile.supplierBusinessInfomration.profileCoverImage,
        supplierCompanyBio: getState().profile.supplierBusinessInfomration.companyBio,
        supplierBusinessAwards: getState().profile.supplierBusinessInfomration.businessAwards,
        supplierJobCost: getState().profile.supplierBusinessInfomration.jobCost,
        supplierJobCostFrom: getState().profile.supplierBusinessInfomration.jobCostFrom,
        supplierJobCostTo: getState().profile.supplierBusinessInfomration.jobCostTo,
        supplierCostDetails: getState().profile.supplierBusinessInfomration.costDetails,
        supplierStreet: getState().profile.supplierBusinessInfomration.street,
        supplierTown: getState().profile.supplierBusinessInfomration.town,
        supplierCountry: getState().profile.supplierBusinessInfomration.country,
        supplierPostcode: getState().profile.supplierBusinessInfomration.postcode,
        supplierWebsite: getState().profile.supplierBusinessInfomration.website
    }
    return postSupplierBusinessInformation(accessToken, customPostObject || postData).then(data => {
        dispatch(sendPostCollectedSupplierBusinessInformationRequestSuccess())
        return data
    })
    .catch(error => {
        dispatch(sendPostCollectedSupplierBusinessInformationRequestFailure())
        return error
    })
}

export const postCollectedSupplierContactInformation = (customPostObject = {}) => (dispatch, getState) => {
    const accessToken = localStorage.getItem('accessToken')
    dispatch(sendPostCollectedSupplierContactInformationRequest())
    const postData = {
        supplierContactFirstName: getState().profile.supplierContactInfomration.firstName,
        supplierContactSurame: getState().profile.supplierContactInfomration.surname,
        supplierContactTelno: getState().profile.supplierContactInfomration.telNo,
        supplierContactMobileNo: getState().profile.supplierContactInfomration.mobileNo,
        supplierContactEmail: getState().profile.supplierContactInfomration.emailAddress
    }
    return postSupplierContactInformation(accessToken, customPostObject || postData).then(data => {
        dispatch(sendPostCollectedSupplierContactInformationRequestSuccess())
        return data
    })
    .catch(error => {
        dispatch(sendPostCollectedSupplierContactInformationRequestFailure())
        return error
    })
}

export const postCollectedSupplierSocialInformation = (customPostObject = {}) => (dispatch, getState) => {
    const accessToken = localStorage.getItem('accessToken')
    dispatch(sendPostCollectedSupplierSocialInformationRequest())
    const postData = {
        supplierSocialProfileBlog: getState().profile.supplierContactInfomration.profileBlog,
        supplierSocialProfileVideo: getState().profile.supplierContactInfomration.profileVideo,
        supplierSocialProfileVimeo: getState().profile.supplierContactInfomration.profileVimeo,
        supplierSocialProfileFacebook: getState().profile.supplierContactInfomration.profileFacebook,
        supplierSocialProfileInstagram: getState().profile.supplierContactInfomration.profileInstagram,
        supplierSocialProfilePinterest: getState().profile.supplierContactInfomration.profilePinterest,
        supplierSocialProfileLinkedIn: getState().profile.supplierContactInfomration.profileLinkedIn,
    }
    return postSupplierSocialInformation(accessToken, customPostObject || postData).then(data => {
        dispatch(sendPostCollectedSupplierSocialInformationRequestSuccess())
        return data
    })
    .catch(error => {
        dispatch(sendPostCollectedSupplierSocialInformationRequestFailure())
        return error
    })
}

export const processSupplierProfileChangePassword = (customPostObject = {}) => (dispatch, getState) => {
    const accessToken = localStorage.getItem('accessToken')
    dispatch(sendSupplierProfileChangePasswordRequest())
    const postData = {
        oldPassword: getState().profile.oldPassword,
        newPassword: getState().profile.newPassword
    }
    return supplierProfileChangePassword(customPostObject || postData, accessToken).then(data => {
        dispatch(sendSupplierProfileChangePasswordRequestSuccess())
        return data
    }).catch(error => {
        dispatch(sendSupplierProfileChangePasswordRequestFailure())
        return error
    })
}

export const processSupplierAddEvent = (customPostObject = {}, imageSet) => (dispatch, getState) => {
    const accessToken = localStorage.getItem('accessToken')
    dispatch(sendSupplierAddEventRequest())
    const postData = {
        eventTypeId: getState().profile.supplierEventInformation.eventTypeId,
        eventDescription: getState().profile.supplierEventInformation.eventDescription,
        imagesAppearIn: getState().profile.supplierEventInformation.imagesAppearIn || 'profile',
        otherCredits: getState().profile.supplierEventInformation.otherCredits,
        imageCount: (imageSet && imageSet.length !== 0) ? imageSet.length : 0
    }
    return supplierAddEvent(customPostObject || postData, accessToken).then(data => {
        dispatch(sendSupplierAddEventRequestSuccess())
        return data
    })
    .catch(error => {
        dispatch(sendSupplierAddEventRequestFailure())
        return error
    })    
}

export const processSupplierUpdateEvent = (eventId, customPostObject = {}, imageSet, keywords, connectedSuppliers) => (dispatch, getState) => {
    const accessToken = localStorage.getItem('accessToken')
    dispatch(sendSupplierUpdateEventRequest())    
    const postData = {
        eventTypeId: getState().profile.supplierEventInformation.eventTypeId,
        eventDescription: getState().profile.supplierEventInformation.eventDescription,
        venueName: getState().profile.supplierEventInformation.venueName,
        venueLink: getState().profile.supplierEventInformation.venueLink,
        imagesAppearIn: getState().profile.supplierEventInformation.imagesAppearIn || 'profile',
        eventLocation: getState().profile.supplierEventInformation.eventLocation,
        eventYear: getState().profile.supplierEventInformation.eventYear,
        projectBudget: getState().profile.supplierEventInformation.projectBudget,
        keywords: (keywords && keywords.length !== 0) ? JSON.stringify(keywords) : [],
        photographerCredits: getState().profile.supplierEventInformation.photographerCredits,
        otherCredits: getState().profile.supplierEventInformation.otherCredits,
        imageCount: (imageSet && imageSet.length !== 0) ? imageSet.length : 0,
        connectedSuppliers: (connectedSuppliers && connectedSuppliers.length !== 0) ? JSON.stringify(connectedSuppliers) : []
    }
    return supplierUpdateEvent(eventId, customPostObject || postData, accessToken).then(data => {
        dispatch(sendSupplierUpdateEventRequestSuccess())
        return data
    })
    .catch(error => {
        dispatch(sendSupplierUpdateEventRequestFailure())
        return error
    })
}

export const postCollectedSupplierReviewInformation = (customPostObject = {}) => (dispatch, getState) => {
    const accessToken = localStorage.getItem('accessToken')
    dispatch(sendSupplierAddNewReviewRequest())  
    const postData = {
        reviewerEmail: getState().profile.supplierReviewform.reviewerEmail,
        reviewerComment: getState().profile.supplierReviewform.reviewerComment
    }
    return supplierAddReview(customPostObject || postData, accessToken).then(data => {
        dispatch(sendSupplierAddNewReviewRequestSuccess())
        return data
    })
    .catch(error => {
        dispatch(sendSupplierAddNewReviewRequestFailure())
        return error
    }) 
}

export const processSupplierPhotosUpload = (eachFile, order, id, width, height, eventId, eventTypeId, currentTimeStamp) => dispatch => {
    const accessToken = localStorage.getItem('accessToken')
    dispatch(sendProcessSupplierPhotosUploadRequest())
    return supplierAddEventPhotos(eachFile, order, id, width, height, eventId, eventTypeId, currentTimeStamp, accessToken, true).then(data => {
        dispatch(sendProcessSupplierPhotosUploadRequestSuccess())
        return data
    }).catch(error => {
        dispatch(sendProcessSupplierPhotosUploadRequestFailure())
        return error
    })
}

export const handleUploadNewPhotosUpdateEvent = (image, order, id, width, height, eventId, eventTypeId, folderId) => dispatch => {
    const accessToken = localStorage.getItem('accessToken')
    dispatch(sendProcessSupplierPhotosUploadRequest())
    return supplierUpdateEventNewPhotos(image, order, id, width, height, eventId, eventTypeId, accessToken, true, folderId).then(data => {
        dispatch(sendProcessSupplierPhotosUploadRequestSuccess())
        return data
    }).catch(error => {
        dispatch(sendProcessSupplierPhotosUploadRequestFailure())
        return error
    })
}

export const profileSupplierImageFolders = () => dispatch => {
    const accessToken = localStorage.getItem('accessToken')
    dispatch(sendProfileSupplierImageFoldersRetrivalRequest())
    return retrievalProfileSupplierImageFolders(accessToken).then(data => {
        dispatch(sendProfileSupplierImageFoldersRetrivalRequestSuccess())
        return data
    }).catch(error => {
        dispatch(sendProfileSupplierImageFoldersRetrivalRequestFailure())
        return error
    })
}

export const profileSupplierImageFoldersFetchPhotos = eventId => dispatch => {
    const accessToken = localStorage.getItem('accessToken')
    dispatch(sendRetrievalProfileSupplierImageFoldersPhotosRequest())
    return retrievalProfileSupplierImageFoldersPhotos(eventId, accessToken).then(data => {
        dispatch(sendRetrievalProfileSupplierImageFoldersPhotosRequestSuccess())
        return data
    }).catch(error => {
        dispatch(sendRetrievalProfileSupplierImageFoldersPhotosRequestFailure())
        return error
    })
}

export const postCollectedSupplierServiceInformation = (postData, serviceTypeId) => dispatch => {
    const accessToken = localStorage.getItem('accessToken')
    dispatch(sendPostCollectedSupplierServiceInformationRequest())
    return postSupplierServiceInformation(postData, serviceTypeId, accessToken).then(data => {
        dispatch(sendPostCollectedSupplierServiceInformationRequestSuccess())
        return data
    }).catch(error => {
        dispatch(sendPostCollectedSupplierServiceInformationRequestFailure())
        return error
    })
}

export const postSupplierLocationInformationAction = postData => dispatch => {
    const accessToken = localStorage.getItem('accessToken')
    dispatch(sendPostSupplierLocationInformationRequest())
    return postSupplierLocationInformation(postData, accessToken).then(data => {
        dispatch(sendPostSupplierLocationInformationRequestSuccess())
        return data
    }).catch(error => {
        dispatch(sendPostSupplierLocationInformationRequestFailure())
        return error
    })    
}

export const retrieveSupplierEventInformation = eventId => dispatch => {
    const accessToken = localStorage.getItem('accessToken')
    dispatch(sendFetchSupplierEventInformationRequest())
    return fetchSupplierEventInformation(eventId, accessToken).then(data => {
        dispatch(sendFetchSupplierEventInformationRequestSuccess())
        return data
    }).catch(error => {
        dispatch(sendFetchSupplierEventInformationRequestFailure())
        return error
    })     
}

export const processFetchMatchingSupplierNames = keyword => _ => {
    const accessToken = localStorage.getItem('accessToken')
    return fetchMatchingSupplierNames(keyword, accessToken).then(data => data).catch(error => error) 
}

export const loadSupplierProfileViewInformation = () => dispatch => {
    const accessToken = localStorage.getItem('accessToken')
    dispatch(fetchSupplierProfileBasicInformationRequest())
    return fetchSupplierProfileBasicInformation(accessToken).then(data => {
        dispatch(fetchSupplierProfileBasicInformationRequestSuccess())
        return data
    }).catch(error => {
        dispatch(fetchSupplierProfileBasicInformationRequestFailure())
        return error
    }) 
}

export const loadAllSupplierReviews = () => dispatch => {
    const accessToken = localStorage.getItem('accessToken')
    dispatch(listAllSupplierReviewsRequest())
    return listAllSupplierReviews(accessToken).then(data => {
        dispatch(listAllSupplierReviewsRequestSuccess())
        return data
    }).catch(error => {
        dispatch(listAllSupplierReviewsRequestFailure())
        return error
    }) 
}

export const loadSupplierEventPhotos = () => () => {
    const accessToken = localStorage.getItem('accessToken')
    return fetchSupplierEventPhotos(accessToken).then(data => data).catch(error => error) 
}

export const fetchAllReviewsForSupplierProfileInAction = () => () => {
    const accessToken = localStorage.getItem('accessToken')
    return fetchAllReviewsForSupplierProfile(accessToken).then(data => data).catch(error => error) 
}

export const loadAllSuppliersNames = userId => () => {
    const accessToken = localStorage.getItem('accessToken')
    return fetchAllSuppliers(accessToken, userId).then(data => data).catch(error => error) 
}

export const loadAllSupplierEventKeywords = (userId, eventId) => () => {
    const accessToken = localStorage.getItem('accessToken')
    return fetchAllSupplierEventKeywords(userId, eventId, accessToken).then(data => data).catch(error => error) 
}

export const loadAllSupplierEventAllKeywords = (userId, eventId) => () => {
    const accessToken = localStorage.getItem('accessToken')
    return fetchAllSupplierEventAllKeywords(userId, eventId, accessToken).then(data => data).catch(error => error) 
}

export const loadAllSupplierEventAllKeywordsNoException = () => () => {
    const accessToken = localStorage.getItem('accessToken')
    return fetchAllSupplierEventAllKeywordsNoException(accessToken).then(data => data).catch(error => error) 
}

export const loadAllConnectedSuppliers = (userId, eventId) => () => {
    const accessToken = localStorage.getItem('accessToken')
    return fetchAllConnectedSuppliers(userId, eventId, accessToken).then(data => data).catch(error => error) 
}

export const loadSupplierMainServices = () => () => {
    const accessToken = localStorage.getItem('accessToken')
    return listAllMainServiceForCurrentProfile(accessToken).then(data => data).catch(error => error)
}

export const loadSupplierSubServicesForMainService = mainServiceId => dispatch => {
    const accessToken = localStorage.getItem('accessToken')
    dispatch(listAllSubServicesForProfileRequest())
    return listAllSubServicesForCurrentProfile(mainServiceId, accessToken).then(data => {
        dispatch(listAllSubServicesForProfileRequestSuceess())
        return data
    }).catch(error => {
        dispatch(listAllSubServicesForProfileRequestFailure())
        return error
    })
}

export const loadSupplierOtherSubServicesForMainService = mainServiceId => () => {
    const accessToken = localStorage.getItem('accessToken')
    return listAllOtherSubServicesForCurrentProfile(mainServiceId, accessToken).then(data => data).catch(error => error)
}

export const loadProfileServedCountries = () => () => {
    const accessToken = localStorage.getItem('accessToken')
    return fetchSupplierProfileLocationInformationCountries(accessToken).then(data => data)
}

export const loadProfileServedOtherAreas = () => () => {
    const accessToken = localStorage.getItem('accessToken')
    return fetchSupplierProfileLocationInformationOthers(accessToken).then(data => data)
}

export const removeCurrentEvent = (eventId, currentTimeStamp) => dispatch => {
    const accessToken = localStorage.getItem('accessToken')
    dispatch(removeCurrentEventRequest())
    return removeTheCurrentEvent(eventId, currentTimeStamp, accessToken).then(data => {
        dispatch(removeCurrentEventRequestSuccess())
        return data
    }).catch(error => {
        dispatch(removeCurrentEventRequestFailure())
        return error
    })
}

export const loadMainServicesInformationForPublicProfileAction = supplierEmail => () => loadMainServicesInformationForPublicProfile(supplierEmail).then(data => data).catch(error => error)

export const loadSupplierSubServicesForMainServicePublicProfile = (mainServiceId, supplierEmail) => dispatch => {
    dispatch(listAllSubServicesForProfileRequest())
    return listAllSubServicesForCurrentPublicProfile(mainServiceId, supplierEmail).then(data => {
        dispatch(listAllSubServicesForProfileRequestSuceess())
        return data
    }).catch(error => {
        dispatch(listAllSubServicesForProfileRequestFailure())
        return error
    })
}

export const loadSupplierOtherSubServicesForMainServicePublicProfile = (mainServiceId, supplierEmail) => () => listAllOtherSubServicesForCurrentPublicProfile(mainServiceId, supplierEmail).then(data => data).catch(error => error)

export const updateTheOrderForTheExistingImages = (eventId, folderInsideImagesSet) => () => {
    const imagesArrayObject = {
        imageSet: JSON.stringify(folderInsideImagesSet)
    }
    const accessToken = localStorage.getItem('accessToken')
    return updateTheOrderForTheExistingImagesProfile(eventId, imagesArrayObject, accessToken).then(data => data).catch(error => error)
}

export const removeSelectedImage = imageId => dispatch => {
    const accessToken = localStorage.getItem('accessToken')
    dispatch(removeSelectedImageRequest())
    return removeTheSelectedImage(imageId, accessToken).then(data => {
        dispatch(removeSelectedImageRequestSuccess())
        return data
    }).catch(error => {
        dispatch(removeSelectedImageRequestFailure())
        return error
    })
}

export const removeSelectedSubServiceInAction = (mainServiceId, subServiceId) => dispatch => {
    const accessToken = localStorage.getItem('accessToken')
    dispatch(removeSelectedSubServiceRequest())
    return removeSelectedSubService(mainServiceId, subServiceId, accessToken).then(data => {
        dispatch(removeSelectedSubServiceRequestSuccess())
        return data
    }).catch(error => {
        dispatch(removeSelectedSubServiceRequestFailure())
        return error
    })
}

export const removeSelectedOtherSubServiceInAction = (mainServiceId, subService) => dispatch => {
    const accessToken = localStorage.getItem('accessToken')
    dispatch(removeSelectedSubServiceRequest())
    return removeSelectedOtherSubService(mainServiceId, subService, accessToken).then(data => {
        dispatch(removeSelectedSubServiceRequestSuccess())
        return data
    }).catch(error => {
        dispatch(removeSelectedSubServiceRequestFailure())
        return error
    })
}

export const removeAddionalServiceAreas = (serviceLocationCountry, serviceLocationCity) => dispatch => {
    const accessToken = localStorage.getItem('accessToken')
    dispatch(removeAddionalServiceAreasRequest())
    return removeAddionalServiceAreasService(serviceLocationCountry, serviceLocationCity, accessToken).then(data => {
        dispatch(removeAddionalServiceAreasRequestSuccess())
        return data
    }).catch(error => {
        dispatch(removeAddionalServiceAreasRequestFailure())
        return error
    })
}

export const removeMainServiceAreas = (serviceLocationCountry, serviceLocationCity) => dispatch => {
    const accessToken = localStorage.getItem('accessToken')
    dispatch(removeAddionalServiceAreasRequest())
    return removeMainServiceAreasService(serviceLocationCountry, serviceLocationCity, accessToken).then(data => {
        dispatch(removeAddionalServiceAreasRequestSuccess())
        return data
    }).catch(error => {
        dispatch(removeAddionalServiceAreasRequestFailure())
        return error
    })
}

export const removeMainService = mainServiceId => dispatch => {
    const accessToken = localStorage.getItem('accessToken')
    dispatch(removeSelectedSubServiceRequest())
    return removeSelectedMainService(mainServiceId, accessToken).then(data => {
        dispatch(removeSelectedSubServiceRequestSuccess())
        return data
    }).catch(error => {
        dispatch(removeSelectedSubServiceRequestFailure())
        return error
    })
}