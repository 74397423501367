import React, { Component } from 'react'
import { connect } from "react-redux"

import { checkLoginStatus } from '../../actions/sessionActions'
import { loadAllSupplierReviews } from '../../actions/profileActions'

import profile_image from '../../assets/images/Person-Icon.png'

class ProfessionalReviewsListing extends Component {

    state = { 
        allReviews: [],
        loggedInRealStatus: true,
        starExplanations: [
            "Would not recommend this pro",
            "Just ok, could have been better",
            "Pretty good overall",
            "Great job, recommend",
            "Excellent job, highly recommend"
        ]
    }

    componentDidMount () {
        this.props.checkLoginStatus().then(data => {
            if (data) {
                this.props.loadAllSupplierReviews().then(allReviews => allReviews && this.setState({ allReviews })) 
            }            
        })        
    }

    render() {

        const { allReviews } = this.state 
        
        return (
            <div className="supplier-profile__reviews">
                <div className="profile__section">
                {
                    allReviews && allReviews.map && allReviews.map((review, i) => (
                        <div key={review.id} className="supplier-profil__review">
                            <div className="supplier-profile__review-header">
                                <div className="supplier-profile__review-company-logo">
                                    {
                                        review.logo ? 
                                            <img src={review.logo} className="supplier-profile__review-company-logo-image" alt="company logo" />
                                        : <img src={profile_image} className="supplier-profile__review-company-logo-image" alt="company logo" />
                                    }
                                </div>
                                <div className="supplier-profile__company-details">
                                    <h5 className="supplier-profile__review-company-name heading__overrided-styles"><a target="_blank" href={`mailto:${review.reviewer_email}`}>{review.reviewer_name}</a></h5>
                                </div>
                            </div>
                            <div className="supplier-profile__review-details">
                                {
                                    review.relationship &&
                                        <p>
                                            <strong>Relationship: </strong>
                                            {review.relationship}
                                        </p>
                                }
                                {
                                    review.eventDate &&
                                        <p>
                                            <strong>Event Date: </strong>
                                            {review.eventDate}
                                        </p>
                                }
                                {
                                    review.projectPrice &&
                                        <p>
                                            <strong>Project Price: </strong>
                                            {review.projectPrice}
                                        </p>
                                }
                                {
                                    review.stars &&
                                        <p>
                                            <strong>How likely are you to refer AV1: </strong>
                                            {this.state.starExplanations[review.stars-1]}
                                        </p>
                                }
                            </div>
                            <p className="supplier-profile__review-comment">{review.reviewer_comment}</p>
                        </div>
                    ))
                }    
                </div>                
            </div>
        )
    }
}

const mapStateToProps = ({ profile, session }) => ({
    loading: profile.loading,        
    isLoggedIn: session.isLoggedIn
})
  
const mapDispatchToProps = dispatch => ({
    checkLoginStatus: () => dispatch(checkLoginStatus()),    
    loadAllSupplierReviews: () => dispatch(loadAllSupplierReviews())
})
  
export default connect(mapStateToProps, mapDispatchToProps)(ProfessionalReviewsListing)