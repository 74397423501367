import React, { Component } from 'react'
import { connect } from "react-redux"

import {
    supplierPublicProfileReviewFormUpdate,
    addSupplierPublicProfileReviewInAction    
} from '../../../actions/galleryActions'
import { showNotification, hideNotification } from '../../../actions/notificationActions'

import { StarRating } from '../../Global/StarRating'
import WriteReview from './WriteReview'

class Reviews extends Component {

    state = {
        starExplanations: [
            "Would not recommend this pro",
            "Just ok, could have been better",
            "Pretty good overall",
            "Great job, recommend",
            "Excellent job, highly recommend"
        ],
        rating: 0,
        reviwerEmail: "",
        acceptTerms: false,
        reviewerLastName: "",
        reviewerFirstName: "",
        reviewerDescription: "",        
        reviewerProjectCost: "",
        reviewerRelationship: "",
        reviewerStreetAddress: "",
        reviewerProjectCompletionMonth: null,
        reviewerProjectCompletionYear: null,                
    }

    onStarChange = input => e => {
        this.setState({ rating: input })
        this.props.supplierPublicProfileReviewFormUpdate('rating', input)
    }

    handleChange = input => event => {
        this.setState({ [input]: event.target.value })    
        this.props.supplierPublicProfileReviewFormUpdate([input], event.target.value)
    }

    submitReview = () => {
        const { emailAddress } = this.props
        this.props.addSupplierPublicProfileReview(emailAddress && emailAddress, this.props.supplierUserEmail).then(data => {
            if (data === "supplier_review_success") {
                this.props.showNotification('Thanks for your review!.', 'success')      
                this.props.hideWriteReview()
                setTimeout(() => {                    
                    this.props.hideNotification()
                    this.setState({
                        rating: 0,
                        reviwerEmail: "",
                        acceptTerms: false,
                        hideReviewForm: true,        
                        reviewerLastName: "",
                        reviewerFirstName: "",
                        reviewerDescription: "",        
                        reviewerProjectCost: "",
                        reviewerRelationship: "",
                        reviewerStreetAddress: "",
                        reviewerProjectCompletionMonth: null,
                        reviewerProjectCompletionYear: null,   
                    })
                }, 6000)      
            } else {
                this.props.showNotification('Something went wrong! Please contact support...', 'error')
                setTimeout(() => this.props.hideNotification(), 6000)                
            }
        })
    }

    acceptTermsAndConditions = _ => this.setState(prevState => ({ acceptTerms: !prevState.acceptTerms }))    
    
    render() { 

        const { 
            logo, 
            reviews, 
            hideWriteReview,
            showWriteReview,
            writeReviewVisbile 
        } = this.props 
        
        const {
            rating,
            acceptTerms,            
            reviwerEmail,
            reviewerLastName,
            reviewerFirstName,
            reviewerDescription,             
            reviewerProjectCost,           
            reviewerRelationship,            
            reviewerStreetAddress,
            reviewerProjectCompletionYear,
            reviewerProjectCompletionMonth,                                                
        } = this.state

        return (
            <div className="supplier-profile__reviews-container">
                <header className="supplier-profile__reviews-header">
                    <button className="btn supplier-profile__write-review" onClick={showWriteReview}>+ Write a Review</button>
                </header>
                {writeReviewVisbile &&
                    <WriteReview 
                        logo={logo}
                        rating={rating}
                        onClose={hideWriteReview} 
                        acceptTerms={acceptTerms}
                        reviwerEmail={reviwerEmail}
                        visible={writeReviewVisbile}                                                
                        handleChange={this.handleChange}
                        onStarChange={this.onStarChange}
                        submitReview={this.submitReview}
                        reviewerLastName={reviewerLastName}
                        reviewerFirstName={reviewerFirstName}
                        reviewerProjectCost={reviewerProjectCost}
                        reviewerDescription={reviewerDescription}
                        reviewerRelationship={reviewerRelationship}
                        reviewerStreetAddress={reviewerStreetAddress}
                        acceptTermsAndConditions={this.acceptTermsAndConditions}                         
                        reviewerProjectCompletionMonth={reviewerProjectCompletionMonth}
                        reviewerProjectCompletionYear={reviewerProjectCompletionYear}                                                
                    />}
                <div className="supplier-profile__reviews">
                {reviews && reviews.map(review => (
                    <div key={review.id} className="supplier-profil__review">
                        <div className="supplier-profile__review-header">
                            {/* <div className="supplier-profile__review-company-logo">
                                <img src={review.logo} alt="company logo" />
                            </div> */}
                            <div className="supplier-profile__company-details">
                                <h5 className="supplier-profile__review-company-name">{review.first_name + ' ' + review.last_name}</h5>
                                <div className="supplier-header__star-container">
                                    {/* {review.pro && <span className="supplier-header__pro">PRO</span>} */}
                                    <StarRating stars={review.rating}></StarRating>   
                                </div>                                 
                            </div>
                        </div>
                        <div className="supplier-profile__review-details">
                            <p>
                                <span className="each__review-header"><strong>Relationship: </strong></span>
                                {review.relationshiip}
                            </p>
                            <p>
                                <span className="each__review-header"><strong>Event Date: </strong></span>
                                {review.project_completion_month + '-' + review.project_completion_year}
                            </p>
                            <p>
                                <span className="each__review-header"><strong>Project Price: </strong></span>
                                {review.project_cost}
                            </p>
                        </div>
                        <p className="supplier-profile__review-comment">{review.review_comment}</p>
                    </div>))}                    
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ session }) => ({
    emailAddress: session.emailAddress,
})

const mapDispatchToProps = dispatch => ({    
    hideNotification: () => dispatch(hideNotification()),    
    showNotification: (message, notificationType) => dispatch(showNotification(message, notificationType)),        
    supplierPublicProfileReviewFormUpdate: (field, value) => dispatch(supplierPublicProfileReviewFormUpdate(field, value)),
    addSupplierPublicProfileReview: (userEmail, supplierEmail) => dispatch(addSupplierPublicProfileReviewInAction(userEmail, supplierEmail))    
})

export default connect(mapStateToProps, mapDispatchToProps)(Reviews)