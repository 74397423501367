import React, { Component } from 'react'
import { connect } from 'react-redux'

import { showNotification, hideNotification } from "../../../actions/notificationActions"
import { submitContactUsData } from "../../../actions/generalActions"

import image from '../../../assets/images/frontpage.jpg'

import './contact-us.css'

class ContactUs extends Component {

    state = {
        email: '',
        phone: '',
        subject: '',
        company: '',
        country: '',
        message: '',
        lastName: '',
        firstName: '',
        errorsInTheForm: true,
        touched: {
            email: false,
            company: false,
            subject: false,
            message: false,
            lastName: false,
            firstName: false,
        },
        errorFields: {
            email: true,
            company: true,
            subject: true,
            message: true,
            lastName: true,
            firstName: true,
        }, 
        loading: false
    }

    handleBlur = field => event => this.setState({ 
        ...this.state, 
        touched: { ...this.state.touched, [field]: true }, 
        errorFields: { ...this.state.errorFields, [field]: event.target.value === '' ? true : false } 
    })

    updateFormHandler = field => event => { 
        this.setState({ 
            ...this.state,
            [field]: event.target.value, 
            errorFields: {
                ...this.state.errorFields,
                [field]: event.target.value === '' ? true : false
            },
        }, () => this.formErrors())
    }

    formErrors = () => {
        const {
            email,
            company,
            subject,
            message,
            lastName,
            firstName,
            errorFields,
        } = this.state

        let errors = {}

        errors = (!firstName && (errorFields.firstName)) || (!lastName && (errorFields.lastName)) || (!email && (errorFields.email)) || (!company && (errorFields.company)) || (!subject && (errorFields.subject)) || (!message && (errorFields.message))
        this.setState({ ...this.state, errorsInTheForm: errors })
    }

    sendMessage = e => {
        e.preventDefault()
        const {
            email,
            phone,
            company,
            subject,
            message,
            country,
            lastName,
            firstName,
        } = this.state

        const postData = {
            email: email,
            phone: phone,
            company: company,
            subject: subject,
            country: country,
            message: message,
            lastName: lastName,
            firstName: firstName,
        }

        if (!this.state.errorsInTheForm) { 
            this.setState({ ...this.state, loading: true })
            this.props.submitContactUsData(postData).then(data => {
                this.setState({
                    email: '',
                    phone: '',
                    country: '',
                    message: '',
                    subject: '',
                    company: '',
                    lastName: '',
                    firstName: '',
                    ...this.state,
                    loading: false,
                })
                if (data === "contactus_query_created_mail_success") {
                    this.props.showNotification('Thanks for your enquiry! We will get back to you soon', 'success')
                    setTimeout(() => {
                        this.props.hideNotification()
                        window.location.href = '/'
                    }, 1000)
                } else {
                    this.props.showNotification('Something went wrong! Please contact support...', 'error')
                    setTimeout(() => this.props.hideNotification(), 2000)                
                }
            })  
        }
    }

    render () {

        const { 
            email,
            phone,
            loading,
            touched,
            message,
            country,
            company,
            subject,
            lastName,
            firstName,
            errorFields,
            errorsInTheForm,
        } = this.state
        
        return (
            <div className="contact-us">
                <div className={loading ? "overlay" : "overlay hide"}>
                    <div className="profile__loader-container">
                        <div className="loader">
                            <span>Loading...</span>
                        </div>
                    </div>
                </div>
                <header className="contact-us__header">Cotnact Us</header>
                <div className="contact-us__main">
                    <div className="contact-us__form-container">
                        <form className="eb-forms eb-forms--contact-us">
                            <div className="eb-forms__form-group">
                                <label htmlFor="contact-person-first-name">Name <span className="required__indicator">*</span></label>
                                <div className="eb-forms--not-fullwidth">
                                    <input 
                                        type="text"
                                        value={firstName}
                                        placeholder="First Name" 
                                        id="contact-person-first-name"                                             
                                        name="contact-person-first-name"                                            
                                        onBlur={this.handleBlur('firstName' )}
                                        onChange={this.updateFormHandler('firstName')}
                                        className={`${((touched.firstName && !firstName) && (errorFields.firstName)) && 'error__fields-indicator'}`}
                                    />
                                    <input 
                                        type="text"
                                        value={lastName}
                                        placeholder="Last Name" 
                                        id="jcontact-person-last-name"                                             
                                        name="contact-person-last-name"                                            
                                        onBlur={this.handleBlur('lastName')}
                                        onChange={this.updateFormHandler('lastName')}
                                        className={`${((touched.lastName && !lastName) && (errorFields.lastName)) && 'error__fields-indicator'}`}
                                    />
                                </div>
                            </div>
                            <div className="eb-forms__form-group">
                                <label htmlFor="email">Email <span className="required__indicator">*</span></label>
                                <div className="profile__input-container">
                                    <input 
                                        type="text"
                                        value={email}
                                        id="contact-email" 
                                        name="contact-email"
                                        placeholder="Email Address" 
                                        onBlur={this.handleBlur('email')}
                                        onChange={this.updateFormHandler('email')}
                                        className={`${((touched.email && !email) && (errorFields.email)) && 'error__fields-indicator'}`}
                                    />
                                </div>
                            </div>
                            <div className="eb-forms__form-group">
                                <label htmlFor="company">Company <span className="required__indicator">*</span></label>
                                <div className="profile__input-container">
                                    <input 
                                        type="text"
                                        value={company}
                                        id="contact-company" 
                                        name="contact-company"
                                        placeholder="Company" 
                                        onBlur={this.handleBlur('company')}
                                        onChange={this.updateFormHandler('company')}
                                        className={`${((touched.company && !company) && (errorFields.company)) && 'error__fields-indicator'}`}
                                    />
                                </div>
                            </div>
                            <div className="eb-forms__form-group">
                                <label htmlFor="subject">Subject <span className="required__indicator">*</span></label>
                                <div className="profile__input-container">
                                    <input 
                                        type="text"
                                        value={subject}
                                        id="contact-subject" 
                                        name="contact-subject"
                                        placeholder="Subject" 
                                        onBlur={this.handleBlur('subject')}
                                        onChange={this.updateFormHandler('subject')}
                                        className={`${((touched.subject && !subject) && (errorFields.subject)) && 'error__fields-indicator'}`}
                                    />
                                </div>
                            </div>
                            <div className="eb-forms__form-group">
                                <label htmlFor="phone">Phone Number</label>
                                <div className="profile__input-container">
                                    <input 
                                        type="text"
                                        value={phone}
                                        id="contact-phone" 
                                        name="contact-phone"
                                        placeholder="Phone No:" 
                                        onChange={this.updateFormHandler('phone')}
                                    />
                                </div>
                            </div>
                            <div className="eb-forms__form-group">
                                <label htmlFor="country">Country </label>
                                <div className="profile__input-container">
                                    <input 
                                        type="text"
                                        value={country}
                                        id="contact-country" 
                                        name="contact-country"
                                        placeholder="Country"
                                        onChange={this.updateFormHandler('country')}
                                    />
                                </div>
                            </div>
                            <div className="eb-forms__form-group">
                                <label htmlFor="message">Message<span className="required__indicator">*</span></label>
                                <textarea 
                                    id="message"
                                    name="message"
                                    value={message}
                                    placeholder="Send us a message. What's on your mind?"  
                                    onBlur={this.handleBlur('message')}
                                    onChange={this.updateFormHandler('message')}
                                    className={`${((touched.message && !message) && (errorFields.message)) && 'error__fields-indicator'}`}

                                ></textarea>
                            </div>
                        </form>
                        <div className="profile__button-container profile__button-container--single">                           
                            <button                           
                                disabled={errorsInTheForm}
                                onClick={this.sendMessage}
                                className="btn btn-gold profile__button profile__button-next"                                                                 
                            >SEND</button>                            
                        </div>
                    </div>
                    <div className="contact-us__image-container">
                        <div className="contact-us__image-outer">
                            <span>Follow Us</span>
                            <img src={image} alt="Contact us" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ session }) => ({
    isLoggedIn: session.isLoggedIn,
})
  
const mapDispatchToProps = dispatch => ({ 
    hideNotification: () => dispatch(hideNotification()),
    submitContactUsData: postData => dispatch(submitContactUsData(postData)),
    showNotification: (message, notificationType) => dispatch(showNotification(message, notificationType)),
})
  
export default connect(mapStateToProps, mapDispatchToProps)(ContactUs)