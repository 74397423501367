import React, { Component } from 'react'
import { connect } from "react-redux"

import Wizard from './Wizard'

import './EditProfileScreen.css'

class EditProfileScreen extends Component {

    render() {  
        if (!this.props.isLoggedIn) {
            // window.location.href = "/"
        }

        return <Wizard />
    }
}

const mapStateToProps = ({ session }) => ({      
    isLoggedIn: session.isLoggedIn
}) 

const mapDispatchToProps = () => {

}

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileScreen)