import React, { Component } from 'react'

import ComingSoon from '../../reusables/ComingSoon/ComingSoon'

export default class ButtonsAndBadges extends Component {

    render() {

        return (
            <>
                <div className="main">
                    <ComingSoon />
                </div>
            </>
        )
    }
}