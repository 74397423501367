import React, { Component } from 'react'
import { connect } from "react-redux"

import { checkLoginStatus } from '../../actions/sessionActions'
import { retrieveAllSupplierSocialInformation, supplierProfileSocialFormUpdate, postCollectedSupplierSocialInformation } from '../../actions/profileActions'
import { showNotification, hideNotification } from "../../actions/notificationActions"

class SocialInformation extends Component {

    constructor(props) {
        super(props)
        this.mySocial = React.createRef()
    }

    state = {
        gotoNext: false,
        profileBlog: "",
        profileVideo: "",
        profileVimeo: "",
        profileFacebook: "",
        profileInstagram: "",
        profileLinkedIn: "",
        profilePinterest: "",
        profileLinkedIn: "",
        loggedInRealStatus: false,
        saveBtnDisabledStatus: false
    }

    componentDidMount () {
        setTimeout(() => this.mySocial.current.scrollIntoView({ behavior: 'smooth', block: "center" }), 100)
        this.props.checkLoginStatus().then(data => {
            if (!data) {
                this.setState({ loggedInRealStatus: false })
            } else {
                this.props.retrieveAllSupplierSocialInformation().then(data => {   
                    if (data !== null) {
                        this.setState({
                            profileBlog: data.blog,
                            profileVideo: data.video,
                            profileVimeo: data.vimeo,
                            profileFacebook: data.facebook,                        
                            profileInstagram: data.instagram,
                            profileLinkedIn: data.linkedIn,
                            profilePinterest: data.pinterest
                        })                        
                    }          
                })    
            }            
        })
    }

    updateFormHandler = field => event => {         
        this.setState({ 
            [field]: event.target.value, 
            errorFields: {
                [field]: (!event.target.value) ? true : false
            },
            // errorsInTheForm: (!event.target.value) ? true : false
        })                        
        this.props.supplierProfileSocialFormUpdate([field], event.target.value)    
    }

    saveAndContinue = e => {
        e.preventDefault()
        const {
            profileBlog, 
            profileVimeo,
            profileVideo,                           
            profileFacebook,            
            profileLinkedIn,
            profilePinterest,
            profileInstagram,                                                
        } = this.state

        const postData = {
            supplierSocialProfileBlog: profileBlog,
            supplierSocialProfileVideo: profileVideo,
            supplierSocialProfileVimeo: profileVimeo,
            supplierSocialProfileFacebook: profileFacebook,
            supplierSocialProfileInstagram: profileInstagram,
            supplierSocialProfileLinkedin: profileLinkedIn,
            supplierSocialProfilePinterest: profilePinterest
        }   
        if (this.props.formUpdated) {
            this.props.postCollectedSupplierSocialInformation(postData).then(data => {
                if (data.status === "supplier_social_information_updated") {
                    this.props.showNotification('Your profile updated!.', 'success')
                    this.setState({ gotoNext: true })
                } else {
                    this.props.showNotification('Something went wrong! Please contact support...', 'error')
                    setTimeout(() => this.props.hideNotification(), 5000)                
                }
            })                         
        } 
    }

    // handleBlur = _ => _ => (this.props.formUpdated) && this.setState({ saveBtnDisabledStatus: false })

    back = e => {
        e.preventDefault()
        this.props.prevStep()
    }

    render() {
        if (this.state.gotoNext) {
            window.location.href = "/services"
        }

        const { 
            values, 
            loading,
        } = this.props

        const {
            profileBlog,  
            profileVideo,   
            profileVimeo,       
            profileFacebook,
            profileInstagram, 
            profilePinterest,
            profileLinkedIn,
            saveBtnDisabledStatus           
        } = this.state

        return (
            <>
                <div className={loading ? "overlay" : "overlay hide"}>
                    <div className="profile__loader-container">
                        <div className="loader"><span>Loading...</span></div>
                    </div>
                </div> 
                <div ref={this.mySocial} className="profile__contact-info">
                    <div className="profile__section">                
                        {/* <h6 className="profile__sub-title sub--heading_presentations sub--heading_presentations">Add your social media, this will appear in your profile. It only appear in the Gallery if you request images to appear via supplier upload images.</h6> */}
                        <div className="profile__section-content">
                            <div className="profile__form-container">
                                <form className="eb-forms eb-forms--profile">
                                    <div className="eb-forms__form-group">
                                        <label htmlFor="profile-blog">Blog</label>
                                        <div className="profile__input-container">
                                            <input 
                                                type="text" 
                                                id="profile-blog"                                         
                                                name="profile-blog"  
                                                // onBlur={this.handleBlur('profileBlog')}                                      
                                                placeholder="http://www.yourblog.com"                                         
                                                onChange={this.updateFormHandler('profileBlog')}                                                                                    
                                                value={(values.profileBlog ? values.profileBlog : '') || (profileBlog !== 'null' ? profileBlog : '')}                                                                                  
                                            />  
                                        </div>
                                    </div>
                                    <div className="eb-forms__form-group">
                                        <label htmlFor="profile-blog">YouTube</label>
                                        <div className="profile__input-container">
                                            <input 
                                                type="text" 
                                                id="profile-blog" 
                                                name="profile-blog"
                                                // onBlur={this.handleBlur('profileVideo')}
                                                onChange={this.updateFormHandler('profileVideo')}
                                                placeholder="http://www.youtube.com/watch?v=SH78G"                                         
                                                value={(values.profileVideo ? values.profileVideo : '') || (profileVideo !== 'null' ? profileVideo : '')}                                                                                  
                                            />
                                        </div>                                        
                                    </div>
                                    <div className="eb-forms__form-group">
                                        <label htmlFor="profile-facebook">Facebook</label>
                                        <div className="profile__input-container">  
                                            <input 
                                                type="text" 
                                                id="profile-facebook" 
                                                name="profile-facebook"
                                                // onBlur={this.handleBlur('profileFacebook')}
                                                placeholder="http://www.facebook.com/myfbpage" 
                                                onChange={this.updateFormHandler('profileFacebook')}
                                                value={(values.profileFacebook ? values.profileFacebook : '') || (profileFacebook !== 'null' ? profileFacebook : '')}                                                                                  
                                            />
                                        </div>
                                    </div>
                                    <div className="eb-forms__form-group">
                                        <label htmlFor="profile-instagram">Instagram</label>
                                        <div className="profile__input-container">
                                            <input 
                                                type="text" 
                                                id="profile-instagram" 
                                                name="profile-instagram"
                                                // onBlur={this.handleBlur('profileInstagram')}
                                                placeholder="http://www.instagram.com/my.instagam" 
                                                onChange={this.updateFormHandler('profileInstagram')}
                                                value={(values.profileInstagram ? values.profileInstagram : '') || (profileInstagram !== 'null' ? profileInstagram : '')}                                                                                  
                                            />
                                        </div>
                                    </div>
                                    <div className="eb-forms__form-group">
                                        <label htmlFor="profile-linkedin">LinkedIn</label>
                                        <div className="profile__input-container">
                                            <input 
                                                type="text" 
                                                id="profile-linkedin" 
                                                name="profile-linkedin"
                                                // onBlur={this.handleBlur('profileLinkedIn')}
                                                placeholder="https://www.linkedin.com/myfbpage" 
                                                onChange={this.updateFormHandler('profileLinkedIn')}
                                                value={(values.profileLinkedIn ? values.profileLinkedIn : '') || (profileLinkedIn !== 'null' ? profileLinkedIn : '')}                                                                                  
                                            />
                                        </div>
                                    </div>
                                    <div className="eb-forms__form-group">
                                        <label htmlFor="profile-pinterest">Pinterest</label>
                                        <div className="profile__input-container">
                                            <input 
                                                type="text" 
                                                id="profile-pinterest" 
                                                name="profile-pinterest"
                                                // onBlur={this.handleBlur('profilePinterest')}
                                                placeholder="http://www.pinterest.com/mypage" 
                                                onChange={this.updateFormHandler('profilePinterest')}
                                                value={(values.profilePinterest ? values.profilePinterest : '') || (profilePinterest !== 'null' ? profilePinterest : '')}                                                                                  
                                            />
                                        </div>
                                    </div>
                                    <div className="eb-forms__form-group">
                                        <label htmlFor="profile-vimeo">Vimeo</label>
                                        <div className="profile__input-container">
                                            <input 
                                                type="text" 
                                                id="profile-vimeo" 
                                                name="profile-vimeo"
                                                // onBlur={this.handleBlur('profileVimeo')}
                                                placeholder="http://www.vimeo.com/mychannel" 
                                                onChange={this.updateFormHandler('profileVimeo')}
                                                value={(values.profileVimeo ? values.profileVimeo : '') || (profileVimeo !== 'null' ? profileVimeo : '')}                                                                                  
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="profile__button-container">
                            <button className="btn btn-gold profile__button profile__button-prev" onClick={this.back}>Previous</button>
                            <button className="btn btn-gold profile__button profile__button-next" disabled={saveBtnDisabledStatus} onClick={this.saveAndContinue}>Save</button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = ({ profile, session }) => ({
    userId: session.userId,
    loading: profile.loading,    
    isLoggedIn: session.isLoggedIn,
    formUpdated: profile.supplierSocialInfomration.formUpdated
})
  
const mapDispatchToProps = dispatch => ({
    hideNotification: () => dispatch(hideNotification()),
    checkLoginStatus: () => dispatch(checkLoginStatus()),        
    retrieveAllSupplierSocialInformation: _ => dispatch(retrieveAllSupplierSocialInformation()),
    showNotification: (message, notificationType) => dispatch(showNotification(message, notificationType)),             
    supplierProfileSocialFormUpdate: (field, value) => dispatch(supplierProfileSocialFormUpdate(field, value)),
    postCollectedSupplierSocialInformation: postData => dispatch(postCollectedSupplierSocialInformation(postData))
})
  
export default connect(mapStateToProps, mapDispatchToProps)(SocialInformation)