import React from "react"

const imgWithClick = { cursor: "pointer" }

const PhotosOrdering = ({ index, photo, margin, onClick, direction, top, left, removeImage, items }) => {

    const imgStyle = { margin: margin }
    if (direction === "column") {
        imgStyle.position = "absolute"
        imgStyle.left = left
        imgStyle.top = top
    }

    const handleClick = event => onClick(event, { photo, index })

    return (
        <div className="uploaded-image">
            <img
                alt="img"
                onClick={onClick && handleClick}
                style={onClick ? { ...imgStyle, ...imgWithClick } : imgStyle}
                {...photo}
            />
            <span className="close" onClick={() => removeImage(index, items)}>x</span>
        </div>
    )
}

export default PhotosOrdering