import React from 'react'

import profile_image from '../../../assets/images/Person-Icon.png'
import './styles/supplier-header.css'

export const SupplierHeader = ({ supplier }) => (
    <header className="supplier-header">
        <div className="supplier-header__cover-image-container">
            {supplier && supplier.basic && supplier.basic.cover_image && <img src={supplier.basic.cover_image} className="supplier-header__cover-image" alt="supplier cover image"/>}      
        </div>
        <div className="supplier-header__info-container">
            <div className="supplier-header__info">
                <div className="supplier-header__logo-container">
                    {supplier && supplier.basic && supplier.basic.profile_photo ? 
                        <img src={supplier && supplier.basic && supplier.basic.profile_photo && supplier.basic.profile_photo} className="supplier-header__logo" alt="supplier logo image" />
                        : <img className="supplier-header__logo--dummy" src={supplier && supplier.basic && supplier.basic.profile_image && profile_image}  />}
                </div>
                <div className="supplier-header__details">
                    <h1 className="supplier-header__supplier-name">{supplier && supplier.basic && supplier.basic.company_name}</h1>
                    <div className="supplier-header__star-container">                        
                        <span className="supplier-header__current-reviews supplier-header__current-reviews-overrided">{supplier && supplier.reviews ? supplier.reviews.length : 1} Review(s) </span>
                    </div>
                </div>
            </div>
        </div>
    </header>
)