import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from "react-redux"

import { checkLoginStatus } from '../../actions/sessionActions'
import { registerFormUpdate, updateUserTypeOnRegister } from '../../actions/registerActions'

import SignUpGeneric from './SignUpGeneric'
import UserType from './UserType'
import EventCustomer from './EventCustomer'
import VenueCustomer from './VenueCustomer'

class LandingWizard extends Component {

    state = { 
        emailAddress: "",
        step: 1,
        touched: {
            userType: false,
            emailAddress: false            
        },
        errorFields: {
            userType: false,
            emailAddress: false            
        }
    }

    componentDidMount() {
        this.props.checkLoginStatus().then(data => data)
        this.fetchCountries()
    }

    fetchCountries = _ => fetch('/countries.json').then(rsp => rsp.json()).then(allCountries => this.setState({ allCountries }))

    nextStep = (email = "") => {
        this.props.updateForm('emailAddress', email)
        this.setState({ step: this.state.step + 1 })
    }

    changeStep = step => this.setState({ step: step })

    handleChange = input => event => {
        this.setState({ [input]: event.target.value })
        this.props.updateForm(input, event.target.value)
    }

    handleBlur = field => _ => this.setState({ touched: { ...this.state.touched, [field]: true } })

    saveAndContinue = e => {
        e.preventDefault()
        this.nextStep()
    }

    filteredUserType = userType => {                
        this.props.updateUserTypeOnRegister(userType)
        this.setState({ userType: userType })
    }

    render() {

        const { 
            step, 
            touched,
            userType,
            emailAddress,
            allCountries, 
            loggedInRealStatus            
        } = this.state

        const values = { 
            userType,
            emailAddress,
            allCountries
        }

        if (loggedInRealStatus) return <Redirect to={'/profile'} />

        switch(step) {
            case 1:
                return <SignUpGeneric 
                            values={values}
                            touched={touched}
                            userType={userType}
                            nextStep={this.nextStep} 
                            handleBlur={this.handleBlur}
                            handleChange={this.handleChange}                            
                            saveAndContinue={this.saveAndContinue}
                        />
            case 2:
                return <UserType 
                            values={values}
                            touched={touched}
                            nextStep={this.nextStep}
                            prevStep={this.prevStep}
                            handleBlur={this.handleBlur}
                            changeStep={this.changeStep}
                            handleChange={this.handleChange}                            
                            saveAndContinue= {this.saveAndContinue}                            
                            filteredUserType={this.filteredUserType}
                        />
            case 3:
                return <Redirect to={'/signup'} />
            case 4:
                return <VenueCustomer 
                            values={values}
                            nextStep={this.nextStep} 
                            changeStep={this.changeStep}
                            handleChange={this.handleChange}                                                        
                        />
            case 5:
                return <EventCustomer values={values} />
            }
    }
}

const mapStateToProps = ({ register }) => ({
    loading: register.loading,
    emailAddress: register.emailAddress
})
  
const mapDispatchToProps = dispatch => ({
    checkLoginStatus: _ => dispatch(checkLoginStatus()),
    updateForm: (field, value) => dispatch(registerFormUpdate({ field, value })),
    updateUserTypeOnRegister: userType => dispatch(updateUserTypeOnRegister(userType))
})
  
export default connect(mapStateToProps, mapDispatchToProps)(LandingWizard)
  