import React, { Component } from 'react'
import PhoneInput from 'react-phone-input-2'

import { 
    formatUrl,
    cleanTheWebSiteUrlAndReturnPlainURL 
} from '../../../utils/misc'

import phoneIcon from '../../../assets/images/icons/phone_icon.jpg'
import wwwIcon from '../../../assets/images/icons/www_icon.jpg'

export default class SupplierFilter extends Component {

    state = {
        currentTab: 0
    }

    componentDidMount() {
        this.props.activeTab(this.state.currentTab)
    }

    goToTab = key => {
        this.setState({ currentTab: key })
        this.props.activeTab(key)
    }

    render() {

        const { currentTab } = this.state

        const { 
            tabs,
            supplier
        } = this.props

        return (
            <section className="filter filter--supplier-profile">
                <div className="supplier-profile__contact-details">
                    { supplier && supplier.basic && supplier.basic.tel_no ? (
                        <div className="supplier__telephone-container">
                            <a href={`${supplier.basic.tel_no && 'tel:' + supplier.basic.tel_no}`}>
                                <PhoneInput
                                    country={'au'}
                                    color="primary"
                                    disabled="true"
                                    value={supplier.basic.tel_no}
                                />
                            </a>
                        </div>
                    ) : null }
                    <a target="_blank" href={supplier && supplier.basic && supplier.basic.website ? `${formatUrl(cleanTheWebSiteUrlAndReturnPlainURL(supplier.basic.website))}` : '#'} className="supplier-profile__website"><img src={wwwIcon} alt="www icon" />Website</a>
                </div>
                <ul className="supplier-profile__tabs">
                    {tabs.map((tab, key) => <li key={key} onClick={e => this.goToTab(key)} className={key ===  currentTab ? "supplier-profile__list supplier-profile__list--active" : "supplier-profile__list"}>{tab}</li>)}
                </ul>
            </section>
        )
    }
}