import React, { Component } from 'react'

export class AddStarRating extends Component {

    state = {
        starTitles: ["Would not recommend this Pro", "Just ok, could have been better", "Pretty good overall", "Great job, recommended", "Excellent job, highly recommended"]
    }  

    fetchTheStarFunctionality = () => {
        let star = []
        const { stars } = this.props
        for (var i = 5; i >= 1; i--) {
            star.push(
                <div className={stars >= i ? "rating-clickable-item checked" : "rating-clickable-item"} key={i}>
                    <input type="radio" id={"star" + i} name="rating" value={i} onChange={this.props.onStarChange(i)} />
                    <label className="full" htmlFor={"star" + i} title={this.state.starTitles[i-1]}></label>
                </div>
            )
        }
        return star
    }

    render() {
        return (
            <>
                <p className="write-review__heading">Rate this professional</p>                                
                <fieldset className="rating-clickable">                
                    {this.fetchTheStarFunctionality()}
                </fieldset>
            </>
        )
    }
}