import React, { Component } from 'react'
import { connect } from "react-redux"
import Select from 'react-select'
import { Redirect } from 'react-router-dom'

import { checkLoginStatus } from '../../actions/sessionActions'
import {
    removeMainService,
    loadSupplierCategories, 
    loadSupplierMainServices,
    removeSelectedSubServiceInAction,
    supplierProfileBusinessFormUpdate, 
    loadSubCategoriesForParentCategory,
    removeSelectedOtherSubServiceInAction,
    loadSupplierSubServicesForMainService,
    retrieveAllSupplierBusinessInformation,
    postCollectedSupplierServiceInformation,
    loadSupplierOtherSubServicesForMainService
} from '../../actions/profileActions'
import { showNotification, hideNotification } from "../../actions/notificationActions"

import Accordion from '../../reusables/Accordian/Accordian'

class Services extends Component {

    state = {        
        activeTab: 0,
        gotoNext: false,
        supplierType: "",
        mainServices: [],                                         
        supplierTypes: [],
        subServicesInfo: [],
        mainServiceId: null,
        supplierSubTypes: [],        
        additionalServices: [],
        loggedInRealStatus: true,
        subCategoriesSelected: [],
        isSupplierTypeDisabled: false,
        showForAddionalServices: false,
        showNewAdditionalServicesBlock: false    
    }

    componentDidMount () {
        this.props.checkLoginStatus().then(data => {
            if (data) {
                this._loadServiceInformation()
            }            
        })        
    }

    _loadServiceInformation = () => {
        this.props.loadSupplierTypes().then(data => {                    
            this.setState({ supplierTypes: data })
            this.props.retrieveAllSupplierBusinessInformation().then(supplierInfo => {  
                if (supplierInfo !== null) {
                    this.props.loadSupplierMainServices().then(mainServices => this.setState({ mainServices }))
                    this.props.loadSubCategoriesForParentCategory(supplierInfo.supplier_type_id).then(data => {
                        if (data.length > 0) {                                     
                            this.setState({ 
                                supplierSubTypes: data,
                                isSupplierTypeDisabled: true,
                                supplierType: supplierInfo.supplier_type_id                                         
                            }) 
                        }
                    })
                }          
            })        
        })
    }

    addNewServices = e => {        
        e.preventDefault()
        const {
            supplierType, 
            additionalServices,
            subCategoriesSelected
        } = this.state
        if ((supplierType !== '') && (subCategoriesSelected.length > 0)) {  
            const postData = {
                supplierServiceType: supplierType,
                additionalServices: additionalServices,
                supplierSubServiceTypes: subCategoriesSelected,
            } 
            this.props.postCollectedSupplierServiceInformation(postData, supplierType).then(data => {
                if (data.status === "supplier_service_information_updated") {                    
                    this.props.showNotification('Your service information have been updated!', 'success')                    
                    this.setState({ gotoNext: true })
                } else {
                    this.props.showNotification('Something went wrong! Please contact support...', 'error') 
                }       
                this.setState({ additionalServices: [], supplierSubTypes: [], supplierServiceType: "", subCategoriesSelected: [], supplierType: "" })         
                setTimeout(() => this.props.hideNotification(), 5000)                
            })
        } 
    }

    onSubCategoryCheck = id => {
        const { subCategoriesSelected } = this.state
        this.setState({ subCategoriesSelected: this.state.subCategoriesSelected.concat(id) })
        if (subCategoriesSelected.indexOf(id) !== -1) this.setState({ subCategoriesSelected: subCategoriesSelected.filter(item => item != id) })
    }

    addAdditionalServiceToQueue = e => {
        const currentServiceValue = e.target.value
        const { additionalServices } = this.state
        if (currentServiceValue !== '') {
            e.target.value = ''
            this.setState({ additionalServices: this.state.additionalServices.concat(currentServiceValue) })
            if (((additionalServices.indexOf(currentServiceValue) !== -1)) && (additionalServices !== '')) {
                this.setState({ additionalServices: additionalServices.filter(item => item != currentServiceValue) })
            }
        }               
    }

    removeAddedAddtionalSubService = service => {
        const { additionalServices } = this.state
        if (((additionalServices.indexOf(service) !== -1)) && (additionalServices !== '')) {
            this.setState({ additionalServices: additionalServices.filter(item => item != service) })
        }
    }

    addAddionalServices = e => {
        e.preventDefault()
        this.setState({ showNewAdditionalServicesBlock: true })
    }

    removeSelectedSubService = (serviceId, subServiceId) => {
        const { subServicesInfo } = this.state
        this.props.removeSelectedOtherSubServiceInAction(serviceId, subServiceId).then(() => {
            const filteredSubServices = subServicesInfo.filter(eachSubService => eachSubService.id !== subServiceId)
            this.setState({ subServicesInfo: filteredSubServices })
            this.props.showNotification('Your service information have been updated!', 'success')
            setTimeout(() => this.props.hideNotification(), 6000)
        })
    }

    removeSelectedSubOtherService = (serviceId, subService) => {
        const { additionalServices } = this.state
        this.props.removeSelectedOtherSubServiceInAction(serviceId, subService).then(() => {
            const filteredOtherSubServices = additionalServices.filter(eachSubService => eachSubService.label !== subService)
            this.setState({ additionalServices: filteredOtherSubServices })
            this.props.showNotification('Your service information have been updated!', 'success')
            setTimeout(() => this.props.hideNotification(), 6000)
        })
    }

    removeSelectedMainService = serviceId => {
        const { mainServices } = this.state
        this.props.removeMainService(serviceId).then(() => {
            const filteredMainServices = mainServices.filter(eachMainService => eachMainService.serviceId !== serviceId)
            this.setState({ mainServices: filteredMainServices })
            this.props.showNotification('Your service information have been updated!', 'success')
            setTimeout(() => this.props.hideNotification(), 6000)
        })
    }

    handleBlur = field => event => this.setState({ touched: { ...this.state.touched, [field]: true }, errorFields: { [field]: (!event.target.value) ? true : false }})

    updateFormHandler = field => event => {   
        if (field === 'supplierType') {
            if (event && event.value) {
                this.setState({ 
                    [field]: event.value, 
                    errorFields: {
                        [field]: (!event.value) ? true : false
                    },
                    errorsInTheForm: (!event.value) ? true : false
                })   
                this.props.supplierProfileBusinessFormUpdate([field], event.value)             
                this.props.loadSubCategoriesForParentCategory(event.value).then(data => (data.length > 0) && this.setState({ supplierSubTypes: data }))    
            } else {
                this.setState({ [field]: '' }) 
            }
        } else {
            if (event && event.target && event.target.value) {
                this.setState({ 
                    [field]: event.target.value, 
                    errorFields: {
                        [field]: (!event.target.value) ? true : false
                    },
                    errorsInTheForm: (!event.target.value) ? true : false
                })                                        
                this.props.supplierProfileBusinessFormUpdate([field], event.target.value)             
            } else {
                this.setState({ [field]: '' }) 
            }
        }
    }

	activateTab = (index, mainServiceId) => {
        const { activeTab } = this.state
        if (activeTab === parseInt(mainServiceId) ) {
            this.setState({ activeTab: 0 })
        } else { 
            this.setState({ activeTab: parseInt(mainServiceId) }, () => (
                this.props.loadSupplierSubServicesForMainService(mainServiceId).then(subServicesInfo => {                
                    this.props.loadSupplierOtherSubServicesForMainService(mainServiceId).then(otherSubServicesInfo => {
                        this.setState({ 
                            subServicesInfo: subServicesInfo,
                            otherSubServicesInfo: otherSubServicesInfo
                        })
                    })
                })    
            ))
        }
    }
    
    showForAddionalServicesTab = () => this.setState(prevState => ({ showForAddionalServices: !prevState.showForAddionalServices }))  

    render() {
        if (this.state.gotoNext) {
            return <Redirect to={'/locations'} />
        }
        const { isLoggedIn } = this.props
        const { 
            activeTab,
            mainServices,
            supplierType,
            mainServiceId,
            supplierTypes,
            subServicesInfo,
            supplierSubTypes,
            additionalServices,
            otherSubServicesInfo,
            subCategoriesSelected,            
            isSupplierTypeDisabled,
            showForAddionalServices,
            showNewAdditionalServicesBlock
        } = this.state

        let serviceTypeObject = {}        
        const { loading } = this.props

        if (supplierType) {
            serviceTypeObject = supplierTypes.filter(eachSupplierCategory => eachSupplierCategory.value === supplierType)
        }

        return (
            <>
                <div className={loading ? "overlay" : "overlay hide"}>
                    <div className="profile__loader-container">
                        <div className="loader"><span>Loading...</span></div>
                    </div>
                </div>  
                <div className="profile__services-location">
                    <div className="profile__section">
                    <h6 className="profile__sub-title sub--heading_presentations sub--heading_presentations">Supplier Category information</h6>
                        <h6 className="profile__sub-title">Choose the categories that best describe your business to help people find you</h6>
                        <div className="profile__section-content">
                            <div className="profile__form-container">
                                <form className="eb-forms eb-forms--profile">
                                    <div className="eb-forms__form-group">
                                        <label htmlFor="contact-person-first-name">Supplier Categories</label>
                                        <div className="eb-forms--not-fullwidth">
                                            <div className="container">
                                                <Select 
                                                    isClearable  
                                                    options={supplierTypes} 
                                                    value={serviceTypeObject}                      
                                                    className="searchable-dropdown"                                                                                                                                                                                    
                                                    isDisabled={isSupplierTypeDisabled}                                                               
                                                    onChange={this.updateFormHandler('supplierType')}
                                                />
                                            </div>      
                                        </div>                                                                           
                                    </div>
                                    {
                                        supplierType && 
                                        <div className="eb-forms__form-group">
                                            <label>Supplier sub-categories</label>
                                            <div className="profile__supplier-subcategories-container eb-forms--not-fullwidth">
                                                {
                                                    supplierSubTypes && 
                                                    supplierSubTypes.map((subCategory, i) => (
                                                        <div className="profile__supplier-subcategories eb-checkbox" key={subCategory.category_id}>
                                                            <input 
                                                                type='checkbox' 
                                                                id={subCategory.category_id}                                                            
                                                                onChange={() => this.onSubCategoryCheck(subCategory.category_id)}                                                                
                                                            />
                                                            <span className="checkmark"></span>
                                                            <label htmlFor={subCategory.category_id}>{subCategory.category_name}</label>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>                                         
                                    }
                                    <div className="eb-forms__form-group">
                                        <div className="eb-forms--not-fullwidth">
                                            <div className="profile__supplier-subcategories eb-checkbox">                                                                                                
                                                <label for="showForAdditionalServices">Please check for additonal services...</label>                                                
                                                <input type="checkbox" id="showForAdditionalServices" onChange={this.showForAddionalServicesTab} />
                                                <span className="checkmark"></span>
                                            </div>                                            
                                        </div>                                        
                                    </div>  
                                    {showForAddionalServices &&
                                    <div className="eb-forms__form-group">
                                        <label>Additional Services</label>
                                        <div className="eb-forms--not-fullwidth">
                                            <input 
                                                type="text" 
                                                name="other-services" 
                                                onBlur={this.addAdditionalServiceToQueue}
                                            />
                                            <button type="button" onClick={this.addAddionalServices} className="btn btn-gold btn-gold--profile-add-services">+ Add</button>
                                        </div>                                        
                                    </div>}
                                    <div className="additional-services__block">
                                        {(showNewAdditionalServicesBlock && additionalServices && additionalServices.length > 0) && additionalServices.map(eachAdditionalService => (
                                            <label>                                      
                                                <svg viewBox='0 0 19.5 19.5' onClick={() => this.removeAddedAddtionalSubService(eachAdditionalService)}>
                                                    <defs />
                                                    <g id='Layer_2' data-name='Layer 2'>
                                                        <g id='Layer_1-2' data-name='Layer 1'>
                                                            <path 
                                                                className='cls-1' 
                                                                d='M.45.45a1.56,1.56,0,0,1,2.19,0L19.05,16.86a1.55,1.55,0,0,1-2.19,2.19L.45,2.64A1.56,1.56,0,0,1,.45.45Z'
                                                            />
                                                            <path 
                                                                className='cls-1' 
                                                                d='M19.05.45a1.56,1.56,0,0,1,0,2.19L2.64,19.05A1.55,1.55,0,0,1,.45,16.86L16.86.45A1.56,1.56,0,0,1,19.05.45Z'
                                                            />
                                                        </g>
                                                    </g>
                                                </svg>
                                                {eachAdditionalService}
                                            </label>                                                
                                        ))} 
                                    </div>                                     
                                </form>
                            </div>
                            <div className="profile__button-container">
                                <button className="btn btn-gold profile__button profile__button-next" disabled={subCategoriesSelected.length === 0} onClick={this.addNewServices}>Save</button>
                            </div>                                                    
                        </div>
                        {(mainServices && mainServices.length > 0) &&
                        <>
                            <h6 className="profile__sub-title sub--heading_presentations">Services you are proving</h6>
                            <div className="profile__section-content">                                                        
                                <Accordion                                                                     
                                    panels={mainServices} 
                                    activeTab={activeTab}
                                    isLoggedIn={isLoggedIn}
                                    serviceId={mainServiceId}
                                    activateTab={this.activateTab} 
                                    subServicesInfo={subServicesInfo} 
                                    otherSubServicesInfo={otherSubServicesInfo}
                                    removeSelectedSubService={this.removeSelectedSubService}
                                    removeSelectedMainService={this.removeSelectedMainService}
                                    removeSelectedSubOtherService={this.removeSelectedSubOtherService}
                                />                        
                            </div>
                        </>}
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = ({ profile, session }) => ({
    loading: profile.loading,        
    isLoggedIn: session.isLoggedIn
})
  
const mapDispatchToProps = dispatch => ({
    checkLoginStatus: () => dispatch(checkLoginStatus()),    
    hideNotification: () => dispatch(hideNotification()),
    loadSupplierTypes: () => dispatch(loadSupplierCategories()), 
    loadSupplierMainServices: () => dispatch(loadSupplierMainServices()),
    removeMainService: mainServiceId => dispatch(removeMainService(mainServiceId)),
    retrieveAllSupplierBusinessInformation: () => dispatch(retrieveAllSupplierBusinessInformation()),
    showNotification: (message, notificationType) => dispatch(showNotification(message, notificationType)),
    loadSubCategoriesForParentCategory: parentCatId => dispatch(loadSubCategoriesForParentCategory(parentCatId)),
    supplierProfileBusinessFormUpdate: (field, value) => dispatch(supplierProfileBusinessFormUpdate(field, value)),    
    loadSupplierSubServicesForMainService: mainServiceId => dispatch(loadSupplierSubServicesForMainService(mainServiceId)),
    loadSupplierOtherSubServicesForMainService: mainServiceId => dispatch(loadSupplierOtherSubServicesForMainService(mainServiceId)),
    removeSelectedSubServiceInAction: (mainServiceId, subServiceId) => dispatch(removeSelectedSubServiceInAction(mainServiceId, subServiceId)),
    removeSelectedOtherSubServiceInAction: (mainServiceId, subService) => dispatch(removeSelectedOtherSubServiceInAction(mainServiceId, subService)),
    postCollectedSupplierServiceInformation: (postObject, serviceTypeId) => dispatch(postCollectedSupplierServiceInformation(postObject, serviceTypeId))
})
  
export default connect(mapStateToProps, mapDispatchToProps)(Services)