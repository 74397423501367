import React from 'react'

import '../find-pro-hire.css'
import closeIcon from '../../../assets/images/Close.svg'

export const FindProSearch = ({ setSearchTerm, searchTerm, clearSearchTerm }) => (
    <div className="find-pro__search eb-forms">
        <div className={"header__search-bar header__search-bar--open"}>
            <div className="header__input-container">
                <input type="text" className="header__search-input" placeholder="Search" value={searchTerm} onChange={e => setSearchTerm(e.target.value)} />
                {searchTerm && 
                    <button className="header__search-bar-button btn" onClick={clearSearchTerm}>
                        <img className="header__search-bar-close-icon" src={closeIcon} alt="close search bar" />
                    </button>
                }
            </div>        
        </div>
    </div>
)