import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'

class SignUpGeneric extends Component {

    state = {                       
        emailAddress: "",
        redirectionEnabled: false,
        errorInEmailAddress: false
    }

    saveAndContinue = e => {        
        e.preventDefault()
        if (this.props.values.emailAddress) 
            this.props.nextStep(this.props.values.emailAddress)
        if ((this.props.touched.emailAddress) && (!this.props.values.emailAddress))
            this.setState({ errorInEmailAddress: true })
        if (!this.props.touched.emailAddress)
            this.setState({ errorInEmailAddress: true })
    }

    login = _ => this.setState({ redirectionEnabled: true })

    render() {

        let notToProcees = false            
        
        const {              
            redirectionEnabled,
            errorInEmailAddress
        } = this.state

        const { 
            values,                         
            touched,
            handleBlur, 
            handleChange
        } = this.props

        if (redirectionEnabled) return <Redirect to={'/login'} />

        if ((!values.emailAddress) && (errorInEmailAddress)) {
            notToProcees = true
        }

        if ((values.emailAddress) && (errorInEmailAddress)) {
            notToProcees = false
        }

        return (
            <div className="landing-page__wizard">
                <header className="landing-page__middle-header">
                    <h4 className="landing-page__middle-title">Be the first</h4>
                    <h4 className="landing-page__middle-title">to know</h4>
                    <p className="landing-page__middle-subtext">sign up below</p>
                </header>
                <div className="eb-forms-open eb-forms-open--landing-page">
                    <form onSubmit={this.saveAndContinue}>
                        <input 
                            type="text"                             
                            id="email-address" 
                            name="email-address"
                            placeholder="Email Address" 
                            defaultValue={values.emailAddress}                             
                            onBlur={handleBlur('emailAddress')}                            
                            onChange={handleChange('emailAddress')}
                        /> 
                        {((touched.emailAddress && !values.emailAddress) || (notToProcees)) && <span className="error">Please Enter a valid Email Address</span>}                        
                        <button type="submit" className="btn btn-transparent">Sign up</button>
                    </form>
                </div>
                <footer className="landing-page__already-registered">
                    <p>
                        Already registered?
                        <button type="submit" className="btn btn-transparent btn-transparent--sign-in" onClick={this.login}>Sign in</button>
                    </p>  
                </footer>
            </div>
        )
    }
}

export default SignUpGeneric