import React, { Component } from 'react'

class VenueCustomer extends Component {

    state = {
        overlay: false
    }

    onSubmit = _ => {
        this.setState({ overlay: true })
        setTimeout(_ => {
            this.setState({ overlay: false })
            this.props.changeStep(5)
          }, 2000)
    }

    render() {

        const {
            values,
            telephone,
            venueName,
            contactName,   
            handleChange,  
            selectedCountry            
        } = this.props

        const { overlay } = this.state
        const countries = values.allCountries.map((country, i) => <option key={country.id} value={country.value}>{country.label}</option>)

        return (
            <div className="landing-page__wizard">
                <header className="landing-page__middle-header">
                    <h4 className="landing-page__middle-title">Venues</h4>
                    <p className="landing-page__middle-subtext">Sign up below</p>
                </header>
                <fieldset className="fieldset fieldset--inverse">
                    <legend className="legend legend--user-type">
                        <button className="btn legend-dark" onClick={this.onSubmit}>Join the buzz</button>
                        <span className="legend-light">Its free</span>
                    </legend>
                    <div className="landing-page__venues-container eb-forms-open">
                        <div className="eb-forms__form-group--open">
                            <input type="text" 
                                placeholder="Venue Name" 
                                id="venue-name" 
                                name="venue-name"
                                onChange={handleChange('venueName')}
                                defaultValue={venueName} />
                        </div>
                        <div className="eb-forms__form-group--open">
                            <input type="text" 
                                placeholder="contact name" 
                                id="contact-name" 
                                name="contact-name"
                                onChange={handleChange('contactName')}
                                defaultValue={contactName} />
                        </div>
                        <div className="eb-forms__form-group--open">
                            <input type="text" 
                                placeholder="telephone +( )" 
                                id="telephone" 
                                name="telephone"
                                onChange={handleChange('telephone')}
                                defaultValue={telephone} />
                        </div>
                        <div className="eb-forms__form-group--open eb-forms__form-group--open-select">
                            <select id="countries" 
                                value={selectedCountry} 
                                onChange={this.onCountryChange}>
                                <option value="ALL">-- SELECT --</option>
                                {countries}
                            </select>
                        </div>
                    </div>
                </fieldset>
                <div className={overlay ? "overlay" : "overlay hide"}>
                    <div className="profile__loader-container">
                        <div className="loader"><span>Loading...</span></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default VenueCustomer