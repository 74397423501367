import React, { Component } from 'react'
import { connect } from "react-redux"

import { checkLoginStatus } from '../../../actions/sessionActions'
import { loadSupplierCategoriesAll } from '../../../actions/profileActions'
import { 
    fetchRelatedSubServices, 
    fetchFilledSubCategoryIdsForPublic,
    fetchRelatedSuppliersForFindProOrHire 
} from '../../../actions/generalActions'

// import { CountryStateFilter } from '../../../reusables/CountryStateFilter/CountryStateFilter'
import { FindProCategories } from '../find-pro/FindProCategories'
import { FindProSearch } from '../find-pro/FindProSearch'

class FindPro extends Component {

    state = {
        searchTerm: '',
        allCategories: [],
        initialCategories: [],
        filledSubCategories: []
    }

    componentDidMount() {
        this.fetchFindPro()
        this.props.checkLoginStatus().then(data => data)
    }

    fetchFindPro = () => {
        this.props.fetchFilledSubCategoryIdsForPublic().then(filledSubCategories => {
            this.setState({ filledSubCategories })
            this.props.loadSupplierTypesAll('FIND_A_PRO').then(initialCategories => this.setState({ initialCategories: initialCategories, allCategories: initialCategories }))        
        })
    }

    redirectToDetails = (main, mainId, sub, subId) => {
        this.props.fetchRelatedSuppliersForFindProOrHire(mainId, subId).then(relatedSupplierInfo => this.setState({ relatedSupplierInfo: relatedSupplierInfo.suppliers }))
        this.props.history.push(`/find-pro-details/${main}/${mainId}/${sub}/${subId}`)
    }

    setSearchTerm = value => { 
        let categories = [], subCategories = []
        this.setState({ ...this.state.searchTerm, searchTerm: value })
        categories = this.state.initialCategories.filter(item => item.category_name.toLowerCase().search(value.toLowerCase()) !== -1)
        subCategories = this.state.initialCategories.filter(item => {            
            if (item.category_name.toLowerCase().search(value.toLowerCase()) == -1) {
                let hasSubcategories = 0
                item.subcategories.filter(sub => {
                    if (sub.category_name.toLowerCase().search(value.toLowerCase()) !== -1) {
                        hasSubcategories++
                    }
                })
                if (hasSubcategories > 0 ) {
                    return item 
                }
            }
        })
        this.setState({ allCategories: [...categories, ...subCategories] })
    }

    clearSearchTerm = () => this.setState({ searchTerm: '', allCategories: this.state.initialCategories })

    redirectToHome = () => this.props.history.push('/find-pro')

    render() {

        const { loading } = this.props

        const { 
            searchTerm,
            allCategories,             
            filledSubCategories 
        } = this.state

        return (
            <>
                <div className={loading ? "overlay" : "overlay hide"}>
                    <div className="profile__loader-container">
                        <div className="loader"><span>Loading...</span></div>
                    </div>
                </div>            
                <div className="main">
                    {/* <CountryStateFilter /> */}
                    <FindProSearch 
                        searchTerm={searchTerm}
                        setSearchTerm={this.setSearchTerm}
                        clearSearchTerm={this.clearSearchTerm}  
                    />
                    {allCategories && 
                    <FindProCategories   
                        categories={allCategories}                         
                        filledSubCategories={filledSubCategories}
                        redirectToDetails={this.redirectToDetails}                                                                      
                    />}
                </div>           
            </>
        )
    }
}

const mapStateToProps = ({ profile }) => ({
    loading: profile.loading
})
  
const mapDispatchToProps = dispatch => ({
    checkLoginStatus: () => dispatch(checkLoginStatus()),
    loadSupplierTypesAll: mainType => dispatch(loadSupplierCategoriesAll(mainType)),
    fetchFilledSubCategoryIdsForPublic: () => dispatch(fetchFilledSubCategoryIdsForPublic()),
    fetchRelatedSubServices: mainServiceId => dispatch(fetchRelatedSubServices(mainServiceId)),    
    fetchRelatedSuppliersForFindProOrHire: (main, categoryName) => dispatch(fetchRelatedSuppliersForFindProOrHire(main, categoryName))
})
  
export default connect(mapStateToProps, mapDispatchToProps)(FindPro)