import React, { Component } from 'react'

class EventCustomer extends Component {

    state = {}

    componentDidMount() {
        this.sendEmail()
    }

    sendEmail = _ => {

    }

    render() {

        return (
            <div className="landing-page__wizard">
                <header className="landing-page__middle-header">
                    <h4 className="landing-page__middle-title">Congratulations you're on the list</h4>
                </header>
                <p className="landing-page__middle-subtext--succss">Check your inbox for confirmation</p>
            </div>
        )
    }
}

export default EventCustomer