import React, { Component } from 'react'
import { connect } from 'react-redux'

import { submitEmailForPasswordRest } from "../../../actions/sessionActions"
import { showNotification, hideNotification } from "../../../actions/notificationActions"

class ForgotPassword extends Component {

    state = {
        email: '',
        touched: {
            email: false
        },
        errorFields: {
            email: false
        }
    }

    handleBlur = field => this.setState({ ...this.state, touched: { ...this.state.touched, [field]: true } })

    updateFormHandler = (field, value) => this.setState({ ...this.state, [field]: value })
    
    formSubmitHandler = e => {
        e.preventDefault()

        const { email } = this.state

        if (email) {                        
            this.props.submitEmailForPasswordRest(email).then(data => {
                if (data === "password_reset_success") {
                    this.props.showNotification('Please check your email to reset your password!.', 'success')
                    setTimeout(_ => this.props.hideNotification(), 3000) 
                } else {
                    this.props.showNotification('Username or password incorrect!', 'error')
                    setTimeout(_ => this.props.hideNotification(), 3000)  
                }
            })            
        }
    }

    render () {

        const { email, touched, errorFields } = this.state

        return (
            <>
                <h5 className="login__title">Forgot Password</h5>
                <div className="login__form-container">
                    <form className="eb-forms" onSubmit={this.formSubmitHandler}>
                        <div className="eb-forms__form-group eb-forms__form-group--login">
                            <label htmlFor="emailAddress">Email</label>
                            <input 
                                type="text" 
                                id="emailAddress" 
                                placeholder="Enter email"
                                onBlur={() => this.handleBlur('email')}
                                onChange={e => this.updateFormHandler('email', e.target.value)} 
                            />                                    
                            {((touched.email && !email) || (errorFields.email)) && <span className="error">Enter a valid email address</span>}                        
                        </div>
                        <button type="submit" className="btn btn-gold signup__button" disabled={(!touched.email || !email)}>Reset</button>
                    </form>     
                </div>    
                <footer className="login-form__footer">
                    <span className="signup__password-hint">By signing up, signing in or continuing, I agree to EventBuzz360's <a target="_blank" href="/terms-of-use">Terms of Use</a> and <a target="_blank" href="/privacy-policy">Privacy Policy</a></span>
                </footer> 
            </>
        )
    }
}

const mapStateToProps = () => ({

})
  
const mapDispatchToProps = dispatch => ({
    hideNotification: _ => dispatch(hideNotification()),    
    submitEmailForPasswordRest: email => dispatch(submitEmailForPasswordRest(email)),
    showNotification: (message, notificationType) => dispatch(showNotification(message, notificationType))
})

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)