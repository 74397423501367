import React, { Component } from 'react'

import './styles/static.css'

export default class About extends Component  {

    render () {

        return (
            <section className="static-page">
                <div className="static-page__static-container">
                    <fieldset className="static-page__fieldset">
                        <legend className="static-page__legend"><h3 className="static-page__title">ABOUT</h3></legend>
                        <div className="static-page__content">
                            <p>EventBuzz360 is the ultimate online platform for event professionals and anyone involved in organising an event.</p>
                            <p>Based on world-class photography and videography, EventBuzz360 takes users on an exciting journey inside the world’s most spectacular corporate and social events, it keeps members abreast of the latest and most inspiring cutting-edge trends in event design and production, and places a world of suppliers at their fingertips to ensure their vision becomes reality.</p>
                            <p>Until now, suppliers have had limited opportunities to market their products and services to the entire events industry, online.  At the same time, event organisers have been spending countless hours searching for inspiration and sourcing the right goods and services to push their events to the next-level.</p>
                            <p>Welcome to Eventbuzz360, the world’s most powerful online search engine for the events industry.</p>
                        </div>
                    </fieldset>
                </div>                
            </section>
        )
    }
}