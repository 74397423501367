import React, { Component } from 'react'

import './styles/static.css'

export default class CopyRightTrademark extends Component  {

    render () {

        return (
            <section className="static-page">
                <div className="static-page__static-container">
                    <header className="static-page__header">
                        <h3 className="static-page__title">EVENTBUZZ360 - COPYRIGHT &amp; TRADEMARK POLICY</h3>
                    </header>
                    <div className="static-page__content">                        
                        <ol type="1">
                            <li>EventBuzz360 expects users of the Site to respect the intellectual property rights of others.</li>
                            <li>Users who have uploaded content have represented and warranted that they have the rights to permit EventBuzz360 to use it on the Site.</li>
                            <li>If you believe in good faith that materials hosted by EventBuzz360 infringe your copyright or trademark rights, you (or your agent) may send us a notice requesting that the material be removed, or access to it blocked. The notice must include the following information:
                                <ol type="a">
                                    <li>a physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed;</li>
                                    <li>identification of the copyrighted work or trademark claimed to have been infringed (or if multiple copyrighted works or trademarks located on the Site are covered by a single notification, a representative list of such works);</li>
                                    <li>identification of the material that is claimed to be infringing or the subject of infringing activity, and information reasonably sufficient to allow EventBuzz360 to locate the material on the Site;</li>
                                    <li>information reasonably sufficient to permit EventBuzz360 to contact the complaining party, such as the name, address, telephone number, and email address of the complaining party;</li>
                                    <li>a statement that the complaining party has a good faith belief that use of the material in the manner complained of is not authorized by the copyright or trademark owner, its agent, or the law; and </li>
                                    <li>a statement that the information in the notification is accurate and, under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</li>
                                </ol>
                                <p>Please be advised that EventBuzz360 will not respond to complaints that do not meet these requirements. These requirements are guided by the Digital Millennium Copyright Act (the "DMCA"). If EventBuzz360 determines that the materials alleged to infringe your copyright or trademark rights do not require removal, EventBuzz360 will remove those materials only pursuant to a court order declaring the content or use of the materials unlawful.</p>
                            </li>
                            <li>If you believe in good faith that a notice of copyright infringement has been wrongly filed against you, the DMCA permits you to send us a counter-notice. Counter-notices must include the following information:
                                <ol type="a">
                                    <li>your name, address, and telephone number;</li>
                                    <li>identification of the material that has been removed or to which access has been disabled, and the location at which the material appeared before it was removed or access to it was disabled;</li>
                                    <li>a statement under penalty of perjury that you have a good faith belief that the content was removed or disabled as a result of a mistake or misidentification of the material to be removed or disabled;</li>
                                    <li>a statement that you consent to the jurisdiction of Federal Court for the judicial district in which your address is located, or if your address is outside of Australia, for any judicial district in which EventBuzz360 may be found, and that you will accept service of process from the person who provided the original complaint; and</li>
                                    <li>a physical or electronic signature (for example, typing your full name).</li>
                                </ol>
                            </li>
                            <li>Notices and counter-notices with respect to the Site must meet the then-current statutory requirements imposed by the DMCA (see <a className="hight__lighted-text-underlined" target="_blank" href="https://www.copyright.org.au">https://www.copyright.org.au</a> for details) and should be sent to the agent identified below. We suggest that you consult your legal advisor before filing a notice or counter-notice. Also, be aware that there can be penalties for false claims under the DMCA.</li>
                        </ol>                        
                    </div>
                </div>                
            </section>
        )
    }
}