import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from "react-redux"

import { retrieveAllSupplierBusinessInformation } from '../../actions/profileActions'
import { openModal } from '../../actions/newLoginActions'

import { CountryPopover } from '../Global/CountryPopover'

class BlackTopBar extends Component {

    state = {
        isHidden: true,
        basicProfileCompleted: false
    }

    componentDidMount () {
        if (this.props.isLoggedIn) {
            this.props.retrieveAllSupplierBusinessInformation().then(data => {  
                if (data && data.company_name && data.cover_image && data.profile_photo && data.company_bio && data.country && data.website) {
                    this.setState({ basicProfileCompleted: true })
                }                     
            })             
        }
        document.addEventListener('mousedown', this.handleClick, false)
    }

    componentWillUnmount () {
        document.removeEventListener('mousedown', this.handleClick, false)
    }

    togglePopover = e => {
        e.preventDefault()
        this.setState({ isHidden: !this.state.isHidden })
    }

    handleClick = e => (!this.node.contains(e.target)) && this.setState({ isHidden: true })

    filteredCountry = selectedCountry => this.props.filteredCountry(selectedCountry)

    userLogout = () => {
        this.props.userLogout()
        window.location.href = "/gallery"
    }

    openLoginModal = () => {
        this.props.openModal()
        document.querySelector('body').classList.add('modal-open')
    }

    render() {

    const { 
        username,
        countries,
        isLoggedIn,
        displayName,
        activeCountry      
    } = this.props

    const { isHidden, basicProfileCompleted } = this.state

    const linkHeader = isLoggedIn ? (!basicProfileCompleted ? '/profile' : '/add-event') : '/login'
    
    return (  
        <ul className="header__black-top-bar-list">
            <li className="header__black-top-bar-list-item">
                <Link to='/wishlist' className="header__black-top-bar-list-link">Wishlist</Link>
            </li>
            { isLoggedIn ? (
                <>
                    <li className="header__black-top-bar-list-item">
                        <Link to={linkHeader} className="header__black-top-bar-list-link">Welcome {displayName || username}</Link>
                    </li>
                    <li className="header__black-top-bar-list-item">
                        <a onClick={this.userLogout} className="header__black-top-bar-list-link">Logout</a>
                    </li>
                </>
            ): (
                <li className="header__black-top-bar-list-item">
                    <a onClick={this.openLoginModal} className="header__black-top-bar-list-link">login / signup</a>
                </li>
            ) }
            <li className="header__black-top-bar-list-item" ref={node => { this.node = node }}>
                <Link to='' 
                    onClick={e => this.togglePopover(e)}
                    className={isHidden ? "header__black-top-bar-list-link header__black-top-bar-popover-trigger" : "header__black-top-bar-list-link header__black-top-bar-popover-trigger header__black-top-bar-list-item--active"}  
                >{(activeCountry && activeCountry.label) && activeCountry.label}
                </Link>
                {!this.state.isHidden && <CountryPopover countries={countries} activeCountry={activeCountry} filteredCountry={this.filteredCountry} />
            }
            </li>          
        </ul>
        )
    }
}

const mapStateToProps = ({ session }) => ({
    isLoggedIn: session.isLoggedIn,
})

const mapDispatchToProps = dispatch => ({
    openModal: () => dispatch(openModal()),
    retrieveAllSupplierBusinessInformation: () => dispatch(retrieveAllSupplierBusinessInformation()),
})

export default connect(mapStateToProps, mapDispatchToProps)(BlackTopBar)