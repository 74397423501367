import React, { Component } from 'react'

class UserType extends Component {

    state = {
        userTypes: [
            {
                "index": 1,
                "text": "I'm an Event Supplier"
            },
            {
                "index": 2,
                "text": "I'm an Events Company"
            },
            {
                "index": 3,
                "text": "I'm a Venue (coming soon)"
            },
            {
                "index": 4,
                "text": "I'm Organising an Event (coming soon)",
                "help": "Looking to organise an event"
            }
        ]
    }

    saveAndContinue = _ => e => {
        e.preventDefault()
        if (this.props.values.userType) {
            this.props.nextStep(this.props.values.emailAddress)
        }
    }

    onUserTypeSelect = input => _ => this.props.filteredUserType(input)

    render() {

        const { values } = this.props
        const { userTypes } = this.state
        
        return (
            <div className="landing-page__wizard">
                <header className="landing-page__middle-header">
                    <p className="landing-page__middle-subtext">Tell us who you are</p>
                </header>
                <fieldset className="fieldset fieldset--inverse">
                    <legend className="legend legend--user-type">
                        <button className={`btn legend-dark ${values.userType && 'legend-gold'}`} onClick={this.saveAndContinue(values.userType)}>Join the buzz</button>
                        <span className="legend-light">Its free</span>
                    </legend>
                    <div className="landing-page__user-type-container eb-forms">
                    {
                        userTypes && 
                        userTypes.map((userType, i) => (
                            <div key={userType.index} 
                                className={userType.index === 3 ? "landing-page__user-type eb-radio eb-radio--disabled" : "landing-page__user-type eb-radio"}>
                                <input 
                                    type='radio'
                                    name="userType" 
                                    id={userType.index}                                    
                                    onChange={this.onUserTypeSelect(userType.index)}                                      
                                    disabled={userType.index !== 1 && userType.index !== 2}                           
                                />
                                <span className="checkmark"></span>
                                <label htmlFor={userType.index}>{userType.text}</label>
                            </div>
                        ))
                    }
                    </div>
                </fieldset>
            </div>
        )
    }
}

export default UserType