import React, { Component } from 'react'

export class LoginInitial extends Component {

    onUserTypeSelect = input => _ => this.props.filteredUserType(input)

    render () {
        return (
            <div className="login-modal__step1 eb-forms">
                <div className="login-modal__choice eb-radio">
                    <input 
                        type='radio'
                        name="userType" 
                        id={1}                                    
                        onChange={this.onUserTypeSelect(1)}                
                    />
                    <span className="checkmark"></span>
                    <label htmlFor={1}>
                        <h5>
                            I'm 
                            <span className="gold"> New </span> 
                            to EventBuzz360
                        </h5>
                    </label>
                </div>
                <div className="login-modal__choice eb-radio">
                    <input 
                        type='radio'
                        name="userType" 
                        id={2}                                    
                        onChange={this.onUserTypeSelect(2)}                         
                    />
                    <span className="checkmark"></span>
                    <label htmlFor={2}>
                        <h5>
                            <span className="gold">Already a member </span> 
                            Sign In
                        </h5>
                    </label>
                </div>
            </div>
        )
    }  
}