import React, { Component } from 'react'
import { connect } from "react-redux"

import { checkLoginStatus } from '../../actions/sessionActions'

import mailIcon from '../../assets/images/icons/icon_mail.png'
import facebookIcon from '../../assets/images/icons/icon_facebook.png'
import instagramIcon from '../../assets/images/icons/icon_instagram.png'
import linkedInIcon from '../../assets/images/icons/icon_linkedin.png'
import twitterIcon from '../../assets/images/icons/icon_twitter.png'
import pinterestIcon from '../../assets/images/icons/icon_pinterest.png'

import './styles/footer.css'

class Footer extends Component  {

    state = {
        social: {
            facebook: "www.facebook.com/EventBuzz360",
            linkedin: "www.linkedin.com/company/eventbuzz360-2",
            instagram: "www.instagram.com/eventbuzz360/",
            pinterest: "www.pinterest.com.au/eventbuzz360/",
            twitter: "twitter.com/EventBuzz360"
        }
    }

    componentDidMount() {
        this.props.checkLoginStatus().then(data => data)
    }

    render () {

        const { social } = this.state

        const { isLoggedIn } = this.props

        return (
            <section id="footer">
                <div className="footer__top">
                    <div className="footer__top-col">
                        <h3 className="footer__top-heading">Company</h3>
                        <ul className="footer__top-list">
                            <li className="footer__top-list-item">
                                <a href="/about" className="footer__top-link">About Us</a>
                            </li>
                            {/* <li className="footer__top-list-item">
                                <a href="/terms-of-use" className="footer__top-link">Terms of Use</a>
                            </li> */}
                            <li className="footer__top-list-item">
                                <a href="/privacy-policy" className="footer__top-link">Privacy Policy</a>
                            </li>
                            <li className="footer__top-list-item">
                                <a href="/acceptable-use-policy" className="footer__top-link">Acceptable Use Policy</a>
                            </li>
                            <li className="footer__top-list-item">
                                <a href="/copy-right-trademark" className="footer__top-link">Copyright &amp; Trademark</a>
                            </li>
                        </ul>
                    </div>
                    <div className="footer__top-col">
                        <h3 className="footer__top-heading">Explore</h3>
                        <ul className="footer__top-list">
                            <li className="footer__top-list-item">
                                <a href={`${isLoggedIn ? '/advertise' : '/'}`} className="footer__top-link">Advertise</a>
                            </li>
                        </ul>
                    </div>
                    <div className="footer__top-col">
                        <h3 className="footer__top-heading">Contact</h3>
                        <ul className="footer__top-list">
                            <li className="footer__top-list-item footer__top-list-item--inline">
                                <img src={mailIcon} />&nbsp;&nbsp;
                                <a href="/contact-us" className="footer__top-link">Contact Us</a>
                            </li>
                        </ul>
                    </div>
                    <div className="footer__top-col">
                        <h3 className="footer__top-heading">Location</h3>
                        <ul className="footer__top-list footer__top-list--util">
                            <li className="footer__top-list-item footer__top-list-item--util">
                                <i className="fa fa-language" aria-hidden="true"></i>
                                <span className="footerMenuOptions">Language English</span>
                            </li>
                            <li className="footer__top-list-item footer__top-list-item--util">
                                <i className="fa fa-globe"></i>
                                <span className="footerMenuOptions">Country Australia</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="footer__bottom">
                    <h3 className="footer__bottom-heading">CONNECT WITH US</h3>
                    <div className="footer__social-media-container">
                        <ul className="footer__social-media">
                            {social.instagram && 
                                (<li className="footer__social-media-list">
                                    <a target="_blank" className="footer__social-media-link" href={"http://" + social.instagram}>
                                        <img src={instagramIcon} alt="instagram" />
                                    </a>
                                </li>)}
                            {social.facebook &&
                                (<li className="footer__social-media-list">
                                    <a target="_blank" className="footer__social-media-link" href={"http://" + social.facebook}>
                                        <img src={facebookIcon} alt="facebook" />
                                    </a>
                                </li>)}
                            {social.linkedin &&
                                (<li className="footer__social-media-list">
                                    <a target="_blank" className="footer__social-media-link" href={"http://" + social.linkedin}>
                                        <img src={linkedInIcon} alt="linkedIn" />
                                    </a>
                                </li>)}
                            {social.pinterest &&
                                (<li className="footer__social-media-list">
                                    <a target="_blank" className="footer__social-media-link" href={"http://" + social.pinterest}>
                                        <img src={pinterestIcon} alt="pinterest" />
                                    </a>
                                </li>)}  
                            {social.twitter &&
                                (<li className="footer__social-media-list">
                                    <a target="_blank" className="footer__social-media-link" href={"http://" + social.twitter}>
                                        <img src={twitterIcon} alt="twitter" />
                                    </a>
                                </li>)}                                    
                        </ul>
                    </div>
                    <p className="footer-links">
                        <span>TM + &copy; 2020 EVENTBUZZ 360, Inc.&nbsp;|&nbsp; All rights reserved.</span>
                    </p>
                </div>
            </section>
        )
    }
}

const mapStateToProps = ({ session }) => ({      
    isLoggedIn: session.isLoggedIn,
}) 

const mapDispatchToProps = dispatch => ({
    checkLoginStatus: () => dispatch(checkLoginStatus()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Footer)