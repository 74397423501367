import React, { Component } from 'react'

import './ComingSoon.css'

class ComingSoon extends Component {

    render() {

        return (
            <div className="main">
                <div className="bgimg">
                    {/* <div className="topleft">
                        <p>Logo</p>
                    </div> */}
                    <div className="middle">
                        <h1>COMING SOON</h1>
                        {/* <hr />
                        <p>35 days</p> */}
                    </div>
                    {/* <div className="bottomleft">
                        <p>Some text</p>
                    </div> */}
                </div>
            </div>
        )
    }
}

export default ComingSoon